import React, { ReactElement } from "react";
import "components/health-details/health-details.css";
import { motion } from "framer-motion";
import { setShowHealthDetails } from "slices/system";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { v4 as uuidv4 } from "uuid";
import { healthItemColors } from "components/health-bar/health-bar";

export default function HealthDetails(): ReactElement {
  let dispatch = useDispatch();

  let system = useSelector((state: RootState) => {
    let obj = {
      showHealthDetails: state.system.popupDetails.showHealthDetails,
      health: state.system.system.health,
      state: state.system.system.state,
    };

    return obj;
  }, shallowEqual);

  function getNumberOfHealthItems() {
    return system.health / 5;
  }
  
  function renderHealthItems() {
    let healthItems = getNumberOfHealthItems();
    let arr = [];
    for (let i = 0; i < healthItems; i++) {
      arr.push(i);
    }
    return arr.map((item: any, index: number) => (
      <div
        key={index}
        className="health-detail-item"
        style={{ background: healthItemColors[item] }}
      />
    ));
  }

  return system.showHealthDetails ? (
    <div>
      <motion.div
        key={uuidv4()}
        className="health-details-screen"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="helath-details">
          <div
            className="helath-details-button"
            onClick={() => dispatch(setShowHealthDetails(false))}
          />
          <div className="health-div-list">
            <div className="health-details-list">
              <div className="health-name">Service Health:</div>
              <div className="health-msg">
                This meter will help you understand the overall health of the
                system.
              </div>
            </div>
          </div>
        </div>

        <div className="health-topbar">
          <div className="health-container">
            <div className="service-health-text">SERVICE HEALTH</div>
            <div className="health-progress-container">
              {renderHealthItems()}
            </div>
            <img className="star-img" src="/assets/images/filledstar.svg"></img>
          </div>
        </div>
      </motion.div>
    </div>
) : (
  <div></div>
);
}
