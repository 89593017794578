import React, { ReactElement, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { updateLoadProfile, updateHealthOfSystem } from "slices/system";
import { updateStateHint } from "services/npc-service";
import { SystemStates } from "constants/system-states";

interface Props {}

export default function Tick({}: Props): ReactElement {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(0);

  let system = useSelector((state: RootState) => {
    let obj = {
      hint: state.system.system.hint,
      isTourOpen: state.system.popupDetails.isTourOpen,
      showIntroduction: state.system.popupDetails.showIntroduction,
      state: state.system.system.state,
      dropMoreHealth: state.system.system.dropMoreHealth,
      showBadgeDetails: state.system.popupDetails.showBadgeDetails,
      showHealthDetails: state.system.popupDetails.showHealthDetails,
      dropTsbHealth: state.system.system.dropTsbHealth,
      showLandscapePopup: state.system.popupDetails.showLandscapePopup
    };
    return obj;
  }, shallowEqual);

  const dropMoreHealthRef = useRef(system.dropMoreHealth);
  dropMoreHealthRef.current = system.dropMoreHealth;

  const stateRef = useRef(system.state);
  stateRef.current = system.state;

  const dropTsbHealthRef = useRef(system.dropTsbHealth);
  dropTsbHealthRef.current = system.dropTsbHealth;

  const showBadgeDetailsRef = useRef(system.showBadgeDetails);
  showBadgeDetailsRef.current = system.showBadgeDetails;

  const showHealthDetailsRef = useRef(system.showHealthDetails);
  showHealthDetailsRef.current = system.showHealthDetails;

  const showLandscapePopupRef = useRef(system.showLandscapePopup);
  showLandscapePopupRef.current = system.showLandscapePopup;

  useEffect(() => {
    if (timer === 0 && !system.isTourOpen && !system.showIntroduction) {
      tick();
    }
  });

  function tick() {
    let timer = setInterval(() => handleTickAction(), 1350);
    setTimer(timer);
  }

  function handleTickAction() {
    let showBadgeDetails = showBadgeDetailsRef
      ? showBadgeDetailsRef.current
      : system.showBadgeDetails;
    let showHealthDetails = showHealthDetailsRef
      ? showHealthDetailsRef.current
      : system.showHealthDetails;

    let showLandscapePopup = showLandscapePopupRef
      ? showLandscapePopupRef.current
      : system.showLandscapePopup;

    if (!showBadgeDetails && !showHealthDetails && !showLandscapePopup) {
      let state = stateRef ? stateRef.current : system.state;
      var load = 3;
      switch (state) {
        case SystemStates.FAILOVER:
          load = 0.4;
          break;
        case SystemStates.UPDATE:
          load = 1;
          break;
        case SystemStates.TSB_FAILOVER:
          load = 1;
          break;
        case SystemStates.BEGIN:
          load = 10;
          break;
        case SystemStates.BEGIN_ZOOMED:
          load = 9;
          break;
        case SystemStates.ADD_TSB:
          load = dropTsbHealthRef.current ? 15 : 2;
          break;
        case SystemStates.ADDED_ENVOY:
          load = dropTsbHealthRef.current ? 10 : 4;
          break;
        case SystemStates.ADDED_ISTIO:
          load = 4;
          break;
        case SystemStates.ENVOY_ZOOM_OUT:
          let dropMoreHealth =
            dropMoreHealthRef.current ?? system.dropMoreHealth;
          load = dropMoreHealth ? 3 : 5;
          break;
        default:
          load = 4;
          break;
      }

      dispatch(updateLoadProfile(load));

      dispatch(updateHealthOfSystem());
      if (system.hint.hintType !== "userAction") {
        updateStateHint();
      }
    }
  }

  return <div></div>;
}
