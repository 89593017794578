export default class NodeType {
  static MONOLITH = "MONOLITH";
  static MONOLITH_ZOOMED = "MONOLITH_ZOOMED";
  static MICROSERVICE = "MICROSERVICE";
  static ISTIO = "ISTIO";
  static LOADBALANCER = "LOADBALANCER";
  static TETRATESERVICEBUS = "TETRATESERVICEBUS";
  static ENVOY = "ENVOY";
  static MONOLITHCONTAINER = "MONOLITHCONTAINER";
  static MICROSERVICE_ENVOY = "MICROSERVICE_ENVOY";
  static TSB = "TSB";
  static BRANCHNODE = "BRANCH_NODE";
}
