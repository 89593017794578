import React, {
  ReactElement,
  useEffect,
  useState,
  ComponentElement
} from "react";
import "components/game-tour/game-tour.css";
// import Tour from "reactour";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { motion } from "framer-motion";
import {
  stepOneArrowImage,
  setpTwoArrowImage,
  stepThreeArrowImage
} from "./game-tour-arrows";
import { playButtonClickMusic } from "services/npc-service";

import {
  setTourOpen,
  setTourStepNumber,
  toggleButtonClickMusic
} from "slices/system";

let Tour = require("reactour").default;

export default function GameTour(): ReactElement {
  const { isTourOpen, tourStepNumber } = useSelector((state: RootState) => {
    return state.system.popupDetails;
  });

  const [isFirstTimeLoaded, setFirstTimeLoaded] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    function keyHandling(e: any) {
      if (isTourOpen && e.keyCode === 13) {
        e.preventDefault();
      }
    }
    window.addEventListener("keyup", keyHandling);
    return () => window.removeEventListener("keyup", keyHandling);
  }, [dispatch, isTourOpen]);

  const NextPrevTransition = {
    duration: 2,
    ease: "easeInOut",
    times: [0, 0.2, 0.5, 0.8, 1],
    loop: Infinity,
    delay: 2
  };

  const steps = [
    {
      selector: ".enabled-tour-t-logo",
      content: () => (
        <div className="fixed-position-one">
          <div className="step-one-container">
            {stepOneArrowImage}
            <motion.div
              className="tour-count"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                default: { delay: 1.3, duration: 1 }
              }}
            >
              1
            </motion.div>
            <motion.span
              className="message"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                default: { delay: 1.3, duration: 1 }
              }}
            >
              You’ll get hints and tips here.
            </motion.span>
          </div>
        </div>
      ),
      style: {
        backgroundColor: "transparent",
        color: "white",
        width: "100%",
        height: "100%",
        maxWidth: "100%"
      }
    },
    {
      selector: ".app-tour-health",
      content: () => (
        <div className="fixed-position-two">
          {setpTwoArrowImage}
          <motion.div
            className="step-two-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              default: { delay: 1.3, duration: 1 }
            }}
          >
            <div className="tour-count">2</div>
            <div className="message">
              This bar will show you the overall health of our services.
              <br />
              Make sure it doesn’t drop too low!
              {/* <b>Make sure</b> it doesn’t drop too low! */}
            </div>
          </motion.div>
        </div>
      ),
      style: {
        backgroundColor: "transparent",
        color: "white",
        width: "100%",
        height: "100%",
        maxWidth: "100%"
      }
    },
    {
      selector: ".app-tour-badge ",
      content: () => (
        <div className="fixed-position-three">
          {stepThreeArrowImage}
          <motion.div
            className="step-three-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              default: { delay: 1.3, duration: 1 }
            }}
          >
            <div className="tour-count">
              <div>3</div>
            </div>
            <div className="message">
              These are the new technologies you'll
              <br /> use throughout.
            </div>
          </motion.div>
        </div>
      ),
      style: {
        backgroundColor: "transparent",
        color: "white",
        width: "100%",
        height: "100%",
        maxWidth: "100%"
      }
    }
  ];

  function MyCustomHelper({
    current,
    content,
    totalSteps,
    gotoStep,
    close
  }: any) {
    return (
      <main className="CustomHelper__wrapper">
        <div className="CustomHelper__content">
          {content}
          <div className="navigation">
            <motion.div
              onClick={() => {
                if (current !== 0) {
                  dispatch(setTourStepNumber(current - 1));
                  gotoStep(current - 1);
                  setFirstTimeLoaded(false);
                }
              }}
              className="CustomHelper__navArrow noSelect"
            >
              {current !== 0 ? (
                <motion.img
                  src={"/assets/images/next.svg"}
                  style={{ height: 20 }}
                  initial={{ rotate: 180 }}
                  // animate={{ marginLeft: [10, 0, 10, 0, 10, 0, 10] }}
                  transition={NextPrevTransition}
                />
              ) : (
                <div />
              )}
            </motion.div>
            {current === totalSteps - 1 ? (
              <button
                onClick={() => {
                  playButtonClickMusic();
                  dispatch(setTourOpen(false));
                }}
                className="done-button noSelect"
              >
                DONE
              </button>
            ) : (
              <motion.button
                onClick={() => {
                  playButtonClickMusic();
                  dispatch(setTourOpen(false));
                }}
                disabled={current === totalSteps - 1}
                className="skip-button-tour noSelect"
                initial={{ opacity: isFirstTimeLoaded ? 0 : 1 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 2, duration: 0.5 }}
              >
                SKIP
              </motion.button>
            )}
            <motion.div
              onClick={() => {
                if (current !== totalSteps - 1) {
                  dispatch(setTourStepNumber(current + 1));
                  gotoStep(current + 1);
                  setFirstTimeLoaded(false);
                }
              }}
              className="next-arrow noSelect"
            >
              {current !== totalSteps - 1 ? (
                <motion.img
                  src={"/assets/images/next.svg"}
                  style={{ height: 20 }}
                  // animate={{ marginRight: [10, 0, 10, 0, 10, 0, 10] }}
                  transition={NextPrevTransition}
                />
              ) : (
                <div />
              )}
            </motion.div>
          </div>
        </div>
      </main>
    );
  }

  return isTourOpen ? (
    <Tour
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => dispatch(setTourOpen(false))}
      CustomHelper={MyCustomHelper}
      maskSpace={0}
      rounded={1000}
    />
  ) : (
    <div></div>
  );
}
