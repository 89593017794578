import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState from "slices/system/system-initial-state";
import { SystemState, NodeParams } from "models/system";

import { Hint } from "models/hint";
import { UpdateBadge } from "models/badge";
import { SystemStates } from "constants/system-states";
import { getMinLimitForState } from "services/node-service";

export const systemSlice = createSlice({
  name: "System",
  initialState: initialState,
  reducers: {
    updateHint: updateHint,
    updateCapacity: updateCapacity,
    updateLoadProfile: updateLoadProfile,
    updateEarnedBadges: updateEarnedBadges,
    updateStateOfSystem: updateStateOfSystem,
    updateHealthOfSystem: updateHealthOfSystem,
    setDropTsbHealth: setDropTsbHealth,
    toggleSoundSetting: toggleSoundSetting,
    toggleMusicSetting: toggleMusicSetting,
    setTourOpen: setTourOpen,
    setShowIntroduction: setShowIntroduction,
    setTourStepNumber: setTourStepNumber,
    setShowStarFly: setShowStarFly,
    setShowBadgeDetails: setShowBadgeDetails,
    setShowHealthDetails: setShowHealthDetails,
    setShowPermissions: setShowPermissions,
    resetLoad: resetLoad,
    updateDropMoreHealth: updateDropMoreHealth,
    setShowLandscapePopup: setShowLandscapePopup,
    setShowUserDetailsScreen: setShowUserDetailsScreen,
    updateShowMissionAccomplished: updateShowMissionAccomplished,
    updateShowParams: updateShowParams,
    setShowNoInternetScreen: setShowNoInternetScreen,
    setCanUpdateSystemHealth: setCanUpdateSystemHealth,
    togglePlayRightActionMusic: togglePlayRightActionMusic,
    togglePlayHealthDropMusic: togglePlayHealthDropMusic,
    toggleBadgeEarnedMusic: toggleBadgeEarnedMusic,
    toggleButtonClickMusic: toggleButtonClickMusic,
    showPostMortemEmail: showPostMortemEmail
  }
});

function resetLoad(state: SystemState) {
  state.system.loadProfile.requests = 0;
}

function updateDropMoreHealth(
  state: SystemState,
  action: PayloadAction<boolean>
) {
  state.system.canUpdateHealth = true;
  state.system.dropMoreHealth = action.payload;
}

// Game-Engine Functions
function updateLoadProfile(state: SystemState, action: PayloadAction<number>) {
  let limit = getMinLimitForState(state.system.state);
  if (
    state.system.health > limit &&
    state.system.canUpdateHealth &&
    state.system.showEarnedBadge === false &&
    (state.system.hint.hintType !== "userAction" ||
      state.system.dropMoreHealth === true) &&
    !state.popupDetails.missionAccomplished &&
    !state.popupDetails.showPostMortemEmail &&
    !state.popupDetails.showPermissions &&
    !state.popupDetails.showLandscapePopup
  ) {
    state.system.loadProfile.requests =
      state.system.loadProfile.requests + action.payload;
  }
}

function updateCapacity(state: SystemState, action: PayloadAction<number>) {
  state.system.capacity = action.payload;
  // let systemHealth =
  //   100 - (state.system.loadProfile.requests / state.system.capacity) * 100;
  let systemHealth = state.system.capacity;
  state.system.health = systemHealth;

  if (
    state.system.state !== SystemStates.ISTIO_ZOOM_OUT &&
    state.system.state !== SystemStates.ENVOY_ZOOM_OUT &&
    state.system.state !== SystemStates.UPDATE &&
    state.system.state !== SystemStates.FAILOVER
  ) {
    state.system.showStarFly = true;
  }
}

function updateHealthOfSystem(state: SystemState) {
  //health of system will be ratio of demands to capacity
  // If health is in between 0.8-1.0 we can show orange color
  // if health is less than 0.9 then we will show green color
  // if health is greater than 1 we will show red color
  // let systemHealth = 100 - (state.system.loadProfile.requests / state.system.capacity) * 100;
  let limit = getMinLimitForState(state.system.state);
  if (
    state.system.health > limit &&
    state.system.canUpdateHealth &&
    state.system.showEarnedBadge === false &&
    state.popupDetails.showPostMortemEmail === false &&
    (state.system.hint.hintType !== "userAction" ||
      state.system.dropMoreHealth === true) &&
    !state.popupDetails.missionAccomplished &&
    !state.popupDetails.showLandscapePopup
  ) {
    let systemHealth =
      state.system.capacity - state.system.loadProfile.requests;
    state.system.health = Math.round(systemHealth);
    let isHealthy = systemHealth > 0;
  }
}

function updateStateOfSystem(
  state: SystemState,
  action: PayloadAction<SystemStates>
) {
  //need more clarification for freezing this one
  state.system.state = action.payload;
}

//this can be done in update state of system
function updateHint(state: SystemState, action: PayloadAction<Hint>) {
  // will need model for hint
  // boolean value show, if true show otherwise hide
  // Get hint object in payload
  state.system.hint = action.payload;
}

//this can be done in update state of system
function updateEarnedBadges(
  state: SystemState,
  action: PayloadAction<UpdateBadge>
) {
  // accept number i.e count of badges from payload
  // need to trigger event to show badge earned screen
  if (action.payload.badge !== null) {
    state.system.earnedBadges.push(action.payload.badge);
  }

  state.system.showEarnedBadge = action.payload.show;
}

function setDropTsbHealth(state: SystemState, action: PayloadAction<boolean>) {
  state.system.dropTsbHealth = action.payload;
}

function setCanUpdateSystemHealth(
  state: SystemState,
  action: PayloadAction<boolean>
) {
  state.system.canUpdateHealth = action.payload;
}

function toggleSoundSetting(state: SystemState) {
  state.musicDetails.volumeOn = !state.musicDetails.volumeOn;
}

function toggleMusicSetting(state: SystemState) {
  state.musicDetails.playAudio = !state.musicDetails.playAudio;
}

function togglePlayRightActionMusic(state: SystemState) {
  state.musicDetails.playRightActionMusic = !state.musicDetails
    .playRightActionMusic;
}
function toggleBadgeEarnedMusic(state: SystemState) {
  state.musicDetails.playBadgeEarnedMusic = !state.musicDetails
    .playBadgeEarnedMusic;
}
function toggleButtonClickMusic(state: SystemState) {
  state.musicDetails.playButtonClickMusic = !state.musicDetails
    .playButtonClickMusic;
}

function togglePlayHealthDropMusic(state: SystemState) {
  state.musicDetails.playHealthDropMusic = !state.musicDetails
    .playHealthDropMusic;
}

function setTourOpen(state: SystemState, action: PayloadAction<boolean>) {
  state.popupDetails.isTourOpen = action.payload;
}

function setShowIntroduction(
  state: SystemState,
  action: PayloadAction<boolean>
) {
  state.popupDetails.showIntroduction = action.payload;
}

function setTourStepNumber(state: SystemState, action: PayloadAction<number>) {
  state.popupDetails.tourStepNumber = action.payload;
}

function setShowStarFly(state: SystemState, action: PayloadAction<boolean>) {
  state.system.showStarFly = action.payload;
}

function setShowBadgeDetails(
  state: SystemState,
  action: PayloadAction<boolean>
) {
  state.popupDetails.showBadgeDetails = action.payload;
  state.system.canUpdateHealth = !action.payload;
}
function setShowHealthDetails(
  state: SystemState,
  action: PayloadAction<boolean>
) {
  state.popupDetails.showHealthDetails = action.payload;
  state.system.canUpdateHealth = !action.payload;
}
function setShowPermissions(
  state: SystemState,
  action: PayloadAction<boolean>
) {
  state.popupDetails.showPermissions = action.payload;
  state.system.canUpdateHealth = !action.payload;
}

function setShowLandscapePopup(
  state: SystemState,
  action: PayloadAction<boolean>
) {
  state.popupDetails.showLandscapePopup = action.payload;
  state.system.canUpdateHealth = !action.payload;
}

function setShowNoInternetScreen(
  state: SystemState,
  action: PayloadAction<boolean>
) {
  state.popupDetails.showNoInternetScreen = action.payload;
  state.system.canUpdateHealth = !action.payload;
}

function setShowUserDetailsScreen(
  state: SystemState,
  action: PayloadAction<boolean>
) {
  state.popupDetails.showUserDetailsScreen = action.payload;
  state.system.canUpdateHealth = !action.payload;
}

function updateShowMissionAccomplished(
  state: SystemState,
  action: PayloadAction<boolean>
) {
  state.popupDetails.missionAccomplished = action.payload;
}

function updateShowParams(
  state: SystemState,
  action: PayloadAction<NodeParams>
) {
  state.params = action.payload;
}
function showPostMortemEmail(
  state: SystemState,
  action: PayloadAction<boolean>
) {
  state.popupDetails.showPostMortemEmail = action.payload;
  state.system.canUpdateHealth = !action.payload;
}
