import { configureStore, Action } from "@reduxjs/toolkit";
import rootReducer, { RootState } from "redux-setup/root-reducer";
import thunk, { ThunkAction } from "redux-thunk";

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: true,
});

export default store;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
