import { AppThunk } from "redux-setup/store";
import {
  getuserDetailsStart,
  getuserDetailsSuccess,
  getuserDetailssFailure,
} from "slices/user-details";
import { saveUserDetails } from "services/user-service";
import { UserDetailsRequest } from "models/user";

export const submitUserDetails = (
  request: UserDetailsRequest
): AppThunk => async (dispatch) => {
  try {
    dispatch(getuserDetailsStart());
    const result = await saveUserDetails(request);
    if(result === null) {
      dispatch(getuserDetailssFailure("Empty Response"));
    }
    else {
      dispatch(getuserDetailsSuccess(result));
    }
  } catch (err) {
    dispatch(getuserDetailssFailure(err.toString()));
  }
};
