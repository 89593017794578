import React, { ReactElement } from "react";
import "components/topbar/topbar.css";
import Badges from "components/badges/badges";
import HealthBar from "components/health-bar/health-bar";
import { Hint } from "models/hint";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import HealthDetails from "components/health-details";
import { setShowHealthDetails, setShowPermissions } from "slices/system";
import Permissions from "components/permissions";

interface Props {}

export default function Topbar(): ReactElement {
  let system = useSelector((state: RootState) => {
    let obj = {
      showEarnedBadge: state.system.system.showEarnedBadge,
      hint: state.system.system.hint,
      isTourOpen: state.system.popupDetails.isTourOpen,
      tourStepNumber: state.system.popupDetails.tourStepNumber,
      showHealthDetails: state.system.popupDetails.showHealthDetails,
      showPermissions: state.system.popupDetails.showPermissions,
      showMissionAccomplished: state.system.popupDetails.missionAccomplished
    };

    return obj;
  }, shallowEqual);

  let dispatch = useDispatch();

  function handleHealthClick() {
    if ((system.isTourOpen && system.tourStepNumber === 1) === false) {
      dispatch(setShowHealthDetails(true));
    }
  }

  return system.showMissionAccomplished ? (
    <div></div>
  ) : (
    <div
      className={
        system.isTourOpen
          ? "topbar"
          : system.showEarnedBadge || system.hint.hintType === "userAction"
          ? " topbar hide-stage"
          : "topbar"
      }
    >
      <Badges
        showBadgeActive={system.isTourOpen && system.tourStepNumber === 2}
      />
      <HealthBar
        showHealthBarActive={system.isTourOpen && system.tourStepNumber === 1}
        handleHealthClick={handleHealthClick}
      />
      {system.showHealthDetails && system.showEarnedBadge === false ? (
        <HealthDetails />
      ) : (
        <div />
      )}
      {system.showPermissions ? <Permissions /> : ""}
    </div>
  );
}
