import { systemSlice } from "slices/system/system-slice";

export const {
  updateHint,
  updateCapacity,
  updateLoadProfile,
  updateEarnedBadges,
  updateStateOfSystem,
  updateHealthOfSystem,
  setDropTsbHealth,
  toggleSoundSetting,
  toggleMusicSetting,
  setTourOpen,
  setShowIntroduction,
  setTourStepNumber,
  setShowStarFly,
  setShowBadgeDetails,
  setShowHealthDetails,
  setShowPermissions,
  resetLoad,
  setShowLandscapePopup,
  setShowNoInternetScreen,
  setShowUserDetailsScreen,
  updateShowMissionAccomplished,
  updateDropMoreHealth,
  updateShowParams,
  setCanUpdateSystemHealth,
  togglePlayRightActionMusic,
  togglePlayHealthDropMusic,
  toggleBadgeEarnedMusic,
  toggleButtonClickMusic,
  showPostMortemEmail
} = systemSlice.actions;

export default systemSlice.reducer;
