import React, { ReactElement, useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { Node } from "models/system";
import { motion } from "framer-motion";
import { keyframes } from "styled-components";
import { SystemStates } from "constants/system-states";

interface Props {
  source: string;
  destination: string;
  animationDelay: number;
  className: string;
  shouldAnimate: boolean;
  curve: string;
  cluster: string;
  animationTimer: number;
}

export default function DynamicEdge(props: Props): ReactElement {
  const icon = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1
    }
  };

  let elements = useSelector((state: RootState) => {
    let sourceElement: Array<Node> = state.stageNodes.filter(node => {
      if (props.shouldAnimate && node.isAnimating) {
        return [];
      } else return node.nodeId === props.source;
    });

    let destinationElement: Array<Node> = state.stageNodes.filter(node => {
      if (props.shouldAnimate && node.isAnimating) {
        return [];
      } else return node.nodeId === props.destination;
    });

    let health = state.system.system.health;
    let systemState = state.system.system.state;
    let showLandscapePopup = state.system.popupDetails.showLandscapePopup;

    return {
      sourceElement,
      destinationElement,
      health,
      systemState,
      showLandscapePopup
    };
  }, shallowEqual);

  let frequency =
    props.cluster === "failover-sec"
      ? (1).toFixed(1)
      : props.animationTimer === 0.3
      ? (0.3).toFixed(1)
      : elements.systemState !== SystemStates.BEGIN_ZOOMED &&
        elements.systemState !== SystemStates.BEGIN
      ? (props.animationTimer * 1).toFixed(1)
      : (((elements.health - 10) * props.animationTimer) / 100).toFixed(1);

  let animationFrequency =
    parseFloat(frequency) <= 0.3
      ? 0.3
      : elements.systemState === SystemStates.BEGIN &&
        parseFloat(frequency) < 0.8
      ? 0.3
      : parseFloat(frequency);

  function renderPath() {
    let sourceX, sourceY, qEndX, qEndY;

    if (
      elements.sourceElement.length > 0 &&
      elements.destinationElement.length > 0
    ) {
      let sourceElement = elements.sourceElement[0];
      let destinationElement = elements.destinationElement[0];

      if (props.curve !== "NA") {
        if (sourceElement.yPosition > destinationElement.yPosition) {
          sourceX = sourceElement.xPosition + sourceElement.width / 2;
          sourceY = sourceElement.yPosition;
          if (props.curve === "start") {
            qEndX = sourceX + (sourceY - destinationElement.yPosition);
            qEndY =
              sourceY -
              (sourceY - destinationElement.yPosition) +
              destinationElement.height / 2;
          }
        } else if (
          sourceElement.yPosition + sourceElement.height <
          destinationElement.yPosition
        ) {
          sourceX = sourceElement.xPosition + sourceElement.width / 2;
          sourceY = sourceElement.yPosition + sourceElement.height;
          if (props.curve === "start") {
            qEndX = sourceX - (sourceY - destinationElement.yPosition);
            qEndY =
              sourceY -
              (sourceY - destinationElement.yPosition) +
              destinationElement.height / 2;
          }
        } else {
          sourceX = sourceElement.xPosition + sourceElement.width - 7;
          sourceY =
            destinationElement.height > 1
              ? sourceElement.yPosition + destinationElement.height / 2
              : destinationElement.yPosition;
          qEndX = sourceX;
          qEndY = sourceY;
        }
      } else {
        if (sourceElement.nodeId.includes("lb")) {
          sourceX = sourceElement.xPosition + sourceElement.width / 2;
          sourceY = sourceElement.yPosition;
        } else {
          sourceX = sourceElement.xPosition + (sourceElement.width / 100) * 15;
          sourceY = sourceElement.yPosition + (sourceElement.height / 100) * 30;
        }
      }

      let path;
      if (props.curve === "start") {
        path = [
          "M",
          sourceX,
          sourceY,
          "Q",
          sourceX,
          qEndY,
          qEndX,
          qEndY,
          "H",
          destinationElement.xPosition
        ].join(" ");
      } else if (props.curve === "end") {
        let destinationX =
          destinationElement.xPosition + (destinationElement.width / 100) * 10;
        let destinationY =
          destinationElement.yPosition + (destinationElement.height / 100) * 30;
        path = [
          "M",
          sourceX,
          sourceY,
          "H",
          destinationX - (destinationY - sourceY),
          "Q",
          destinationX,
          sourceY,
          destinationX,
          destinationY
        ].join(" ");
      } else if (props.curve === "horizontal") {
        path = [
          "M",
          sourceElement.xPosition + sourceElement.width,
          sourceElement.yPosition + sourceElement.height / 2,
          "H",
          destinationElement.xPosition
        ].join(" ");
      } else {
        path = [
          "M",
          sourceX,
          sourceY,
          "V",

          destinationElement.yPosition + (destinationElement.height / 100) * 25
        ].join(" ");
      }
      return props.shouldAnimate &&
        !sourceElement.isAnimating &&
        !destinationElement.isAnimating ? (
        <motion.g>
          <motion.path
            fill="none"
            d={path}
            style={{
              fill: "none",
              stroke:
                elements.health < 36 ||
                (elements.health < 67 &&
                  (elements.systemState === SystemStates.ADDED_ISTIO ||
                    elements.systemState === SystemStates.ISTIO_ZOOM_OUT))
                  ? "red"
                  : "#3A955E",
              strokeWidth: "1px"
            }}
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={{
              duration: props.animationDelay === 0 ? 0 : props.animationDelay,
              ease: "easeInOut",
              delay: props.animationDelay === 0 ? 0 : props.animationDelay
            }}
          />

          {props.className !== "dotted-line" ? (
            <motion.path
              className={
                elements.showLandscapePopup ? "dotted-line" : props.className
              }
              fill="none"
              d={path}
              style={{
                stroke:
                  elements.health < 36 ||
                  (elements.health < 67 &&
                    (elements.systemState === SystemStates.ADDED_ISTIO ||
                      elements.systemState === SystemStates.ISTIO_ZOOM_OUT))
                    ? "red"
                    : "#3A955E",
                animation: elements.showLandscapePopup
                  ? "dotted-line"
                  : (props.className !== "flow-initial"
                      ? props.className === "flow-reverse"
                        ? "dash-reverse "
                        : "dash "
                      : "round ") +
                    animationFrequency +
                    "s linear infinite"
              }}
              variants={icon}
              initial="hidden"
              animate="visible"
              transition={{
                duration:
                  props.animationDelay === 0 ? 0 : props.animationDelay + 1,
                ease: "easeInOut",
                delay:
                  props.animationDelay === 0 ? 0 : props.animationDelay + 1.5
              }}
            />
          ) : (
            ""
          )}
        </motion.g>
      ) : !props.shouldAnimate ? (
        <g>
          {props.className !== "dotted-line" ? (
            <path
              fill="none"
              d={path}
              style={{
                stroke:
                  elements.health < 36 ||
                  (elements.health < 67 &&
                    (elements.systemState === SystemStates.ADDED_ISTIO ||
                      elements.systemState === SystemStates.ISTIO_ZOOM_OUT))
                    ? "red"
                    : "#3A955E",
                animation:
                  (props.cluster === "failover" && elements.health < 36
                    ? "blinker "
                    : "") +
                  animationFrequency +
                  "s linear infinite"
              }}
            ></path>
          ) : (
            ""
          )}
          <path
            className={
              elements.showLandscapePopup ? "dotted-line" : props.className
            }
            fill="none"
            d={path}
            style={{
              animation:
                (props.className !== "flow-initial"
                  ? props.cluster === "failover" && elements.health < 36
                    ? "blinker "
                    : props.className === "flow-reverse"
                    ? "dash-reverse "
                    : "dash "
                  : "round ") +
                animationFrequency +
                "s linear infinite",
              stroke:
                elements.health < 36 ||
                (elements.health < 67 &&
                  (elements.systemState === SystemStates.ADDED_ISTIO ||
                    elements.systemState === SystemStates.ISTIO_ZOOM_OUT))
                  ? "red"
                  : "#3A955E"
            }}
          ></path>
        </g>
      ) : (
        ""
      );
    }
  }

  return (
    <motion.g>
      {elements.sourceElement[0]?.xPosition !== 0 &&
      elements.sourceElement[0]?.yPosition !== 0 &&
      elements.destinationElement[0]?.xPosition !== 0 &&
      elements.destinationElement[0]?.yPosition !== 0
        ? renderPath()
        : ""}
    </motion.g>
  );
}
