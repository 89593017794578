import React, {
  ReactElement, useEffect
} from "react";
import "components/internet-issue/internet-issue.css";
import TetrateLogo from "components/tetrate-logo";
import { motion } from "framer-motion";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { setShowNoInternetScreen } from "slices/system";
import { v4 as uuidv4 } from "uuid";
import{
  playButtonClickMusic
} from "services/npc-service";

export default function InternetIssue(): ReactElement {

  let showNoInternetScreen = useSelector((state: RootState) => {
    return state.system.popupDetails.showNoInternetScreen;
  }, shallowEqual);

  const variants = {
    open: { x: "0%", y: "0%", scale: 1, opacity: 1 },
    closed: { opacity: 0, scale: 0 }
  };
  let dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('load', () => {
      // 1st, we set the correct status when the page loads
      navigator.onLine ? dispatch(setShowNoInternetScreen(false)) : dispatch(setShowNoInternetScreen(true));

      // now we listen for network status changes
      window.addEventListener('online', () => {
        dispatch(setShowNoInternetScreen(false))
      });

      window.addEventListener('offline', () => {
        dispatch(setShowNoInternetScreen(true))
      });
    });
  }, []);

  function handleStartGameAction() {
    var condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      fetch('https://www.google.com/', { // Check for internet connectivity
        mode: 'no-cors',
      })
        .then(() => {
          dispatch(setShowNoInternetScreen(false))
        }).catch(() => {
          dispatch(setShowNoInternetScreen(true))
        })
    } else {
      dispatch(setShowNoInternetScreen(true))
    }
  }

  return (
    <div>
      {showNoInternetScreen ? (
        <motion.div
          key={uuidv4()}
          className="internet-screen"
          initial={{ x: "0%", y: "0%", scale: 1, opacity: 0 }}
          animate={showNoInternetScreen ? "open" : "closed"}
          variants={variants}
          transition={{
            duration: 1
          }}
        >
          <motion.div className="internet-background"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}>

            <TetrateLogo animateTheGame={false} />
            <div className="retry-msg">
              Seems like you are not connected to the internet.
            </div>
            <div
              className="internet-action-button noSelect"
              onClick={evt => {
                playButtonClickMusic()
                handleStartGameAction()
              }}>
              <div className="internet-button-title">
                RETRY
              </div>
            </div>
          </motion.div>

        </motion.div>
      ) : (
          ""
        )}
    </div>
  )
}