export function getBadgeFor(value: number) {
  switch (value) {
    case 1:
      return {
        value: 1,
        message: "Congratulations!",
        title: "Envoy is enabled!",
        subtitle: "With Envoy you can:",
        imageSrc: "/assets/images/earned_envoy.svg",
        badgeName: "envoy",
        badgeSubtitle: "badge",
        xPosDesktop: "calc(-23vw - 71%)",
        xPosmobile: "calc(-20vw + 1%)",
        yPosDesktop: "calc(2vh - 390%)",
        yPosmobile: "calc(20vh - 300%)",
        badgeDetails: [
          {
            detail: "Control traffic flow",
          },
          {
            detail: "Apply uniform policies",
          },
          {
            detail: "Gather metrics",
          },
        ],
      };
    case 2:
      return {
        value: 2,
        message: "Congratulations!",
        title: "We’ve enabled Istio!",
        subtitle: "With Istio you get:",
        imageSrc: "/assets/images/earned_istio.svg",
        badgeName: "istio",
        badgeSubtitle: "badge",
        xPosDesktop: "calc(-23vw - 50%)",
        xPosmobile: "calc(1vw - 75%)",
        yPosDesktop: "calc(2vh - 100%)",
        yPosmobile: "calc(12vh - 100%)",
        badgeDetails: [
          {
            detail: "Easy traffic management for all of your services",
          },
          {
            detail:
              "Identity, Authentication, and Authorization for your services",
          },
          {
            detail: "Application Layer (L7) metrics and tracing",
          },
        ],
      };
    case 3:
      return {
        value: 3,
        message: "Congratulations!",
        title: "TSB is installed!",
        subtitle: "Now we can:",
        imageSrc: "/assets/images/TSB_earned.svg",
        badgeName: "TSB",
        badgeSubtitle: "BADGE",
        xPosDesktop: "calc(-23vw - 8%)",
        xPosmobile: "calc(20vw - 150%)",
        yPosDesktop: "calc(2vh - 100%)",
        yPosmobile: "calc(12vh - 100%)",
        badgeDetails: [
          {
            detail: "Manage all clusters with a single platform",
          },
          {
            detail: "Set strict permissions for users",
          },
          {
            detail: "Always run the most updated version of Istio",
          },
          {
            detail: "Track your global deployments in one dashboard",
          },
        ],
      };

    default:
      return {
        value: 2,
        message: "",
        title: "MISSION ACCOMPLISHED",
        subtitle: "You have completed the game by earning these 3 badges.",
        imageSrc: "",
        badgeName: "DOUBLE",
        badgeSubtitle: "CAPACITY",
        xPosDesktop: "calc(-23vw - 10%)",
        xPosmobile: "calc(12vw - 100%)",
        yPosDesktop: "calc(2vh - 100%)",
        yPosmobile: "calc(12vh - 100%)",
        badgeDetails: [],
      };
  }
}
