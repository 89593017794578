import React, { ReactElement, useRef } from "react";
import "components/mission-accomplish/mission-accomplish.css";
import AboutUs from "components/aboutus";
import { motion, useCycle, AnimatePresence } from "framer-motion";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { v4 as uuidv4 } from "uuid";
import { setShowUserDetailsScreen } from "slices/system";
import {
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TwitterIcon,
  EmailIcon,
  LinkedinIcon
} from "react-share";
import ReactGA from "react-ga";
import { EMAIL_BODY, SHARE_SUBJECT, SHARE_URL } from "configs/app-config";

const badges = [
  {
    title: "ENVOY",
    image: "/assets/images/mission_envoy.svg"
  },
  {
    title: "ISTIO",
    image: "/assets/images/mission-istio.svg"
  },
  {
    title: "TSB",
    image: "/assets/images/mission-tsb.svg"
  }
];

export default function MissionAccomplished(): ReactElement {
  let system = useSelector((state: RootState) => {
    let obj = {
      missionAccomplished: state.system.popupDetails.missionAccomplished,
      showUserDetailsScreen: state.system.popupDetails.showUserDetailsScreen
    };

    return obj;
  }, shallowEqual);

  const [showShare, toggleshowShare] = useCycle(false, true);
  const [showAboutUs, toggleShowAboutUs] = useCycle(false, true);
  const containerRef = useRef(null);

  let dispatch = useDispatch();

  const variants = {
    open: { x: "0%", y: "0%", scale: 1, opacity: 1 },
    closed: { opacity: 0, scale: 0 }
  };

  function handleAboutUsAction() {
    toggleShowAboutUs();
  }

  function sendShareInformation(name: string) {
    ReactGA.event({
      category: "User",
      action: "User shared link on " + name
    });
  }

  return system.missionAccomplished && !system.showUserDetailsScreen ? (
    <motion.div
      className="misson-background"
      key={uuidv4()}
      initial={{ x: "0%", y: "0%", scale: 0, opacity: 0 }}
      animate={system.missionAccomplished ? "open" : "closed"}
      variants={variants}
      transition={{
        duration: 0
      }}
    >
      <motion.div className="mission-screen">
        <AnimatePresence>
          {showAboutUs && (
            <motion.nav
              initial={"closed"}
              exit={"closed"}
              animate={showAboutUs ? "open" : "closed"}
              onAnimationComplete={() => console.log("end")}
              ref={containerRef}
              variants={{}}
            >
              <AboutUs key="aboutus" onClose={() => toggleShowAboutUs()} />
            </motion.nav>
          )}
          {showShare && (
            <motion.nav
              initial={"closed"}
              exit={"closed"}
              animate={showShare ? "open" : "closed"}
              onAnimationComplete={() => console.log("end")}
              ref={containerRef}
              variants={{}}
            ></motion.nav>
          )}
        </AnimatePresence>
        {!showShare && !showAboutUs ? (
          <motion.div
            className="mission-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <div className="mission-accomplished">MISSION ACCOMPLISHED</div>
            <div className="you-have-completed-t">
              You have completed the game by earning these 3 badges.
            </div>

            <div className="image-container mission-badge-title">
              {badges.map((badge: any, index: number) => {
                return (
                  <div className="mission-tsb" key={index}>
                    <img className="img" src={badge.image}></img>
                    <div>{badge.title}</div>
                    <div className="mission-badge-text">BADGE</div>
                  </div>
                );
              })}
            </div>

            <div
              className="info-bg noSelect"
              onClick={evt => handleAboutUsAction()}
            >
              <div className="mission-info-button" />
            </div>
            <div
              className="learn-more noSelect"
              onClick={evt => {
                dispatch(setShowUserDetailsScreen(true));
              }}
            >
              <div className="learn-more-title">LEARN MORE</div>
            </div>
            <motion.div className="button-bg">
              <EmailShareButton
                url={""}
                subject={SHARE_SUBJECT}
                body={EMAIL_BODY}
                className="share"
              >
                <EmailIcon
                  size={42}
                  round={true}
                  onClick={() => sendShareInformation("Email")}
                />
              </EmailShareButton>
              <TwitterShareButton
                url={SHARE_URL}
                title={SHARE_SUBJECT}
                className="share"
                onClick={() => sendShareInformation("Twitter")}
              >
                <TwitterIcon size={42} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={SHARE_URL}
                title={SHARE_SUBJECT}
                className="share noSelect"
                onClick={() => sendShareInformation("Linkedin")}
              >
                <LinkedinIcon size={42} round={true} />
              </LinkedinShareButton>
            </motion.div>
          </motion.div>
        ) : (
          ""
        )}
      </motion.div>
    </motion.div>
  ) : (
    <div></div>
  );
}
