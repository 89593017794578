import {
  UserDetailsResult
} from "models/user";

const userDetailsInitialState: UserDetailsResult = {
  inlineMessage:"",
  isLoading: false,
  error: null
};

export default userDetailsInitialState;
