import React, { ReactElement } from "react";
import "components/settings/settings.css";
import { useDispatch } from "react-redux";
import { 
  toggleSoundSetting,
  toggleMusicSetting
} from "slices/system";
import { motion } from "framer-motion";

interface Props {
  onClose: Function
}

export default function Settings({ onClose }: Props): ReactElement {

  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

  const optionVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

  const dispatch = useDispatch();

  function handleSoundsAction() {
    dispatch(toggleSoundSetting())
  }

  function handleMusicAction() {
    dispatch(toggleMusicSetting())
  }

  return (
    <motion.div className="settings-update-background">
      <motion.div className="settings" variants={optionVariants}>
        SETTINGS
        </motion.div>
      <motion.div className="settings-button-bg" variants={optionVariants} onClick={evt => onClose()} />

      <motion.ul variants={variants} className="settings-update-options-container">
        <motion.li
          variants={optionVariants}
          whileTap={{ scale: 0.95 }}
        >
          <div className="settings-update-action-button noSelect" onClick={evt => handleSoundsAction()}>
            <div className="settings-update-button-title"> SOUND </div>
          </div>
        </motion.li>
        <motion.li
          variants={optionVariants}
          whileTap={{ scale: 0.95 }}
        >
          <div className="settings-update-action-button noSelect" onClick={evt => handleMusicAction()}>
            <div className="settings-update-button-title"> MUSIC </div>
          </div>
        </motion.li>
      </motion.ul>
    </motion.div>
  )
}