export const TITLE = "Tetrate : MAKE IT MESH";
export const LOGO_SUBTITLE = "MAKE IT MESH";
export const TERMS_URL = "https://www.tetrate.io/terms-of-use/";
export const PRIVACY_POLICY_URL =
  "https://www.tetrate.io/pdfs/PrivacyPolicy.pdf";
export const CONTACT_US_URL = "https://www.tetrate.io/contact/";
export const SHARE_SUBJECT = "Check this out";
export const SHARE_URL = "https://demo.tetrate.io/";
export const EMAIL_BODY =
  "Look what I found! This is a teaser to Tetrate Service Bridge. I like what they have to offer and want you to check it out as well. " +
  SHARE_URL +
  " Let's discuss more. regards,";
export const GA_ID = "UA-120539154-1";
export const FORM_OPENED = "User Details form opened";
export const FORM_CLOSED = "User Details Form Closed";
export const FORM_SUBMITTED = "User Details Form Submitted";
export const GAME_START = "Game start";
export const USER_CLICKED = "User clicked on ";
