import React, { ReactElement, useRef, MutableRefObject, useState } from "react";
import { motion, useCycle, useMotionValue, useTransform } from "framer-motion";
import "./microservice.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { updateNode, addNode } from "slices/stage-nodes";
import { Node } from "models/system";

interface Props {
  strokeColor?: string;
  xPosition: string;
  yPosition: string;
  animate: any;
  key: string;
  node: any;
  fillColour: string;
  id: string;
}

export default function Microservice(props: Props): ReactElement {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(0);
  let health = useSelector((state: RootState) => {
    return state.system.system.health;
  });

  const pathVariants = {
    hidden: { opacity: 0, stroke: "#3A955E" },
    unhealthy: { opacity: 1, stroke: "red" },
    healthy: { opacity: 1, stroke: "#3A955E" }
  };

  const microserviceRef = useRef() as MutableRefObject<SVGRectElement>;

  let nodeItem: Node = {
    nodeId: props.id,
    xPosition: 0,
    yPosition: 0,
    height: 0,
    width: 0,
    isAnimating: true
  };

  dispatch(addNode(nodeItem));

  function onAnimationStart() {
    let timer = setInterval(() => {
      if (microserviceRef !== null && microserviceRef.current !== null) {
        let obj = microserviceRef.current.getBBox();

        let nodeItem: Node = {
          nodeId: props.id,
          xPosition: obj.x,
          yPosition: obj.y,
          height: obj.height,
          width: obj.width,
          isAnimating: true
        };

        dispatch(updateNode(nodeItem));
      }
    }, 10);
    setTimer(timer);
  }

  function onAnimationComplete() {
    clearTimeout(timer);
    if (microserviceRef !== null && microserviceRef.current !== null) {
      let obj = microserviceRef.current.getBBox();

      let nodeItem: Node = {
        nodeId: props.id,
        xPosition: obj.x,
        yPosition: obj.y,
        height: obj.height,
        width: obj.width,
        isAnimating: false
      };

      dispatch(updateNode(nodeItem));
    }
  }

  return (
    <motion.g ref={microserviceRef}>
      <motion.svg
        overflow="visible"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 56 56"
        height={props.node.height}
        width={props.node.width}
        x={props.xPosition}
        y={props.yPosition}
      >
        <motion.g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          variants={props.animate}
          initial="hidden"
          animate="visible"
          transition={{
            default: {
              duration: 0.5,
              delay: props.node.animationDelay ?? 0,
              ease: "easeInOut"
            }
          }}
          onAnimationStart={onAnimationStart}
          onAnimationComplete={onAnimationComplete}
          style={{ height: "135vw", width: "135vh" }}
        >
          <motion.g id="microservice" transform="translate(1.000000, 1.000000)">
            <motion.g transform="translate(-1.000000, 0.000000)">
              <motion.polygon
                id="Path-2"
                animate={health < 42 ? "unhealthy" : "healthy"}
                variants={pathVariants}
                stroke-width="1.5"
                fill="#3B3F40"
                stroke-linecap="round"
                stroke-linejoin="round"
                points="1 12.2363479 27.6152312 0 54.7492106 12.2363479 54.7492106 38.3650043 27.8746053 53.9903915 1 38.3650043"
              ></motion.polygon>
              <motion.g
                id="Color_brown"
                transform="translate(0.906406, 12.205880)"
                fill={props.fillColour}
                animate={health < 42 ? "unhealthy" : "healthy"}
                variants={pathVariants}
              >
                <motion.polygon
                  id="Path-5-Copy"
                  points="53.5 25.717444 26.7583019 41 0.5 25.717444 0.5 0.705463989 26.7583019 11.8464308 53.5 0.5"
                ></motion.polygon>
              </motion.g>
              <motion.polyline
                id="Path-3"
                animate={health < 42 ? "unhealthy" : "healthy"}
                variants={pathVariants}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                points="0.906405596 12.7066369 26.5108862 23.6351485 52.6959906 12.7066369"
              ></motion.polyline>
              <motion.line
                x1="26.8746053"
                y1="23.6351485"
                x2="26.8746053"
                y2="53.1320228"
                id="Path-4"
                animate={health < 42 ? "unhealthy" : "healthy"}
                variants={pathVariants}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></motion.line>
            </motion.g>
          </motion.g>
        </motion.g>
      </motion.svg>
    </motion.g>
  );
}
