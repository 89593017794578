import axios from "axios";
import {
  UserDetailsResult,
  UserDetailsRequest
} from "models/user";
import UrlConfig, { HUBSPOT_PORTAL_ID, HUBSPOT_FORM_ID } from "configs/hubspot-config";

export async function saveUserDetails(request: UserDetailsRequest) {
  try {
    const url =
      UrlConfig.BASE_URL +
      HUBSPOT_PORTAL_ID +
      "/" + HUBSPOT_FORM_ID;
    const { data } = await axios.post<UserDetailsResult>(url, request);
    return data;
  } catch (err) {
    console.log(err)
    throw err;
  }
}
