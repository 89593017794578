import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import userDetailsInitialState from "slices/user-details/user-details-initial-state";
import {
  UserDetailsResult
} from "models/user";
import { act } from "react-dom/test-utils";

export const userDetails = createSlice({
  name: "userDetails",
  initialState: userDetailsInitialState,
  reducers: {
    getuserDetailsStart: startLoading,
    getuserDetailsSuccess: loadingSuccess,
    getuserDetailssFailure: loadingFailed,
    resetuserState: resetuserState
  },
});

function startLoading(state: UserDetailsResult) {
  state.isLoading = true;
}

function loadingFailed(
  state: UserDetailsResult,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

function loadingSuccess(
  state: UserDetailsResult,
  action: PayloadAction<UserDetailsResult>
) {
  state.inlineMessage = action.payload.inlineMessage;
  state.isLoading = false;
  state.error = null;
}

function resetuserState(
  state: UserDetailsResult,
  action: PayloadAction<string>
) {
  state.inlineMessage = action.payload;
  state.isLoading = false;
  state.error = null;
}
