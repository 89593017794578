import React, { ReactElement, useRef, MutableRefObject } from "react";
import { motion } from "framer-motion";
import "./istio.css";
import { useDispatch } from "react-redux";
import { updateShowMissionAccomplished, updateShowParams } from "slices/system";
import { addNode, updateNode } from "slices/stage-nodes";
import { Node, NodeParams } from "models/system";

interface Props {
  strokeColor?: string;
  xPosition: string;
  yPosition: string;
  animate: any;
  key: string;
  node: any;
  id: string;
}

export default function ISTIO(props: Props): ReactElement {
  const dispatch = useDispatch();

  const istioRef = useRef() as MutableRefObject<SVGRectElement>;

  let nodeItem: Node = {
    nodeId: props.id,
    xPosition: 0,
    yPosition: 0,
    height: 0,
    width: 0,
    isAnimating: true
  };

  dispatch(addNode(nodeItem));

  function onAnimationComplete() {
    if (istioRef !== null && istioRef.current !== null) {
      let obj = istioRef.current.getBBox();

      let nodeItem: Node = {
        nodeId: props.id,
        xPosition: obj.x,
        yPosition: obj.y,
        height: obj.height,
        width: obj.width,
        isAnimating: false
      };

      dispatch(updateNode(nodeItem));
    }
  }

  return (
    <motion.g ref={istioRef}>
      <motion.svg
        version="1.1"
        viewBox="0 0 190 280"
        overflow="visible"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        height={props.node.height}
        width={props.node.width}
        x={props.xPosition}
        y={props.yPosition}
      >
        <motion.defs>
          <radialGradient
            cx="49.2342841%"
            cy="21.4438537%"
            fx="49.2342841%"
            fy="21.4438537%"
            r="80.7326751%"
            id="radialGradient-1"
          >
            <motion.stop stop-color="#434748" offset="0%"></motion.stop>
            <motion.stop stop-color="#202426" offset="100%"></motion.stop>
          </radialGradient>
        </motion.defs>
        <motion.g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          variants={props.animate}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 0.5, delay: props.node.animationDelay ?? 5 }
          }}
          onAnimationComplete={onAnimationComplete}
        >
          <motion.g id="11.1" transform="translate(-367.000000, -57.000000)">
            <motion.g
              id="ISTIO-Applied"
              transform="translate(0.000000, 9.000000)"
            >
              <motion.g
                id="Group-4"
                transform="translate(368.000000, 49.500000)"
              >
                <motion.polygon
                  id="Rectangle"
                  stroke="#717171"
                  fill="#1A1A1A"
                  opacity="0.503408668"
                  points="0 0 168 0 168 277 0 277"
                ></motion.polygon>
                <motion.g
                  id="Istio_Icon"
                  transform="translate(148.000000, 75.500000)"
                  initial={
                    props.node.setOpacity != undefined &&
                    props.node.setOpacity === false
                      ? { opacity: 1 }
                      : { opacity: 0 }
                  }
                  animate={{ opacity: 1 }}
                  transition={{
                    default: {
                      duration: 0.5,
                      delay: (props.node.animationDelay ?? 5) + 0.5
                    }
                  }}
                >
                  <motion.g id="Group-3">
                    <motion.circle
                      id="Oval"
                      stroke="#3A955E"
                      stroke-width="1.40625"
                      fill="url(#radialGradient-1)"
                      fill-rule="nonzero"
                      cx="20"
                      cy="20"
                      r="20"
                    ></motion.circle>

                    <motion.path
                      d="M20.0702403,7.78505618 L20.0702403,26.0724146 L28.6557446,26.9633195 L20.0702403,7.78505618 Z"
                      id="Path"
                      stroke="url(#linearGradient-2)"
                      stroke-width="0.2"
                      fill="#FFFFFF"
                    ></motion.path>
                    <motion.path
                      d="M18.7742926,16.0989972 L13.2607411,26.970286 L18.7742926,26.2327386 L18.7742926,16.0989972 Z"
                      id="Path-2"
                      stroke="url(#linearGradient-3)"
                      stroke-width="0.2"
                      fill="#FFFFFF"
                    ></motion.path>
                    <motion.path
                      d="M29.201299,27.8336582 L13.0116126,27.8336582 L18.9405769,30.7926695 L29.201299,27.8336582 Z"
                      id="Path-3"
                      stroke="url(#linearGradient-4)"
                      stroke-width="0.2"
                      fill="#FFFFFF"
                    ></motion.path>
                  </motion.g>
                </motion.g>
              </motion.g>
            </motion.g>
          </motion.g>
        </motion.g>
      </motion.svg>
    </motion.g>
  );
}
