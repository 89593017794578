import React, { ReactElement } from "react";
import "components/badges/badges.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { getBadgeFor } from "services/badge-service";
import BadgeDetails from "components/badge-details";
import { setShowBadgeDetails } from "slices/system";

interface Props {
  showBadgeActive: boolean;
}

export default function Badges(props: Props): ReactElement {
  let badges = [1, 2, 3];
  let system = useSelector((state: RootState) => {
    return state.system;
  });
  let dispatch = useDispatch();

  function handleBadgeClick() {
    if (props.showBadgeActive === false) {
      dispatch(setShowBadgeDetails(true));
    }
  }

  function renderBadge() {
    return badges.map((item: any, index: number) => (
      <div key={index}>
        <div
          onClick={handleBadgeClick}
          key={index}
          className={
            system.system.earnedBadges.length >= index + 1
              ? system.system.earnedBadges.length === index + 1
                ? system.system.showEarnedBadge
                  ? getBadgeFor(item).badgeName + "-locked badge"
                  : getBadgeFor(item).badgeName + " badge badge-animate"
                : getBadgeFor(item).badgeName + " badge"
              : getBadgeFor(item).badgeName + "-locked badge"
          }
        ></div>
      </div>
    ));
  }

  return (
    <div
      className="badge-bar"
      style={props.showBadgeActive ? { zIndex: 111111 } : {}}
    >
      {props.showBadgeActive ? <div className="app-tour-badge"></div> : ""}
      {renderBadge()}
      {system.popupDetails.showBadgeDetails &&
      system.system.showEarnedBadge === false ? (
        <BadgeDetails />
      ) : (
        <div />
      )}
    </div>
  );
}
