import React, { ReactElement, useRef, MutableRefObject, useState } from "react";
import { motion } from "framer-motion";
import "./load-balancer.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { updateNode, addNode } from "slices/stage-nodes";
import { Node } from "models/system";
import { SystemStates } from "constants/system-states";

interface Props {
  strokeColor?: string;
  xPosition: string;
  yPosition: string;
  animate: any;
  key: string;
  node: any;
  id: string;
}

export default function LoadBalancer(props: Props): ReactElement {
  let step = useSelector((state: RootState) => {
    return state.system.system.state;
  });

  let health = useSelector((state: RootState) => {
    return state.system.system.health;
  });

  const [timer, setTimer] = useState(0);

  const dispatch = useDispatch();
  const loadBalancerRef = useRef() as MutableRefObject<SVGRectElement>;

  let nodeItem: Node = {
    nodeId: props.id,
    xPosition: 0,
    yPosition: 0,
    height: 0,
    width: 0,
    isAnimating: true
  };

  dispatch(addNode(nodeItem));

  const pathVariants = {
    hidden: { opacity: 0, stroke: "#3A955E" },
    unhealthy: { opacity: 1, stroke: "red" },
    healthy: { opacity: 1, stroke: "#3A955E" }
  };

  function onAnimationStart() {
    let timer = setInterval(() => {
      if (loadBalancerRef !== null && loadBalancerRef.current !== null) {
        let obj = loadBalancerRef.current.getBBox();

        let nodeItem: Node = {
          nodeId: props.id,
          xPosition: obj.x,
          yPosition: obj.y,
          height: obj.height,
          width: obj.width,
          isAnimating: true
        };

        dispatch(updateNode(nodeItem));
      }
    }, 10);
    setTimer(timer);
  }

  function onAnimationComplete() {
    clearTimeout(timer);
    if (loadBalancerRef !== null && loadBalancerRef.current !== null) {
      let obj = loadBalancerRef.current.getBBox();

      let nodeItem: Node = {
        nodeId: props.id,
        xPosition: obj.x,
        yPosition: obj.y,
        height: obj.height,
        width: obj.width,
        isAnimating: false
      };

      dispatch(updateNode(nodeItem));
    }
  }

  function getHealthLimit() {
    return 42;
  }

  return (
    <motion.g ref={loadBalancerRef}>
      <motion.svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        overflow="visible"
        height={props.node.height}
        width={props.node.width}
        x={props.xPosition}
        y={props.yPosition}
      >
        <motion.defs>
          <motion.radialGradient
            cx="49.2342841%"
            cy="21.4438537%"
            fx="49.2342841%"
            fy="21.4438537%"
            r="80.7326751%"
            id="radialGradient-1"
          >
            <motion.stop stopColor="#434748" offset="0%"></motion.stop>
            <motion.stop stopColor="#202426" offset="100%"></motion.stop>
          </motion.radialGradient>
          <motion.circle
            id="path-2"
            cx="21.5384615"
            cy="21.5384615"
            r="21.5384615"
          ></motion.circle>
          <motion.filter
            x="-9.3%"
            y="-9.3%"
            width="118.6%"
            height="118.6%"
            filterUnits="objectBoundingBox"
            id="filter-3"
          >
            <motion.feMorphology
              radius="1.5"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></motion.feMorphology>
            <motion.feOffset
              dx="0"
              dy="0"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></motion.feOffset>
            <motion.feGaussianBlur
              stdDeviation="0.5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></motion.feGaussianBlur>
            <motion.feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></motion.feComposite>
            <motion.feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.497049825 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></motion.feColorMatrix>
          </motion.filter>
        </motion.defs>
        <motion.g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          variants={props.animate}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 0.5, ease: "easeInOut" }
          }}
          onAnimationComplete={onAnimationComplete}
          onAnimationStart={onAnimationStart}
        >
          <motion.g id="1" transform="translate(-104.000000, -183.000000)">
            <motion.g id="Group" transform="translate(-1.000000, 61.000000)">
              <motion.g
                id="Load-Balancer"
                transform="translate(106.500000, 123.500000)"
              >
                <motion.g
                  id="Group-8"
                  transform="translate(2.461538, 2.461538)"
                >
                  <motion.g id="Oval">
                    <motion.use
                      fill="black"
                      fill-opacity="1"
                      filter="url(#filter-3)"
                      xlinkHref="#path-2"
                    ></motion.use>
                    <motion.use
                      stroke-width="2"
                      fill="url(#radialGradient-1)"
                      fill-rule="evenodd"
                      xlinkHref="#path-2"
                      animate={
                        health < getHealthLimit() ? "unhealthy" : "healthy"
                      }
                      variants={pathVariants}
                      initial="hidden"
                    ></motion.use>
                  </motion.g>
                  <motion.g
                    id="Group-5"
                    transform="translate(20.923077, 21.153846) scale(-1, -1) rotate(270.000000) translate(-20.923077, -21.153846) translate(4.923077, 6.153846)"
                    stroke="#FFFFFF"
                    stroke-width="1.11888112"
                  >
                    <motion.g
                      id="Group-7"
                      transform="translate(0.943565, 0.538760)"
                    >
                      <motion.ellipse
                        id="Oval"
                        cx="15.2759363"
                        cy="14.5073261"
                        rx="5.03583276"
                        ry="5.0153619"
                      ></motion.ellipse>
                      <motion.ellipse
                        id="Oval-Copy"
                        cx="15.2759363"
                        cy="25.9710104"
                        rx="2.87761872"
                        ry="2.86592108"
                      ></motion.ellipse>
                      <motion.line
                        x1="15.2759363"
                        y1="19.3449673"
                        x2="15.2759363"
                        y2="23.1050894"
                        id="Path-7-Copy"
                        stroke-linejoin="round"
                      ></motion.line>
                      <motion.polyline
                        id="Path-6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        points="11.3848984 4.13900297 15.2759363 0 19.2574267 4.13900297"
                      ></motion.polyline>
                      <motion.line
                        x1="15.2759363"
                        y1="0"
                        x2="15.2759363"
                        y2="9.49196422"
                        id="Path-7"
                        stroke-linejoin="round"
                      ></motion.line>
                      <motion.polyline
                        id="Path-6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        transform="translate(27.463016, 11.016035) rotate(70.000000) translate(-27.463016, -11.016035) "
                        points="23.5408814 13.0955372 27.4233819 8.93653299 31.3851511 13.0852519"
                      ></motion.polyline>
                      <motion.line
                        x1="24.9318116"
                        y1="7.12769345"
                        x2="24.91936"
                        y2="16.6538683"
                        id="Path-7"
                        stroke-linejoin="round"
                        transform="translate(24.925586, 11.890781) rotate(70.000000) translate(-24.925586, -11.890781) "
                      ></motion.line>
                      <motion.polyline
                        id="Path-6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        transform="translate(3.439637, 10.516035) scale(-1, 1) rotate(70.000000) translate(-3.439637, -10.516035) "
                        points="-0.482498166 12.5955372 3.40000237 8.43653299 7.36177154 12.5852519"
                      ></motion.polyline>
                      <motion.line
                        x1="5.80644646"
                        y1="6.7515227"
                        x2="6.05790255"
                        y2="16.2782047"
                        id="Path-7"
                        stroke-linejoin="round"
                        transform="translate(5.932175, 11.514864) scale(-1, 1) rotate(70.000000) translate(-5.932175, -11.514864) "
                      ></motion.line>
                    </motion.g>
                  </motion.g>
                </motion.g>
              </motion.g>
            </motion.g>
          </motion.g>
        </motion.g>
      </motion.svg>
    </motion.g>
  );
}
