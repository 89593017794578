import { SystemState } from "models/system";

import { SystemStates } from "constants/system-states";

const initialState: SystemState = {
  system: {
    dropTsbHealth: false,
    loadProfile: {
      requests: 0
    },
    health: 100,
    capacity: 110,
    earnedBadges: [],
    state: SystemStates.BEGIN,
    hint: {
      showHint: false,
      hintText: "",
      hintType: "",
      actions: []
    },
    showEarnedBadge: false,
    canUpdateHealth: true,
    showStarFly: false,
    dropMoreHealth: false
  },
  musicDetails: {
    playAudio: false,
    volumeOn: false,
    playRightActionMusic: false,
    playHealthDropMusic: false,
    playBadgeEarnedMusic: false,
    playButtonClickMusic: false
  },
  popupDetails: {
    showIntroduction: true,
    isTourOpen: false,
    tourStepNumber: 0,
    missionAccomplished: false,
    showBadgeDetails: false,
    showHealthDetails: false,
    showPermissions: false,
    showLandscapePopup: false,
    showNoInternetScreen: false,
    showUserDetailsScreen: false,
    showPostMortemEmail: false
  },
  params: {
    nodeId: "",
    showParams: false
  }
};

export default initialState;
