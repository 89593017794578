import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState from "slices/stage-nodes/stage-nodes-initial-state";
import { Node } from "models/system";

export const StageNodeSlice = createSlice({
  name: "StageNodes",
  initialState: initialState,
  reducers: {
    addNode: addNode,
    updateNode: updateNode,
    clearNodes: clearNodes,
  },
});

function addNode(state: Array<Node>, action: PayloadAction<Node>) {
  let filterArr = state.filter((node) => node.nodeId === action.payload.nodeId);
  if (filterArr.length === 0) state.push(action.payload);
}

function updateNode(state: Array<Node>, action: PayloadAction<Node>) {
  let item = action.payload;
  state.forEach((node) => {
    if (node.nodeId === item.nodeId) {
      node.xPosition = item.xPosition;
      node.yPosition = item.yPosition;
      node.height = item.height;
      node.width = item.width;
      node.isAnimating = item.isAnimating;
    }
  });
}

function clearNodes(state: Array<Node>) {
  state.length = 0;
}
