import React, {
  ReactElement,
  useEffect,
  useState,
  useRef,
  MutableRefObject
} from "react";
import { motion } from "framer-motion";
import "./monolith.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { SystemStates } from "constants/system-states";
import { updateNode, addNode } from "slices/stage-nodes";
import { Node } from "models/system";

interface Props {
  strokeColor?: string;
  xPosition: string;
  yPosition: string;
  animate: any;
  key: string;
  node: any;
  id: string;
}

export default function Monolith(props: Props): ReactElement {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(0);

  let step = useSelector((state: RootState) => {
    return state.system.system.state;
  });

  let health = useSelector((state: RootState) => {
    return state.system.system.health;
  });

  const pathVariants = {
    hidden: { opacity: 0, stroke: "#3A955E" },
    unhealthy: { opacity: 1, stroke: "red" },
    healthy: { opacity: 1, stroke: "#3A955E" }
  };

  var THREE_POLYGONS = (
    <motion.g id="Carved-Monolith">
      <motion.g id="Monolith_3-layers">
        <motion.polyline
          id="Path-3"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          points="1.95509308 13.3058374 26.3007091 24.4477701 52.0743199 12.921215"
          animate={health < getHealthLimit() ? "unhealthy" : "healthy"}
          variants={pathVariants}
          initial="hidden"
        ></motion.polyline>
        <motion.polygon
          id="Path-3-Copy-2"
          fill="#C5C5C5"
          points="21.3805593 8.4690218 35.972049 14.740269 41.8017862 12.4914934 27.0147065 5.86044474"
        ></motion.polygon>
        <motion.polygon
          id="Path-3-Copy-3"
          fill="#C5C5C5"
          points="11.8822725 12.921215 25.8593861 19.160198 32.171255 16.4252042 17.9283077 10.3203399"
        ></motion.polygon>
        <motion.polygon
          id="Path-5-Copy"
          fill="#FFA68B"
          transform="translate(39.450000, 23.250000) scale(-1, 1) translate(-39.450000, -23.250000) "
          points="52.2 33 52.2 24 26.7 13.5 26.7 20.7307692"
        ></motion.polygon>
        <motion.polygon
          id="Path-5-Copy"
          fill="#FBFBC7"
          transform="translate(39.700000, 31.500000) scale(-1, 1) translate(-39.700000, -31.500000) "
          points="52.2 42 52.2 33 27.2 21 27.2 28.2307692"
        ></motion.polygon>
        <motion.polygon
          id="Path-5-Copy"
          fill="#B3ECB7"
          transform="translate(39.700000, 40.250000) scale(-1, 1) translate(-39.700000, -40.250000) "
          points="52.2 51.5 52.2 42.5 27.2 29 27.2 37.2307692"
        ></motion.polygon>
        <motion.polygon
          id="Path-5-Copy"
          fill="#FFA68B"
          points="26 32.5 26 24 1 13.5 1 20.7307692"
        ></motion.polygon>
        <motion.polygon
          id="Path-5-Copy"
          fill="#FBFBC7"
          points="26 42 26 33 1 21.5 1 28.7307692"
        ></motion.polygon>
        <motion.polygon
          id="Path-5-Copy"
          fill="#B3ECB7"
          points="26 51.5 26 42.5 1.5 29.5 1.5 37.2307692"
        ></motion.polygon>
        <motion.polyline
          id="Path-10-Copy"
          stroke="#FFFFFF"
          points="1.04490692 29.2611446 26.8154051 42.5534594 52.0449069 28.5"
        ></motion.polyline>
        <motion.polyline
          id="Path-10-Copy"
          stroke="#FFFFFF"
          points="1.04490692 21.2611446 26.8154051 33.0534594 52.0449069 21"
        ></motion.polyline>
        <motion.line
          x1="26.6615446"
          y1="23.4477701"
          x2="26.6615446"
          y2="52.7107945"
          id="Path-4"
          stroke="#3A955E"
          stroke-width="1.5"
          stroke-linejoin="round"
          animate={health < getHealthLimit() ? "unhealthy" : "healthy"}
          variants={pathVariants}
          initial="hidden"
        ></motion.line>
        <motion.path
          d="M26.4072265,0.824087772 L0.75,12.6199932 L0.75,37.6293571 L26.6615446,52.6948032 L52.5730893,37.6293571 L52.5730893,12.6238535 L26.4072265,0.824087772 Z"
          id="Path-2"
          stroke="#3A955E"
          stroke-width="1.5"
          animate={health < getHealthLimit() ? "unhealthy" : "healthy"}
          variants={pathVariants}
          initial="hidden"
        ></motion.path>
      </motion.g>
    </motion.g>
  );

  var TWO_POLYGONS = (
    <motion.g id="Total-Carved">
      <motion.g
        id="Monolith_2-layers"
        transform="translate(-0.000000, 0.000000)"
      >
        <motion.polygon
          id="Path-5-Copy-2"
          fill="#FBFBC7"
          transform="translate(39.700000, 38.000000) scale(-1, 1) translate(-39.700000, -38.000000) "
          points="52.2 51.5 52.2 36.2692308 27.2 24.5 27.2 37.7307692"
          strokeOpacity={1}
        ></motion.polygon>
        <motion.polygon
          id="Path-5-Copy-2"
          fill="#FBFBC7"
          points="26 51.5 26 36.2692308 1.5 25.5 1.5 37.2307692"
          strokeOpacity={1}
        ></motion.polygon>
        <motion.polygon
          id="Path-5-Copy"
          fill="#FFA68B"
          transform="translate(39.700000, 24.500000) scale(-1, 1) translate(-39.700000, -24.500000) "
          points="52.2 35.5 52.2 23.5 27.2 13.5 27.2 23.7307692"
          strokeOpacity={1}
        ></motion.polygon>
        <motion.polygon
          id="Path-5-Copy"
          fill="#FFA68B"
          points="26 35.5 26 23.7692308 1.5 13.5 1.5 24.7307692"
          strokeOpacity={1}
        ></motion.polygon>
        <motion.polyline
          id="Path-10-Copy"
          stroke="#FFFFFF"
          points="1.04490692 24.7611446 26.8154051 36.0534594 52.0449069 24"
        ></motion.polyline>
        <motion.path
          d="M26.4072265,0.824087772 L0.75,12.6199932 L0.75,37.6293571 L26.6615446,52.6948032 L52.5730893,37.6293571 L52.5730893,12.6238535 L26.4072265,0.824087772 Z"
          id="Path-2"
          stroke-width="1.5"
          animate={health < getHealthLimit() ? "unhealthy" : "healthy"}
          variants={pathVariants}
          initial="hidden"
        ></motion.path>
        <motion.polyline
          id="Path-3"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          points="1.95509308 13.3058374 26.3007091 24.4477701 52.0743199 12.921215"
          animate={health < getHealthLimit() ? "unhealthy" : "healthy"}
          variants={pathVariants}
          initial="hidden"
        ></motion.polyline>
        <motion.polygon
          id="Path-3-Copy-2"
          fill="#C5C5C5"
          points="21.3805593 8.4690218 35.972049 14.740269 41.8017862 12.4914934 27.0147065 5.86044474"
        ></motion.polygon>
        <motion.polygon
          id="Path-3-Copy-3"
          fill="#C5C5C5"
          points="11.8822725 12.921215 25.8593861 19.160198 32.171255 16.4252042 17.9283077 10.3203399"
        ></motion.polygon>
        <motion.line
          x1="26.6615446"
          y1="23.4477701"
          x2="26.6615446"
          y2="52.7107945"
          id="Path-4"
          stroke="#3A955E"
          stroke-width="1.5"
          stroke-linejoin="round"
          animate={health < getHealthLimit() ? "unhealthy" : "healthy"}
          variants={pathVariants}
          initial="hidden"
        ></motion.line>
      </motion.g>
    </motion.g>
  );

  var FIVE_POLYGONS = (
    <motion.g id="Monolith-Green-Copy">
      <motion.g
        id="Monolith_5-layers"
        transform="translate(-0.000000, 0.000000)"
      >
        <motion.g id="Path-8" transform="translate(0.000000, 0.000000)">
          <motion.polygon
            fill="#00B39D"
            points="1.45509308 32.9594727 1.45509308 37.2754092 26.4115446 51.9594727 52 39.3290977 52 34.0374556 26.4115446 46.9594727"
          ></motion.polygon>
          <motion.polygon
            id="Path-8-Copy"
            fill="#B3ECB7"
            points="1.45509308 27.9594727 1.45509308 32.661065 26.4115446 46.4594727 52 34.2134535 52 29.0474503 26.4115446 41.1615173"
          ></motion.polygon>
          <motion.polygon
            id="Path-8-Copy-2"
            fill="#FFFAC4"
            opacity="0.889999986"
            points="1.45509308 23.416137 1.45509308 28.0393052 26.4115446 41.1615173 52 29.0474503 52 23.9676177 26.4115446 35.9519335"
          ></motion.polygon>
          <motion.polygon
            id="Path-8-Copy-3"
            fill="#FFA68B"
            points="1.45509308 18.5974669 1.45509308 23.416137 26.4115446 35.9519335 52 24.2300938 52 18.9354482 26.4115446 31.029589"
          ></motion.polygon>
          <motion.polygon
            id="Path-8-Copy-4"
            fill="#F0BE84"
            points="1.45509308 13.8415279 1.45509308 18.660198 26.4115446 31.029589 52.0743199 18.916137 52.0743199 13.6214914 26.4115446 25.0997054"
          ></motion.polygon>
          <motion.polyline
            id="Path-10"
            stroke="#FFFFFF"
            points="1.04490692 18.7611446 26.8154051 31.309618 52.0449069 19"
          ></motion.polyline>
          <motion.polyline
            id="Path-10-Copy"
            stroke="#FFFFFF"
            points="1.04490692 23.7611446 26.8154051 36.5534594 52.0449069 24"
          ></motion.polyline>
          <motion.polyline
            id="Path-10-Copy-2"
            stroke="#FFFFFF"
            points="1.04490692 27.7611446 26.8154051 41.1615173 52.0449069 28"
          ></motion.polyline>
          <motion.polyline
            id="Path-10-Copy-3"
            stroke="#FFFFFF"
            points="1.23388672 32.7611446 26.6615446 46.4594727 52.0449069 34"
          ></motion.polyline>
          <motion.mask id="mask-2" fill="white">
            <motion.use xlinkHref="#path-1"></motion.use>
          </motion.mask>
          <motion.path
            animate={health < getHealthLimit() ? "unhealthy" : "healthy"}
            variants={pathVariants}
            initial="hidden"
            stroke-width="2"
            d="M26.4072265,0.824087772 L0.75,12.6199932 L0.75,37.6293571 L26.6862459,52.7091649 L52.5730893,39.5999668 L52.5730893,12.6238535 L26.4072265,0.824087772 Z"
          ></motion.path>
        </motion.g>
        <motion.polyline
          id="Path-3"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          points="1.95509308 13.3058374 26.3007091 24.4477701 52.0743199 12.921215"
          animate={health < getHealthLimit() ? "unhealthy" : "healthy"}
          variants={pathVariants}
          initial="hidden"
        ></motion.polyline>
        <motion.polygon
          id="Path-3-Copy-2"
          fill="#C5C5C5"
          points="21.3805593 8.4690218 35.972049 14.740269 41.8017862 12.4914934 27.0147065 5.86044474"
        ></motion.polygon>
        <motion.polygon
          id="Path-3-Copy-3"
          fill="#C5C5C5"
          points="11.8822725 12.921215 25.8593861 19.160198 32.171255 16.4252042 17.9283077 10.3203399"
        ></motion.polygon>
        <motion.line
          x1="26.6615446"
          y1="23.4477701"
          x2="26.6615446"
          y2="52.7107945"
          id="Path-4"
          stroke-width="1.5"
          stroke-linejoin="round"
          animate={health < getHealthLimit() ? "unhealthy" : "healthy"}
          variants={pathVariants}
          initial="hidden"
        ></motion.line>
      </motion.g>
    </motion.g>
  );

  const monolithRef = useRef() as MutableRefObject<SVGRectElement>;

  let nodeItem: Node = {
    nodeId: props.id,
    xPosition: 0,
    yPosition: 0,
    height: 0,
    width: 0,
    isAnimating: true
  };

  dispatch(addNode(nodeItem));

  const [isUpdate, setUpdated] = useState(props.node.updateNode ?? false);

  function getHealthLimit() {
    return 42;
  }

  useEffect(() => {
    let interval: any = null;
    if (interval === null && isUpdate) {
      interval = setInterval(() => {
        setUpdated(false);
        clearInterval(interval);
      }, props.node.nodeUpdateDelay ?? 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isUpdate, props.node.nodeUpdateDelay]);

  const transition = { default: { duration: 2, ease: "easeInOut" } };

  function getPolylines() {
    if (step === SystemStates.BEGIN || step === SystemStates.BEGIN_ZOOMED) {
      return FIVE_POLYGONS;
    } else if (step === SystemStates.DECOMPOSED_MONOLITHS) {
      if (isUpdate) {
        return FIVE_POLYGONS;
      } else {
        return THREE_POLYGONS;
      }
    } else if (step === SystemStates.ENVOY_ZOOM_OUT) {
      if (isUpdate) {
        return THREE_POLYGONS;
      } else {
        return TWO_POLYGONS;
      }
    } else if (step === SystemStates.ADDED_ENVOY) {
      return THREE_POLYGONS;
    } else if (
      step === SystemStates.ADDED_ISTIO ||
      step === SystemStates.ISTIO_ZOOM_OUT ||
      step === SystemStates.FAILOVER ||
      step === SystemStates.ADD_TSB ||
      step === SystemStates.UPDATE ||
      step === SystemStates.TSB_FAILOVER
    ) {
      return TWO_POLYGONS;
    } else {
      return FIVE_POLYGONS;
    }
  }

  function onAnimationStart() {
    let timer = setInterval(() => {
      if (monolithRef !== null && monolithRef.current !== null) {
        let obj = monolithRef.current.getBBox();

        let nodeItem: Node = {
          nodeId: props.id,
          xPosition: obj.x,
          yPosition: obj.y,
          height: obj.height,
          width: obj.width,
          isAnimating: true
        };

        dispatch(updateNode(nodeItem));
      }
    }, 10);
    setTimer(timer);
  }

  function onAnimationComplete() {
    clearTimeout(timer);
    if (monolithRef !== null && monolithRef.current !== null) {
      let obj = monolithRef.current.getBBox();

      let nodeItem: Node = {
        nodeId: props.id,
        xPosition: obj.x,
        yPosition: obj.y,
        height: obj.height,
        width: obj.width,
        isAnimating: false
      };

      dispatch(updateNode(nodeItem));
    }
  }

  return (
    <motion.g ref={monolithRef}>
      <motion.svg
        overflow="visible"
        viewBox="0 0 54 54"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        height={props.node.height}
        width={props.node.width}
        x={props.xPosition}
        y={props.yPosition}
      >
        <motion.defs>
          <motion.polygon
            id="path-1"
            points="6.75015599e-14 12.1393387 26.4042269 -1.42108547e-14 53.3230893 12.1393387 53.3230893 40.0608483 26.6615446 53.5623581 6.75015599e-14 38.0608483"
          ></motion.polygon>
        </motion.defs>
        <motion.g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          variants={props.animate}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 0.5, ease: "easeInOut" }
          }}
          onAnimationStart={onAnimationStart}
          onAnimationComplete={onAnimationComplete}
        >
          {getPolylines()}
        </motion.g>
      </motion.svg>
    </motion.g>
  );
}
