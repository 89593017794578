
import React, { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactAudioPlayer from 'react-audio-player';
import { RootState } from "redux-setup/root-reducer";
const mp3_file = require('audio/melody.mp3');
const health_drop = require('audio/health-drop.mp3');
const right_action = require('audio/right-action.mp3');

export default function AudiPlayer(): ReactElement {

  var audioEl = React.createRef<ReactAudioPlayer>();
  var musicEl = React.createRef<ReactAudioPlayer>();
  let musicDetails = useSelector((state: RootState) => {
    return state.system.musicDetails;
  });

  return (
    <div>
      {
        musicDetails.playAudio ?
        <ReactAudioPlayer
        id="music"
        key="music"
          ref={musicEl}
          src={mp3_file}
          autoPlay={true}
          controls={false}
          onError={(e) => { }}
          preload="metadata"
          onLoadedMetadata={(e) => { }}
          onCanPlay={(e) => { }}
          loop={true}
          muted={!musicDetails.playAudio}
          volume={
            musicDetails.playHealthDropMusic || 
              musicDetails.playRightActionMusic ||
              musicDetails.playBadgeEarnedMusic || 
              musicDetails.playButtonClickMusic
            ? 0.1 : 1
          }
        />
        : ""
      }
      {
        musicDetails.playHealthDropMusic ||
        musicDetails.playRightActionMusic  ||
        musicDetails.playBadgeEarnedMusic || 
        musicDetails.playButtonClickMusic ?
        <ReactAudioPlayer
        id="health"
        key="health"
          ref={audioEl}
          src={
            musicDetails.playRightActionMusic ||
            musicDetails.playBadgeEarnedMusic || 
            musicDetails.playButtonClickMusic ?
            right_action 
            : health_drop
          }
          autoPlay={true}
          controls={false}
          onError={(e) => { }}
          preload="metadata"
          onLoadedMetadata={(e) => { }}
          onCanPlay={(e) => { }}
          muted={!musicDetails.volumeOn}
          loop={false}
        />
        : ""
      }
    </div>
  )
}

