import { SystemStates } from "constants/system-states";
import NodeType from "models/node-type";

export function getCurrentStepConfig(stepName: string) {
  let step = steps.filter((step) => step.stepName === stepName)[0];
  return step;
}

export const steps: Array<any> = [
  {
    stepName: SystemStates.BEGIN,
    nodes: [
      {
        type: NodeType.LOADBALANCER,
        xPosition: "10%",
        yPosition: "38%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "b-lb",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "-15%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "b-m-0",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "30%",
        yPosition: "12%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "b-m-1",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "30%",
        yPosition: "38%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 1,
          },
          visible: {
            opacity: 1,
            scale: 1,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "b-m-2",
      },
    ],
    edges: [
      {
        sourceId: "b-m-0",
        destinationId: "b-lb",
        shouldAnimate: false,
        className: "flow-initial",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:1
      },
      {
        sourceId: "b-lb",
        destinationId: "b-m-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:1
      },
      {
        sourceId: "b-lb",
        destinationId: "b-m-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "horizontal",
        cluster: "NA",
        animationFrequency:1
      }
    ],
  },
  {
    stepName: SystemStates.BEGIN_ZOOMED,
    nodes: [
      {
        type: NodeType.LOADBALANCER,
        xPosition: "10%",
        yPosition: "38%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            scale: 1,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            scale: 1.3,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "b-lb",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "-30%",
        yPosition: "38%",
        width: "1%",
        height: "1%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0,
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.1,
          },
        },
        strokeColor: "#1ED761",
        id: "bz-bn-0",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "10%",
        yPosition: "12%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 1,
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 1.3,
            x: 5,
            y: -115,
          },
        },
        strokeColor: "#1ED761",
        id: "bz-m-1",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "30%",
        yPosition: "38%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            scale: 1,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            scale: 1.5,
            x: 90,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "bz-m-2",
      },
    ],
    edges: [
      {
        sourceId: "bz-bn-0",
        destinationId: "b-lb",
        shouldAnimate: false,
        className: "flow-initial",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "bz-lb-m2",
        sourceId: "b-lb",
        destinationId: "bz-m-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:"NA"
      },
      {
        // id: "bz-lb-m2",
        sourceId: "b-lb",
        destinationId: "bz-m-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "horizontal",
        cluster: "NA",
        animationFrequency:0.3
      },
    ],
  },
  {
    stepName: SystemStates.DECOMPOSED_MONOLITHS,
    nodes: [
      {
        type: NodeType.LOADBALANCER,
        xPosition: "10%",
        yPosition: "38%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            scale: 1.3,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            scale: 1,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "dm-lb",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "-15%",
        yPosition: "39%",
        width: "15%",
        height: "15%",
        updateNode: true,
        nodeUpdateDelay: 3000,
        animate: {
          hidden: {
            opacity: 1,
            scale: 1.3,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            scale: 1,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "dm-m-0",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "10%",
        yPosition: "12%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 1,
            x: 120,
            y: -115,
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 1.3,
            x: 120,
            y: -115,
          },
        },
        strokeColor: "#1ED761",
        id: "dm-m-1",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "45%",
        yPosition: "37%",
        width: "15%",
        height: "15%",
        updateNode: true,
        nodeUpdateDelay: 3000,
        animate: {
          hidden: {
            opacity: 1,
            scale: 1.3,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            scale: 1,
            x: -30,
            y: -70,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "dm-m-2",
      },
      {
        type: NodeType.MICROSERVICE,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 2,
        animate: {
          hidden: {
            opacity: 1,
            scale: 0,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            scale: 1,
            x: 190,
            y: 30,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "dm-ms-1",
      },
      {
        type: NodeType.MICROSERVICE,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 2,
        animate: {
          hidden: {
            opacity: 1,
            scale: 0,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            scale: 1,
            x: 165,
            y: 65,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "dm-ms-2",
      },
      {
        type: NodeType.MICROSERVICE,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 2,
        animate: {
          hidden: {
            opacity: 1,
            scale: 0,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            scale: 1,
            x: 165,
            y: 130,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "dm-ms-3",
      },
      {
        type: NodeType.MICROSERVICE,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 2,
        animate: {
          hidden: {
            opacity: 1,
            scale: 0,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            scale: 1,
            x: 140,
            y: 165,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "dm-ms-4",
      },
    ],
    edges: [
      {
        // id: "dm-lb",
        sourceId: "dm-m-0",
        destinationId: "dm-lb",
        shouldAnimate: false,
        className: "flow-initial",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "dm-lb",
        sourceId: "dm-lb",
        destinationId: "dm-m-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:"NA"
      },
      {
        // id: "dm-lb",
        sourceId: "dm-lb",
        destinationId: "dm-m-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "dm-lb",
        sourceId: "dm-lb",
        destinationId: "dm-ms-1",
        shouldAnimate: true,
        className: "flow",
        animationDelay: 0.7,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        sourceId: "dm-lb",
        destinationId: "dm-ms-2",
        shouldAnimate: true,
        animationDelay: 0.7,
        className: "flow",
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        sourceId: "dm-lb",
        destinationId: "dm-ms-3",
        animationDelay: 0.7,
        shouldAnimate: true,
        className: "flow",
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        sourceId: "dm-lb",
        destinationId: "dm-ms-4",
        animationDelay: 0.7,
        shouldAnimate: true,
        className: "flow",
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        sourceId: "dm-m-2",
        destinationId: "dm-ms-1",
        animationDelay: 0.7,
        shouldAnimate: true,
        className: "flow",
        curve: "end",
        cluster: "NA",
        animationFrequency:0.3
      },
    ],
  },
  {
    stepName: SystemStates.ADDED_ENVOY,
    nodes: [
      {
        type: NodeType.LOADBALANCER,
        xPosition: "10%",
        yPosition: "38%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            scale: 1.3,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            scale: 1,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "ae-lb",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "-15%",
        yPosition: "40%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            scale: 1,
            x: -30,
            y: -70,
            stroke: "#1ED761",
          },
          visible: {
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "ae-m-0",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "10%",
        yPosition: "12%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 1,
            x: 120,
            y: -115,
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 1,
            x: 120,
            y: -115,
          },
        },
        strokeColor: "#1ED761",
        id: "ae-m-1",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "45%",
        yPosition: "37%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            scale: 1,
            x: -30,
            y: -70,
            stroke: "#1ED761",
          },
          visible: {
            stroke: "#1ED761",
            x: -30,
            y: -70,
          },
        },
        strokeColor: "#1ED761",
        id: "ae-m-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 5,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 1,
              stroke: "#1ED761",
              x: 190,
              y: 30,
            },
            visible: {
              opacity: 1,
              scale: 1,
              stroke: "#1ED761",
            },
          },
          envoy: {
            hidden: {
              opacity: 0,
              x: 15,
              scale: 0,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: {
              opacity: 1,
              x: 3,
              y: 11,
              scale: 0.7,
            },
          },
          strokeColor: "#1ED761",
        },
        id: "ae-ms-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 5,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 1,
              x: 165,
              y: 65,
            },
            visible: {
              opacity: 1,
              scale: 1,
              stroke: "#1ED761",
            },
          },
          envoy: {
            hidden: {
              opacity: 0,
              x: 15,
              scale: 0,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: {
              opacity: 1,
              x: 3,
              y: 11,
              scale: 0.7,
            },
          },
        },
        strokeColor: "#1ED761",
        id: "ae-ms-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 5,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 1,
              x: 165,
              y: 130,
            },
            visible: {
              opacity: 1,
              scale: 1,
              stroke: "#1ED761",
            },
          },
          envoy: {
            hidden: {
              opacity: 0,
              x: 15,
              scale: 0,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: {
              opacity: 1,
              x: 3,
              y: 11,
              scale: 0.7,
            },
          },
        },
        strokeColor: "#1ED761",
        id: "ae-ms-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 5,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 1,
              x: 140,
              y: 165,
            },
            visible: {
              opacity: 1,
              scale: 1,
              stroke: "#1ED761",
            },
          },
          envoy: {
            hidden: {
              opacity: 0,
              x: 15,
              scale: 0,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: {
              opacity: 1,
              x: 3,
              y: 11,
              scale: 0.7,
            },
          },
        },
        strokeColor: "#1ED761",
        id: "ae-ms-4",
      },
    ],
    edges: [
      {
        // id: "ae-lb",
        sourceId: "ae-m-0",
        destinationId: "ae-lb",
        shouldAnimate: false,
        className: "flow-initial",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ae-lb",
        destinationId: "ae-m-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:"NA"
      },
      {
        // id: "ae-lb",
        sourceId: "ae-lb",
        destinationId: "ae-m-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },

      {
        // id: "ae-lb-m1",
        sourceId: "ae-lb",
        destinationId: "ae-ms-1",
        shouldAnimate: true,
        animationDelay: 0,
        className: "flow",
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-m1-ms1",
        sourceId: "ae-lb",
        destinationId: "ae-ms-2",
        shouldAnimate: true,
        animationDelay: 0,
        className: "flow",
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb-ms1-ms2",
        sourceId: "ae-lb",
        destinationId: "ae-ms-3",
        shouldAnimate: true,
        animationDelay: 0,
        className: "flow",
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb-ms3-ms4",
        sourceId: "ae-lb",
        destinationId: "ae-ms-4",
        shouldAnimate: true,
        animationDelay: 0,
        className: "flow",
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb-ms3-ms4",
        sourceId: "ae-m-2",
        destinationId: "ae-ms-1",
        shouldAnimate: false,
        animationDelay: 3,
        className: "flow",
        curve: "end",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "ae-ms-2",
        destinationId: "ae-ms-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 3,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
    ],
  },
  {
    stepName: SystemStates.ENVOY_ZOOM_OUT,
    nodes: [
      {
        type: NodeType.LOADBALANCER,
        xPosition: "10%",
        yPosition: "38%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            scale: 1.3,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            scale: 1,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "ez-lb",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "-15%",
        yPosition: "40%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            scale: 1,
            x: -30,
            y: -70,
            stroke: "#1ED761",
          },
          visible: {
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "ez-m-0",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "10%",
        yPosition: "12%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 1,
            x: 120,
            y: -115,
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 1.3,
            x: 120,
            y: -115,
          },
        },
        strokeColor: "#1ED761",
        id: "ez-m-1",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "45%",
        yPosition: "37%",
        width: "15%",
        height: "15%",
        updateNode: true,
        nodeUpdateDelay: 400,
        animate: {
          hidden: {
            opacity: 1,
            scale: 1.3,
            stroke: "#1ED761",
            x: -30,
            y: -70,
          },
          visible: {
            opacity: 1,
            scale: 0.8,
            x: -90,
            y: -50,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "ez-m-2",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "25%",
        yPosition: "22.4%",
        width: "1%",
        height: "1%",
        updateNode: true,
        nodeUpdateDelay: 400,
        animate: {
          hidden: {
            opacity: 0,
            scale: 0,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 0,
            scale: 0.1,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "ez-bn-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 1,
              stroke: "#1ED761",
              x: 190,
              y: 30,
            },
            visible: {
              scale: 0.7,
              x: 140,
              y: 40,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ez-ms-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 1,
              x: 165,
              y: 65,
            },
            visible: {
              scale: 0.7,
              x: 120,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ez-ms-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 1,
              x: 165,
              y: 130,
            },
            visible: {
              scale: 0.7,
              x: 120,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ez-ms-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 1,
              x: 140,
              y: 165,
            },
            visible: {
              scale: 0.7,
              x: 100,
              y: 155,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ez-ms-4",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0,
              stroke: "#1ED761",
            },
            visible: {
              x: 15,
              y: -65,
              scale: 0.7,
            },
          },
          envoy: {
            hidden: {
              opacity: 0,
              x: 15,
              scale: 0,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: {
              opacity: 1,
              x: 3,
              scale: 0.7,
            },
          },
        },
        strokeColor: "#1ED761",
        id: "ez-ms-7",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "10%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0,
              stroke: "#1ED761",
            },
            visible: {
              x: -4,
              y: -4,
              scale: 0.7,
            },
          },
          envoy: {
            hidden: {
              opacity: 0,
              x: 15,
              scale: 0,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: {
              opacity: 1,
              x: 3,
              scale: 0.7,
            },
          },
        },
        strokeColor: "#1ED761",
        id: "ez-ms-5",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0,
              stroke: "#1ED761",
            },
            visible: {
              x: 15,
              y: -17,
              scale: 0.7,
            },
          },
          envoy: {
            hidden: {
              opacity: 0,
              x: 15,
              scale: 0,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: {
              opacity: 1,
              x: 3,
              scale: 0.7,
            },
          },
        },
        strokeColor: "#1ED761",
        id: "ez-ms-6",
      },
      
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "57%",
        yPosition: "29.5%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale:0.1,
            },
            
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ez-bn-3",
      }
    ],
    edges: [
      {
        // id: "ae-lb",
        sourceId: "ez-m-0",
        destinationId: "ez-lb",
        shouldAnimate: false,
        className: "flow-initial",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ez-lb",
        destinationId: "ez-m-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:"NA"
      },
      {
        // id: "ae-lb",
        sourceId: "ez-lb",
        destinationId: "ez-m-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ez-m-2",
        destinationId: "ez-ms-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "end",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ez-lb",
        destinationId: "ez-ms-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ez-lb",
        destinationId: "ez-ms-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ez-lb",
        destinationId: "ez-ms-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "ez-lb",
        destinationId: "ez-ms-4",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "ez-lb",
        destinationId: "ez-bn-2",
        shouldAnimate: true,
        className: "flow",
        animationDelay: 0.5,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ez-bn-2",
        destinationId: "ez-ms-5",
        shouldAnimate: true,
        className: "flow",
        animationDelay: 0.7,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ez-bn-2",
        destinationId: "ez-ms-6",
        shouldAnimate: true,
        className: "flow",
        animationDelay: 0.7,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ez-bn-2",
        destinationId: "ez-ms-7",
        shouldAnimate: true,
        className: "flow",
        animationDelay: 0.7,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },

      {
        // id: "ae-lb",
        sourceId: "ez-ms-2",
        destinationId: "ez-ms-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ez-ms-7",
        destinationId: "ez-bn-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ez-ms-5",
        destinationId: "ez-bn-3",
        shouldAnimate: true,
        className:"flow-reverse",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ez-ms-4",
        destinationId: "ez-bn-3",
        shouldAnimate: true,
        className:"flow-reverse",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.7
      },
    ],
  },
  {
    stepName: SystemStates.ADDED_ISTIO,
    nodes: [
      {
        type: NodeType.LOADBALANCER,
        xPosition: "10%",
        yPosition: "40%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            scale: 1.3,
            stroke: "#1ED761",
          },
          visible: {
            opacity: 1,
            scale: 1,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "ai-lb",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "-30%",
        yPosition: "38%",
        width: "1%",
        height: "1%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0,
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0,
          },
        },
        strokeColor: "#1ED761",
        id: "ai-bn-0",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "10%",
        yPosition: "12%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 1,
            x: 120,
            y: -115,
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 1.3,
            x: 120,
            y: -115,
          },
        },
        strokeColor: "#1ED761",
        id: "ai-m-1",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "45%",
        yPosition: "40%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.8,
            x: -90,
            y: -50,
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
          },
        },
        strokeColor: "#1ED761",
        id: "ai-m-2",
      },
      {
        type: NodeType.ISTIO,
        xPosition: "14%",
        yPosition: "-9.3%",
        width: "100%",
        height: "100%",
        fillColour: "#F0BE84",
        animationDelay: 4,
        animate: {
          hidden: {
            opacity: 0,
            stroke: "none",
            scale: 0,
          },
          visible: {
            opacity: 1,
            stroke: "#FFFFFF",
            scale: 0.8,
          },
        },
        strokeColor: "#1ED761",
        id: "ai-en-7",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.7,
              x: 140,
              y: 45,
              stroke: "#1ED761",
            },
            visible: { x: 140 },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ai-ms-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.7,
              x: 120,
              y: 68,
            },
            visible: { x: 120 },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ai-ms-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.7,
              x: 120,
              y: 136,
            },
            visible: { x: 120 },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ai-ms-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.7,
              x: 100,
              y: 161,
            },
            visible: { x: 100 },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ai-ms-4",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 3,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              x: 15,
              y: -65,
              scale: 0.7,
              stroke: "#1ED761",
            },
            visible: {
              x: 140,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ai-ms-5",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "10%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 3,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              x: -4,
              y: -4,
              scale: 0.7,
              stroke: "#1ED761",
            },
            visible: {
              x: 120,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              y: 8,
              x: 3,
              scale: 0.7,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ai-ms-6",
      },

      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 3,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              x: 15,
              y: -17,
              scale: 0.7,
              stroke: "#1ED761",
            },
            visible: {
              x: 140,
            },
          },
          envoy: {
            hidden: {
              scale: 0.7,
              opacity: 1,
              x: 3,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ai-ms-7",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "45.4%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ai-bn-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "70.4%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ai-bn-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "45%",
        yPosition: "22.4%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ai-bn-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "-20%",
        yPosition: "38%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
             
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
              
            },
            
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ai-bn-0",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "57%",
        yPosition: "32.4%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
       
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
              
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale:0.1,
             
            },
                     },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ai-bn-6",
      }
    ],
    edges: [
      {
        // id: "ae-lb",
        sourceId: "ai-bn-0",
        destinationId: "ai-lb",
        shouldAnimate: false,
        className: "flow-initial",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ai-lb",
        destinationId: "ai-m-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:"NA"
      },
      {
        // id: "ae-lb",
        sourceId: "ai-lb",
        destinationId: "ai-m-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ai-m-2",
        destinationId: "ai-ms-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "end",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ai-lb",
        destinationId: "ai-bn-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ai-bn-1",
        destinationId: "ai-ms-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ai-bn-1",
        destinationId: "ai-ms-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ai-lb",
        destinationId: "ai-bn-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "ai-bn-2",
        destinationId: "ai-ms-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "ai-bn-2",
        destinationId: "ai-ms-4",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "ai-lb",
        destinationId: "ai-bn-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ai-bn-3",
        destinationId: "ai-ms-5",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "ai-bn-3",
        destinationId: "ai-ms-6",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ai-bn-3",
        destinationId: "ai-ms-7",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ai-ms-2",
        destinationId: "ai-ms-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ai-ms-7",
        destinationId: "ai-ms-1",
        shouldAnimate: true,
        className: "flow",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "ai-ms-6",
        destinationId: "ai-bn-6",
        shouldAnimate: false,
        className:"flow-reverse",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ai-ms-4",
        destinationId: "ai-bn-6",
        shouldAnimate: false,
        className:"flow-reverse",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.7
      },
    ],
  },
  {
    stepName: SystemStates.ISTIO_ZOOM_OUT,
    nodes: [
      {
        type: NodeType.LOADBALANCER,
        xPosition: "10%",
        yPosition: "38%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            scale: 1,
            stroke: "#1ED761",
          },
          visible: {
            scale: 1,
            y: "80%",
            x: "4%",
          },
        },
        strokeColor: "#1ED761",
        id: "iz-lb",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "45%",
        yPosition: "40%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.8,
            x: -90,
            y: -50,
          },
          visible: {
            scale: 0.6,
            x: -150,
            y: 7,
          },
        },
        strokeColor: "#1ED761",
        id: "iz-mono",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "40%",
        yPosition: "24.9%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.8,
            x: -45,
            y: -150,
          },
          visible: {
            scale: 0.6,
            x: -30,
            y: -150,
          },
        },
        strokeColor: "#1ED761",
        id: "iz-mono-2",
      },
      {
        type: NodeType.ISTIO,
        xPosition: "0%",
        yPosition: "6%",
        width: "100%",
        height: "100%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          hidden: {
            opacity: 0,
            stroke: "#FFFFFF",
            scale: 0.6,
          },
          visible: {
            opacity: 1,
          },
        },
        strokeColor: "#1ED761",
        id: "iz-is-1",
      },
      {
        type: NodeType.ISTIO,
        xPosition: "20%",
        yPosition: "-45%",
        width: "100%",
        height: "100%",
        fillColour: "#F0BE84",
        animationDelay: 0.6,
        animate: {
          hidden: {
            opacity: 0,
            stroke: "#FFFFFF",
            scale: 0.6,
          },
          visible: {
            opacity: 1,
          },
        },
        strokeColor: "#1ED761",
        id: "iz-is-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.7,
              x: 140,
              y: 40,
              stroke: "#1ED761",
            },
            visible: {
              scale: 0.5,
              x: 0,
              y: 104,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "iz-ms-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.7,
              x: 120,
              y: 65,
            },
            visible: {
              scale: 0.5,
              x: -13,
              y: 123,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "iz-ms-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.7,
              x: 120,
              y: 130,
            },
            visible: {
              scale: 0.5,
              x: -13,
              y: 160,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "iz-ms-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.7,
              x: 100,
              y: 155,
            },
            visible: {
              scale: 0.5,
              x: -25,
              y: 177,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "iz-ms-4",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "10%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              x: 100,
              y: -4,
              scale: 0.7,
              stroke: "#1ED761",
            },
            visible: {
              scale: 0.5,
              x: -2,
              y: 62,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              y: 8,
              x: 3,
              scale: 0.7,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "iz-ms-5",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              x: 120,
              y: -65,
              scale: 0.7,
              stroke: "#1ED761",
            },
            visible: {
              scale: 0.5,
              x: -15,
              y: 40,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "iz-ms-7",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              x: 120,
              y: -17,
              scale: 0.7,
              stroke: "#1ED761",
            },
            visible: {
              scale: 0.5,
              x: -2,
              y: 55,
            },
          },
          envoy: {
            hidden: {
              scale: 0.7,
              opacity: 1,
              x: 3,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "iz-ms-6",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "60%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 1,
              x: 40,
              y: -120,
              stroke: "#1ED761",
            },
            visible: {
              scale: 0.5,
              x: "0%",
              y: -80,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "iz-ms-8",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "59%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 1,
              x: 40,
              y: -100,
            },
            visible: {
              scale: 0.5,
              x: "0%",
              y: -60,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "iz-ms-9",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "59%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 1,
              x: 40,
              y: -100,
            },
            visible: {
              scale: 0.5,
              x: "0%",
              y: -10,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "iz-ms-10",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "-30%",
        yPosition: "38%",
        width: "1%",
        height: "1%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0,
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.1,
          },
        },
        strokeColor: "#1ED761",
        id: "iz-bn-0",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "30%",
        yPosition: "60%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
       
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
             
            },
            
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "iz-bn-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "30%",
        yPosition: "74.5%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,

        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "iz-bn-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "30%",
        yPosition: "42%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,

        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
           
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "iz-bn-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "25%",
        width: "1%",
        height: "1%",

        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
              x: -30,
              y: -100,
            },
            visible: {
              scale: 0.1,
              x: -30,
              y: -30,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "iz-bn-4",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "50%",
        yPosition: "12%",
        width: "1%",
        height: "1%",

        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
              x: 80,
              y: -100,
            },
            visible: {
              scale: 0.1,
              x: 80,
              y: -60,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "iz-bn-5",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "43%",
        yPosition: "48%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale:0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "iz-bn-6",
      }
    ],
    edges: [
      {
        // id: "ae-lb",
        sourceId: "iz-bn-0",
        destinationId: "iz-lb",
        shouldAnimate: false,
        className: "flow-initial",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-lb",
        destinationId: "iz-mono",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-bn-4",
        destinationId: "iz-mono-2",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "NA",
        cluster: "failover-sec",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-mono",
        destinationId: "iz-ms-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "end",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-lb",
        destinationId: "iz-bn-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-bn-1",
        destinationId: "iz-ms-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-bn-1",
        destinationId: "iz-ms-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-lb",
        destinationId: "iz-bn-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-bn-2",
        destinationId: "iz-ms-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-bn-2",
        destinationId: "iz-ms-4",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },

      {
        // id: "ae-lb",
        sourceId: "iz-lb",
        destinationId: "iz-bn-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-bn-3",
        destinationId: "iz-ms-5",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-bn-3",
        destinationId: "iz-ms-6",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-bn-3",
        destinationId: "iz-ms-7",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },

      {
        // id: "ae-lb",
        sourceId: "iz-lb",
        destinationId: "iz-bn-4",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0.5,
        curve: "start",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "iz-bn-4",
        destinationId: "iz-bn-5",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0.5,
        curve: "start",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "iz-bn-5",
        destinationId: "iz-ms-8",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "iz-bn-5",
        destinationId: "iz-ms-9",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "iz-bn-4",
        destinationId: "iz-ms-10",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "iz-ms-2",
        destinationId: "iz-ms-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-ms-6",
        destinationId: "iz-ms-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "NA",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-ms-9",
        destinationId: "iz-ms-10",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "iz-ms-7",
        destinationId: "iz-bn-6",
        shouldAnimate: false,
        className:"flow-reverse",
        animationDelay: 0,
        curve: "NA",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "iz-ms-4",
        destinationId: "iz-bn-6",
        shouldAnimate: false,
        className:"flow-reverse",
        animationDelay: 0,
        curve: "NA",
        cluster: "failover",
        animationFrequency:0.3
      },
    ],
  },

  {
    stepName: SystemStates.FAILOVER,
    nodes: [
      {
        type: NodeType.LOADBALANCER,
        xPosition: "10%",
        yPosition: "40%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            scale: 0.7,
            y: "80%",
            x: "4%",
            stroke: "#1ED761",
          },
          visible: { scale: 0.8 },
        },
        strokeColor: "#1ED761",
        id: "fl-lb",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "-30%",
        yPosition: "38%",
        width: "1%",
        height: "1%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0,
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.1,
          },
        },
        strokeColor: "#1ED761",
        id: "fl-bn-0",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "45%",
        yPosition: "40%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.6,
            x: -150,
            y: 5,
          },
          visible: { opacity: 1, stroke: "#1ED761", scale: 0.6, x: -150, y: 5 },
        },
        strokeColor: "#1ED761",
        id: "fl-mono",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "40%",
        yPosition: "24.9%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.8,
            x: -45,
            y: -150,
          },
          visible: {
            scale: 0.6,
            x: -30,
            y: -150,
          },
        },
        strokeColor: "#1ED761",
        id: "fl-mono-2",
      },
      {
        type: NodeType.ISTIO,
        xPosition: "0%",
        yPosition: "6%",
        width: "100%",
        height: "100%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#FFFFFF",
            scale: 0.6,
          },
          visible: {},
        },
        strokeColor: "#1ED761",
        id: "fl-is-1",
      },
      {
        type: NodeType.ISTIO,
        xPosition: "19%",
        yPosition: "-45%",
        width: "100%",
        height: "100%",
        fillColour: "#F0BE84",
        animationDelay: 0.6,
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#FFFFFF",
            scale: 0.6,
          },
          visible: {},
        },
        strokeColor: "#1ED761",
        id: "fl-is-2",
      },

      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.5,
              x: "-2%",
              y: "190%",
              stroke: "#1ED761",
            },
            visible: {
              opacity: 1,
              scale: 0.5,
              x: "-2%",
              y: "190%",
              stroke: "#1ED761",
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
          },
        },
        strokeColor: "#1ED761",
        id: "fl-ms-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.5,
              x: "-20%",
              y: "222%",
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.5,
              x: "-20%",
              y: "222%",
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "fl-ms-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.5,
              x: -13,
              y: 158,
            },
            visible: { x: -13 },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 8, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "fl-ms-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.5,
              x: -25,
              y: 177,
            },
            visible: { x: -25 },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "fl-ms-4",
      },

      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "10%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.5,
              x: -2,
              y: 62,
              stroke: "#1ED761",
            },
            visible: { x: -2 },
          },
          envoy: {
            hidden: {
              opacity: 1,
              y: 8,
              x: 3,
              scale: 0.7,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "fl-ms-5",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "39.5%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.5,
              x: -15,
              y: 40,
              stroke: "#1ED761",
            },
            visible: { x: -15 },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "fl-ms-7",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.5,
              x: -2,
              y: 55,
              stroke: "#1ED761",
            },
            visible: { x: -2 },
          },
          envoy: {
            hidden: {
              scale: 0.7,
              opacity: 1,
              x: 3,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "fl-ms-6",
      },

      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "60%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.7,
              x: "0%",
              y: -80,
              stroke: "#1ED761",
            },
            visible: { x: "0%" },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "fl-ms-8",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "59%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.7,
              x: "0%",
              y: -60,
            },
            visible: { x: "0%" },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "fl-ms-9",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "59%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.7,
              x: "0%",
              y: -10,
            },
            visible: { x: "0%" },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "fl-ms-10",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "30%",
        yPosition: "60.4%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "fl-bn-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "30%",
        yPosition: "74.5%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "fl-bn-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "30%",
        yPosition: "42%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "fl-bn-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "24.4%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "fl-bn-4",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "55%",
        yPosition: "10.8%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "fl-bn-5",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "43%",
        yPosition: "47.8%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale:0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "fl-bn-6",
      }
    ],
    edges: [
      {
        // id: "ae-lb",
        sourceId: "fl-bn-0",
        destinationId: "fl-lb",
        shouldAnimate: false,
        className: "flow-initial",
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-lb",
        destinationId: "fl-mono",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-bn-4",
        destinationId: "fl-mono-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "NA",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-lb",
        destinationId: "fl-bn-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-bn-1",
        destinationId: "fl-ms-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-bn-1",
        destinationId: "fl-ms-2",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-lb",
        destinationId: "fl-bn-2",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "fl-bn-2",
        destinationId: "fl-ms-3",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "fl-bn-2",
        destinationId: "fl-ms-4",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },

      {
        // id: "ae-lb",
        sourceId: "fl-lb",
        destinationId: "fl-bn-3",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-bn-3",
        destinationId: "fl-ms-5",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "fl-bn-3",
        destinationId: "fl-ms-6",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-bn-3",
        destinationId: "fl-ms-7",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },

      {
        // id: "ae-lb",
        sourceId: "fl-lb",
        destinationId: "fl-bn-4",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-bn-4",
        destinationId: "fl-bn-5",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-bn-5",
        destinationId: "fl-ms-8",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-bn-5",
        destinationId: "fl-ms-9",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-bn-4",
        destinationId: "fl-ms-10",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-ms-2",
        destinationId: "fl-ms-3",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-ms-6",
        destinationId: "fl-ms-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-ms-9",
        destinationId: "fl-ms-10",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "NA",
        cluster: "failover",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-mono",
        destinationId: "fl-ms-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "end",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-ms-7",
        destinationId: "fl-bn-6",
        shouldAnimate: false,
        className:"dotted-line",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "fl-ms-4",
        destinationId: "fl-bn-6",
        shouldAnimate: false,
        className:"dotted-line",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.7
      },
    ],
  },
  {
    stepName: SystemStates.UPDATE,
    nodes: [
      {
        type: NodeType.LOADBALANCER,
        xPosition: "10%",
        yPosition: "40%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            scale: 0.7,
            y: "80%",
            x: "4%",
            stroke: "#1ED761",
          },
          visible: { scale: 0.8 },
        },
        strokeColor: "#1ED761",
        id: "up-lb",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "-30%",
        yPosition: "38%",
        width: "1%",
        height: "1%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0,
          },
          visible: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.1,
          },
        },
        strokeColor: "#1ED761",
        id: "up-bn-0",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "45%",
        yPosition: "40%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.6,
            x: -150,
            y: 5,
          },
          visible: { opacity: 1, stroke: "#1ED761", scale: 0.6, x: -150, y: 5 },
        },
        strokeColor: "#1ED761",
        id: "up-mono",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "40%",
        yPosition: "24.9%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.8,
            x: -45,
            y: -150,
          },
          visible: {
            scale: 0.6,
            x: -30,
            y: -150,
          },
        },
        strokeColor: "#1ED761",
        id: "up-mono-2",
      },
      {
        type: NodeType.ISTIO,
        xPosition: "0%",
        yPosition: "6%",
        width: "100%",
        height: "100%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#FFFFFF",
            scale: 0.6,
          },
          visible: {},
        },
        strokeColor: "#1ED761",
        id: "up-is-1",
      },
      {
        type: NodeType.ISTIO,
        xPosition: "19%",
        yPosition: "-45%",
        width: "100%",
        height: "100%",
        fillColour: "#F0BE84",
        animationDelay: 0.6,
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#FFFFFF",
            scale: 0.6,
          },
          visible: {},
        },
        strokeColor: "#1ED761",
        id: "up-is-2",
      },

      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.5,
              x: "-2%",
              y: "190%",
              stroke: "#1ED761",
            },
            visible: {
              opacity: 1,
              scale: 0.5,
              x: "-2%",
              y: "190%",
              stroke: "#1ED761",
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
          },
        },
        strokeColor: "#1ED761",
        id: "up-ms-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.5,
              x: "-20%",
              y: "222%",
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.5,
              x: "-20%",
              y: "222%",
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "up-ms-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.5,
              x: -13,
              y: 158,
            },
            visible: { x: -13 },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 8, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "up-ms-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.5,
              x: -25,
              y: 177,
            },
            visible: { x: -25 },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "up-ms-4",
      },

      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "10%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.5,
              x: -2,
              y: 62,
              stroke: "#1ED761",
            },
            visible: { x: -2 },
          },
          envoy: {
            hidden: {
              opacity: 1,
              y: 8,
              x: 3,
              scale: 0.7,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "up-ms-5",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "39.5%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.5,
              x: -15,
              y: 40,
              stroke: "#1ED761",
            },
            visible: { x: -15 },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "up-ms-7",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.5,
              x: -2,
              y: 55,
              stroke: "#1ED761",
            },
            visible: { x: -2 },
          },
          envoy: {
            hidden: {
              scale: 0.7,
              opacity: 1,
              x: 3,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "up-ms-6",
      },

      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "60%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.7,
              x: "0%",
              y: -80,
              stroke: "#1ED761",
            },
            visible: { x: "0%" },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "up-ms-8",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "59%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.7,
              x: "0%",
              y: -60,
            },
            visible: { x: "0%" },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "up-ms-9",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "59%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.7,
              x:"0%",
              y: -10,
            },
            visible: { x: "0%" },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "up-ms-10",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "30%",
        yPosition: "60.4%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "up-bn-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "30%",
        yPosition: "74.5%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "up-bn-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "30%",
        yPosition: "42%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "up-bn-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "24.4%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "up-bn-4",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "55%",
        yPosition: "10.8%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "up-bn-5",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "43%",
        yPosition: "47.8%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale:0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "up-bn-6",
      }
    ],
    edges: [
      {
        // id: "ae-lb",
        sourceId: "up-bn-0",
        destinationId: "up-lb",
        shouldAnimate: false,
        className: "flow-initial",
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "up-lb",
        destinationId: "up-mono",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "up-bn-4",
        destinationId: "up-mono-2",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "up-lb",
        destinationId: "up-bn-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "up-bn-1",
        destinationId: "up-ms-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "up-bn-1",
        destinationId: "up-ms-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "up-lb",
        destinationId: "up-bn-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "up-bn-2",
        destinationId: "up-ms-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "up-bn-2",
        destinationId: "up-ms-4",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },

      {
        // id: "ae-lb",
        sourceId: "up-lb",
        destinationId: "up-bn-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "up-bn-3",
        destinationId: "up-ms-5",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "up-bn-3",
        destinationId: "up-ms-6",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "up-bn-3",
        destinationId: "up-ms-7",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },

      {
        // id: "ae-lb",
        sourceId: "up-lb",
        destinationId: "up-bn-4",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "up-bn-4",
        destinationId: "up-bn-5",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "up-bn-5",
        destinationId: "up-ms-8",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "up-bn-5",
        destinationId: "up-ms-9",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "up-bn-4",
        destinationId: "up-ms-10",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "up-ms-2",
        destinationId: "up-ms-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "up-ms-6",
        destinationId: "up-ms-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "up-ms-9",
        destinationId: "up-ms-10",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "up-mono",
        destinationId: "up-ms-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "end",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "up-ms-7",
        destinationId: "up-bn-6",
        shouldAnimate: false,
        className:"flow-reverse",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "up-ms-4",
        destinationId: "up-bn-6",
        shouldAnimate: false,
        className:"flow-reverse",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.7
      },
    ],
  },
  {
    stepName: SystemStates.ADD_TSB,
    nodes: [
      {
        type: NodeType.LOADBALANCER,
        xPosition: "10%",
        yPosition: "38%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            scale: 0.7,
            y: "80%",
            x: "4%",
            stroke: "#1ED761",
          },
          visible: {
            scale: 0.5,
          },
        },
        strokeColor: "#1ED761",
        id: "ts-lb",
      },
      {
        type: NodeType.TSB,
        xPosition: "16%",
        yPosition: "5%",
        width: "80%",
        height: "80%",
        animationDelay: 4,
        animate: {
          rectangle: {
            hidden: {
              opacity: "0",
              stroke: "#FFFFFF",
            },
            visible: {
              opacity: "0.095",
            },
          },
          tsb: {
            hidden: {},
            visible: {},
          },
        },
        strokeColor: "#1ED761",
        id: "ts-ts-2",
      },
      {
        type: NodeType.ISTIO,
        xPosition: "-2%",
        yPosition: "6%",
        width: "100%",
        height: "100%",
        fillColour: "#F0BE84",
        animationDelay: 0.5,
        animate: {
          hidden: {
            opacity: 0,
            stroke: "#FFFFFF",
            scale: 0.4,
          },
          visible: {
            opacity: 1,
          },
        },
        strokeColor: "#1ED761",
        id: "ts-is-1",
      },
      {
        type: NodeType.ISTIO,
        xPosition: "-2%",
        yPosition: "6%",
        width: "100%",
        height: "100%",
        fillColour: "#F0BE84",
        animationDelay: 0.5,
        animate: {
          hidden: {
            opacity: 0,
            stroke: "#FFFFFF",
            scale: 0.4,
            x: "67%",
            y: "-27%",
          },
          visible: {
            opacity: 1,
            x: "67%",
            y: "-27%",
          },
        },
        strokeColor: "#1ED761",
        id: "ts-is-2",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "45%",
        yPosition: "43%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.6,
            x: -150,
            y: 5,
          },
          visible: {
            scale: 0.5,
            y: 10,
          },
        },
        strokeColor: "#1ED761",
        id: "ts-mono-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.4,
              x: 0,
              y: 106,
              stroke: "#1ED761",
            },
            visible: { x: 0 },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x:5,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ts-ms-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.4,
              x: -13,
              y: 123,
            },
            visible: { x: -13 },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ts-ms-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.5,
              x: -13,
              y: 155,
            },
            visible: {
              scale: 0.4,
              y: 151,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ts-ms-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.5,
              x: -25,
              y: 170,
            },
            visible: {
              scale: 0.4,
              y: 167,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ts-ms-4",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "10%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.5,
              x: -2,
              y: 62,
              stroke: "#1ED761",
            },
            visible: {
              scale: 0.4,
              y: 82,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              y: 8,
              x: 3,
              scale: 0.7,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ts-ms-5",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.5,
              x: -15,
              y: 40,
              stroke: "#1ED761",
            },
            visible: {
              scale: 0.4,
              y: 60,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ts-ms-7",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.5,
              x: -2,
              y: 55,
              stroke: "#1ED761",
            },
            visible: {
              scale: 0.4,
              y: 75,
            },
          },
          envoy: {
            hidden: {
              scale: 0.7,
              opacity: 1,
              x: 3,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ts-ms-6",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "45%",
        yPosition: "40%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.6,
            x: 0,
            y: -200,
          },
          visible: {
            scale: 0.5,
            y: -95,
          },
        },
        strokeColor: "#1ED761",
        id: "ts-mono-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 4,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.7,
              x: 170,
              y: -120,
              stroke: "#1ED761",
            },
            visible: {
              scale: 0.4,
              x: 160,
              y: -45,
            },
          },
          envoy: {
            hidden: {
              scale: 0.7,
              opacity: 1,
              x: 3,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ts-ms-11",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.7,
              x: 170,
              y: -80,
              stroke: "#1ED761",
            },
            visible: {
              scale: 0.4,
              x: 160,
              y: -15,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ts-ms-8",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.7,
              x: 150,
              y: -60,
            },
            visible: {
              scale: 0.4,
              y: 0,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ts-ms-9",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.7,
              x: 150,
              y: -10,
            },
            visible: {
              scale: 0.4,
              y: 30,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ts-ms-10",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "-10%",
        yPosition: "42%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ts-bn-0",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "36.5%",
        yPosition: "60%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ts-bn-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "36.5%",
        yPosition: "71.8%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ts-bn-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "36.5%",
        yPosition: "47.3%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ts-bn-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "35%",
        width: "1%",
        height: "1%",

        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
              x: -40,
              y: -100,
            },
            visible: {
              scale: 0.1,
              x: "0%",
              y: "0%",
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ts-bn-4",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "57%",
        yPosition: "27%",
        width: "1%",
        height: "1%",

        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
              
            },
            visible: {
              scale: 0.1,
              
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "ts-bn-5",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "42.6%",
        yPosition: "52%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale:0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ts-bn-6",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "55%",
        yPosition: "20%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
              x: 80,
              y: -140,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale:0.1,
              x: 80,
              y: -20,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ts-bn-7",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "58%",
        yPosition: "35%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 2,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
             
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale:0.1,
              
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ts-bn-8",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "57%",
        yPosition: "15%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 2,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
              
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale:0.1,
              
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "ts-bn-9",
      }

    ],
    edges: [
      {
        // id: "ae-lb",
        sourceId: "ts-bn-0",
        destinationId: "ts-lb",
        shouldAnimate: false,
        className: "flow-initial",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ts-lb",
        destinationId: "ts-mono-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ts-lb",
        destinationId: "ts-mono-2",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "ts-lb",
        destinationId: "ts-bn-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-1",
        destinationId: "ts-ms-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-1",
        destinationId: "ts-ms-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ts-lb",
        destinationId: "ts-bn-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-2",
        destinationId: "ts-ms-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-2",
        destinationId: "ts-ms-4",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },

      {
        // id: "ae-lb",
        sourceId: "ts-lb",
        destinationId: "ts-bn-3",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-3",
        destinationId: "ts-ms-5",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-3",
        destinationId: "ts-ms-6",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-3",
        destinationId: "ts-ms-7",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },

      {
        // id: "ae-lb",
        sourceId: "ts-lb",
        destinationId: "ts-bn-4",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-4",
        destinationId: "ts-bn-5",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-5",
        destinationId: "ts-ms-8",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-5",
        destinationId: "ts-ms-9",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-4",
        destinationId: "ts-ms-10",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-4",
        destinationId: "ts-ms-11",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "ts-ms-2",
        destinationId: "ts-ms-3",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "ts-ms-6",
        destinationId: "ts-ms-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "ts-ms-9",
        destinationId: "ts-ms-10",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "ts-ms-11",
        destinationId: "ts-ms-8",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "ts-mono-1",
        destinationId: "ts-ms-1",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "end",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ts-mono-2",
        destinationId: "ts-ms-8",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "end",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "ts-ms-7",
        destinationId: "ts-bn-6",
        shouldAnimate: true,
        className:"flow-reverse",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "ts-ms-4",
        destinationId: "ts-bn-6",
        shouldAnimate: true,
        className:"flow-reverse",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-7",
        destinationId: "ts-bn-8",
        shouldAnimate: true,
        className: "dotted-line",
        animationDelay: 1,
        curve: "NA",
        cluster: "failover-sec",
        animationFrequency:2
      },
      {
        // id: "ae-lb",
        sourceId: "ts-bn-9",
        destinationId: "ts-bn-7",
        shouldAnimate: true,
        className: "dotted-line",
        animationDelay: 1,
        curve: "NA",
        cluster: "failover-sec",
        animationFrequency:2
      },
    ],
  },
  
  {
    stepName: SystemStates.TSB_FAILOVER,
    nodes: [
      {
        type: NodeType.LOADBALANCER,
        xPosition: "10%",
        yPosition: "38%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            y: "80%",
            x: "4%",
            stroke: "#1ED761",
            scale: 0.7,
          },
          visible: {
            x: "4%",
          },
        },
        strokeColor: "#1ED761",
        id: "tf-lb",
      },
      {
        type: NodeType.TSB,
        xPosition: "20%",
        yPosition: "5%",
        width: "70%",
        height: "75%",
        animationDelay: 0,
        setOpacity: false,
        animate: {
          rectangle: {
            hidden: {
              opacity: "0.095",
              stroke: "#FFFFFF",
            },
            visible: {},
          },
          tsb: {
            hidden: { x: "0%", y: "0%", scale: 1 },
            visible: { x: "0%", y: "0%", scale: 1 },
          },
        },
        strokeColor: "#1ED761",
        id: "tsf-2",
      },
      {
        type: NodeType.ISTIO,
        xPosition: "-2%",
        yPosition: "6%",
        width: "100%",
        height: "100%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        setOpacity: false,
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#FFFFFF",
            scale: 0.4,
            x: "-2%",
          },
          visible: {
            x: "-2%",
          },
        },
        strokeColor: "#1ED761",
        id: "tsf-is-1",
      },
      {
        type: NodeType.ISTIO,
        xPosition: "-2%",
        yPosition: "6%",
        width: "100%",
        height: "100%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        setOpacity: false,
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#FFFFFF",
            scale: 0.4,
            x: "67%",
            y: "-27%",
          },
          visible: {
            x: "67%",
            y: "-27%",
          },
        },
        strokeColor: "#1ED761",
        id: "tsf-is-2",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "45%",
        yPosition: "43%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.5,
            y: 10,
            x: -150,
          },
          visible: {
            x: -150,
          },
        },
        strokeColor: "#1ED761",
        id: "tf-mono-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.4,
              y: 108,
              stroke: "#1ED761",
              x: 0,
            },
            visible: {
              x: 0,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "tf-ms-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.4,
              y: 123,
              x: -13,
            },
            visible: {
              x: -13,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "tf-ms-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.4,
              y: 151,
              x: -13,
            },
            visible: {
              x: -13,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "tf-ms-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.4,
              y: 167,
              x: -25,
            },
            visible: {
              x: -25,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "tf-ms-4",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "10%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.4,
              y: 82,
              x: -2,
              stroke: "#1ED761",
            },
            visible: {
              x: -2,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              y: 8,
              x: 3,
              scale: 0.7,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "tf-ms-5",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.4,
              y: 60,
              stroke: "#1ED761",
              x: -15,
            },
            visible: {
              x: -15,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "tf-ms-7",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.4,
              y: 75,
              stroke: "#1ED761",
              x: -2,
            },
            visible: {
              x: -2,
            },
          },
          envoy: {
            hidden: {
              scale: 0.7,
              opacity: 1,
              x: 3,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "tf-ms-6",
      },
      {
        type: NodeType.MONOLITH,
        xPosition: "45%",
        yPosition: "40%",
        width: "15%",
        height: "15%",
        animate: {
          hidden: {
            opacity: 1,
            stroke: "#1ED761",
            scale: 0.5,
            y: -95,
            x: -35,
          },
          visible: {
            x: -35,
          },
        },
        strokeColor: "#1ED761",
        id: "tf-mono-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#B3ECB7",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.4,
              y: -45,
              stroke: "#1ED761",
              x: 160,
            },
            visible: {
              x: 160,
            },
          },
          envoy: {
            hidden: {
              scale: 0.7,
              opacity: 1,
              x: 3,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "tf-ms-11",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              opacity: 1,
              scale: 0.4,
              y: -15,
              stroke: "#1ED761",
              x: 160,
            },
            visible: {
              x: 160,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "tf-ms-8",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.4,
              y: 0,
              x:150,
            },
            visible: {
              x: 150,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "tf-ms-9",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "20%",
        width: "15%",
        height: "15%",
        fillColour: "#F0BE84",
        animationDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.4,
              y: 30,
              x: 150,
            },
            visible: {
              x: 150,
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",
        id: "tf-ms-10",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "-10%",
        yPosition: "42%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "tf-bn-0",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "36.5%",
        yPosition: "60%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "tf-bn-1",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "36.5%",
        yPosition: "71.8%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "tf-bn-2",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "36.5%",
        yPosition: "47.2%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "tf-bn-3",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "40%",
        yPosition: "35%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
            },
            afterUpdate: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "tf-bn-4",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "55%",
        yPosition: "27%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
              x: 30,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0.1,
              x: 30,
            },
            afterUpdate: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 11,
              stroke: "#FFFFFF",
            },
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "tf-bn-5",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "42.6%",
        yPosition: "52%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale:0.1,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "tf-bn-6",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "54%",
        yPosition: "20%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
              
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale:0.1,
              x: 80,
              y: -20,
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "tf-bn-7",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "58%",
        yPosition: "35%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
             
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale:0.1,
              
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "tf-bn-8",
      },
      {
        type: NodeType.MICROSERVICE_ENVOY,
        xPosition: "57%",
        yPosition: "15%",
        width: "1%",
        height: "1%",
        fillColour: "#2cb39e",
        animationDelay: 0,
        checkForHealth: true,
        nodeUpdateDelay: 0,
        animate: {
          microservice: {
            hidden: {
              stroke: "#1ED761",
              opacity: 1,
              scale: 0,
              
            },
            visible: {
              stroke: "#1ED761",
              opacity: 1,
              scale:0.1,
              
            },
            afterUpdate: {},
          },
          envoy: {
            hidden: {
              opacity: 1,
              x: 3,
              scale: 0.7,
              y: 8,
              stroke: "#FFFFFF",
            },
            visible: { opacity: 1, x: 3, scale: 0.7, y: 11, stroke: "#FFFFFF" },
          },
        },
        strokeColor: "#1ED761",

        id: "tf-bn-9",
      }
    ],
    edges: [
      {
        // id: "ae-lb",
        sourceId: "tf-bn-0",
        destinationId: "tf-lb",
        shouldAnimate: false,
        className: "flow-initial",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "tf-lb",
        destinationId: "tf-mono-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency: 0.7
      },
      {
        // id: "ae-lb",
        sourceId: "tf-lb",
        destinationId: "tf-mono-2",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "tf-lb",
        destinationId: "tf-bn-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-1",
        destinationId: "tf-ms-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-1",
        destinationId: "tf-ms-2",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "tf-lb",
        destinationId: "tf-bn-2",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-2",
        destinationId: "tf-ms-3",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency: 0.7
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-2",
        destinationId: "tf-ms-4",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },

      {
        // id: "ae-lb",
        sourceId: "tf-lb",
        destinationId: "tf-bn-3",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-3",
        destinationId: "tf-ms-5",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-3",
        destinationId: "tf-ms-6",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency: 0.7
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-3",
        destinationId: "tf-ms-7",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency: 0.7
      },

      {
        // id: "ae-lb",
        sourceId: "tf-lb",
        destinationId: "tf-bn-4",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency: 0.3
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-4",
        destinationId: "tf-bn-5",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency: 0.3
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-5",
        destinationId: "tf-ms-8",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency: 0.3
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-5",
        destinationId: "tf-ms-9",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency: 0.3
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-4",
        destinationId: "tf-ms-10",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency: 0.3
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-4",
        destinationId: "tf-ms-11",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "start",
        cluster: "NA",
        animationFrequency: 0.3
      },
      {
        // id: "ae-lb",
        sourceId: "tf-ms-2",
        destinationId: "tf-ms-3",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency: 0.7
      },
      {
        // id: "ae-lb",
        sourceId: "tf-ms-6",
        destinationId: "tf-ms-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency: 0.7
      },
      {
        // id: "ae-lb",
        sourceId: "tf-ms-9",
        destinationId: "tf-ms-10",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency: 0.3
      },
      {
        // id: "ae-lb",
        sourceId: "tf-ms-11",
        destinationId: "tf-ms-8",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0.5,
        curve: "NA",
        cluster: "NA",
        animationFrequency: 0.3
      },
      {
        // id: "ae-lb",
        sourceId: "tf-mono-1",
        destinationId: "tf-ms-1",
        shouldAnimate: false,
        className: "dotted-line",
        animationDelay: 0.5,
        curve: "end",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "tf-mono-2",
        destinationId: "tf-ms-8",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0.5,
        curve: "end",
        cluster: "NA",
        animationFrequency: 0.3
      },
      {
        // id: "ae-lb",
        sourceId: "tf-ms-7",
        destinationId: "tf-bn-6",
        shouldAnimate: false,
        className:"dotted-line",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "tf-ms-4",
        destinationId: "tf-bn-6",
        shouldAnimate: false,
        className:"dotted-line",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.7
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-7",
        destinationId: "tf-bn-8",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
      {
        // id: "ae-lb",
        sourceId: "tf-bn-9",
        destinationId: "tf-bn-7",
        shouldAnimate: false,
        className: "flow",
        animationDelay: 0,
        curve: "NA",
        cluster: "NA",
        animationFrequency:0.3
      },
    ],
  },
];
