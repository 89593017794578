import React, { ReactElement, useRef, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "redux-setup/store";
import "routes/routes.css";

import App from "App";
import MenuScreenContainer from "components/menu-container";
import AudiPlayer from "components/audio-player";
import Fullscreen from "react-full-screen";
import LandscapePopup from "components/landscape-popup";
import InternetIssue from "components/internet-issue";
import ReactGA from "react-ga";
import { GA_ID, SHARE_URL } from "configs/app-config";

ReactGA.initialize(GA_ID);

ReactGA.pageview(SHARE_URL);

export default function Routes(): ReactElement {
  return (
    <Provider store={store}>
      <AudiPlayer />
      <LandscapePopup />
      <Router>
        <div className="route-body">
          <Switch>
            <Route exact path="/">
              <MenuScreenContainer />
            </Route>

            <Route exact path="/menu-screen">
              <MenuScreenContainer />
            </Route>

            <Route exact path="/main-graph">
              <App />
            </Route>
          </Switch>
        </div>
      </Router>
      <InternetIssue />
    </Provider>
  );
}
