import React, { ReactElement } from "react";
import "components/postmortem-email/postmortem-email.css";
import { motion } from "framer-motion";
import { showPostMortemEmail } from "slices/system";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { RootState } from "redux-setup/root-reducer";

export default function PostMortemEmail(): ReactElement {
  let dispatch = useDispatch();

  let showEmail = useSelector((state: RootState) => {
    return state.system.popupDetails.showPostMortemEmail;
  }, shallowEqual);

  function handleCloseEmail() {
    dispatch(showPostMortemEmail(false));
  }

  return showEmail ? (
    <div>
      <motion.div
        className="postmortem-email-popover-details"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="postmortem-email-logo">TP Inc.</div>
        <div className="postmortem-subject-container">
          <div className="postmortem-details-container">
            <div className="postmortem-title"> From: </div>
            <div> T </div>
          </div>
          <div className="postmortem-details-container">
            <div className="postmortem-title"> Subject: </div>
            <div>Hard down outage post-mortem report </div>
          </div>
        </div>
        <div className="postmortem-wc-meg-conatiner">
          <div className="postmortem-welcome-message">Outage Duration: 8 hours</div>
          <div className="postmortem-welcome-message">
            Root cause: No process in place to manage configuration changes across clusters, 
            which allowed configurations to become out of date.
          </div>
          <div className="postmortem-welcome-message">
            Next Steps: Review possible implementations of a management plane to exercise 
            greater control over our infrastructure.
          </div>
        </div>
        <motion.div
          className="postmortem-begin-action-button noSelect"
          onClick={evt => handleCloseEmail()}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.1, duration: 1 }}
        >
          <div className="postmortem-begin-button-title">OK</div>
        </motion.div>
      </motion.div>
    </div>
  ) : (
    <div></div>
  );
}
