import React, { ReactElement, useEffect } from "react";
import "components/permissions/permissions.css";
import { motion } from "framer-motion";
import { setShowPermissions } from "slices/system";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { v4 as uuidv4 } from "uuid";

const permissions = [
  {
    title: "Platform",
    access: "Permission Granted",
    icon: "/assets/images/granted.svg",
    status: true
  },
  {
    title: "Security",
    access: "Permission Granted",
    icon: "/assets/images/granted.svg",
    status: true
  },
  {
    title: "Payroll",
    access: "Awaiting Permission",
    icon: "/assets/images/denied.svg",
    status: false
  },
  {
    title: "Product Testing",
    access: "Awaiting Permission",
    icon: "/assets/images/denied.svg",
    status: false
  }
];

export default function Permissions(): ReactElement {
  let dispatch = useDispatch();

  let { showPermissions } = useSelector((state: RootState) => {
    return state.system.popupDetails;
  });

  const variants = {
    open: {
      x: "-50%",
      y: "-50%",
      top: "50%",
      left: "50%",
      scaleX: 1,
      scaleY: 1,
      opacity: 1
    },
    closed: { opacity: 0, scale: 0 }
  };

  useEffect(() => {
    if (showPermissions === true) {
      setTimeout(() => {
        dispatch(setShowPermissions(false));
      }, 5000);
    }
  }, [showPermissions]);

  return showPermissions ? (
    <div>
      <motion.div
        className="permissions-screen"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      />
      <motion.div
        className="permission-detail"
        initial={{
          x: "-50%",
          y: "-50%",
          top: "-50%",
          left: "50%",
          opacity: 1,
          scaleX: 0,
          scaleY: 0
        }}
        animate={"open"}
        exit={{ opacity: 0, scale: 0 }}
        variants={variants}
        transition={{
          duration: 0.4,
          when: "afterChildren"
        }}
      >
        <div className="granting-permissions">
          Granting permissions to the teams.
        </div>
        <div className="permissions-details-list rectangle-bg">
          <div className="permissions-name">Team Name</div>
          <div className="permissions-msg">Access Policies</div>
        </div>
        {permissions.map((perm: any, index: number) => {
          return (
            <div className="permissions-details-list" key={index}>
              <div className="permissions-name">{perm.title}</div>
              <div className="permissions-msg">{perm.access}</div>
              <div
                className={
                  perm.status === true
                    ? "permission-img"
                    : "permission-denied-img"
                }
              >
                <img className="check-img " src={perm.icon}></img>
              </div>
            </div>
          );
        })}
      </motion.div>
    </div>
  ) : (
    <div></div>
  );
}
