import React, { ReactElement, useState, useEffect } from "react";
import "components/menu-container/menu-container.css";
import LandScreen from "components/land-screen";
import GameLoader from "components/game-loader";
import StartGame from "components/start-game";

export default function MenuScreenContainer(): ReactElement {
  const [showSplash, setShowSplash] = useState(true);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    let interval: any = null;
    if (showSplash) {
      interval = setInterval(() => {
        setShowSplash(false);
      }, 4000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="menu-container">
      {showSplash ? (
        <LandScreen />
      // ) : showLoader ? (
      //   <GameLoader handleLoadingComplete={() => setShowLoader(false)} />
      ) : (
        <StartGame />
      )}
    </div>
  );
}
