import React, {
  ReactElement
} from "react";
import "components/land-screen/land-screen.css";
import TetrateLogo from "components/tetrate-logo";
import { motion } from "framer-motion";

export default function LandScreen(): ReactElement {

  return (
    <div className="land-screen-background">
      <TetrateLogo animateTheGame={true} />
      <motion.text className="copyright-message"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 1,
          duration: 1,
        }}
      >
        Copyright © Tetrate 2020. All rights reserved.
      </motion.text>
    </div>
  )
}