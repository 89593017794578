import React from "react";
import "./App.css";
import Stage from "components/stage";

import Topbar from "components/topbar";
import Hintbar from "components/hintbar/hintbar";

import BadgeEarn from "components/badge-earn";
import UpdateGameResponse from "services/game-response-service";
import IntroPopUp from "components/intro-popup";
import GameTour from "components/game-tour";
import Tick from "components/tick";
import StarAnimation from "components/star-animation";
import MissionAccomplished from "components/mission-accomplish";
import ParameterBox from "components/parameter-box";
import UserDetails from "components/user-details";
import PostMortemEmail from "components/postmortem-email";
import MusicOptions from "components/music-options/music-options";

function App() {
  return (
    <div className="App">
      <GameTour />
      <Tick />
      <UserDetails />
      <MissionAccomplished />
      <Topbar />
      <IntroPopUp />
      <Stage />
      <Hintbar></Hintbar>
      <UpdateGameResponse />
      <BadgeEarn />
      {/* <StarAnimation /> */}
      <PostMortemEmail />
      <ParameterBox />
      <MusicOptions />
    </div>
  );
}

export default App;
