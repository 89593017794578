import React, { ReactElement, useEffect, useState } from "react";
import "components/landscape-popup/landscape-popup.css";
import { motion } from "framer-motion";
import { setShowLandscapePopup } from "slices/system";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { v4 as uuidv4 } from "uuid";

export default function LandscapePopup(props: any): ReactElement {
  let dispatch = useDispatch();

  const [showButton, setShowButton] = useState(false);
  let system = useSelector((state: RootState) => {
    let obj = {
      showLandscapePopup: state.system.popupDetails.showLandscapePopup,
    };
    return obj;
  }, shallowEqual);

  function readDeviceOrientation() {
    if(window.orientation !== undefined) {
      if (Math.abs(parseFloat(""+window.orientation+"")) === 90) {
        dispatch(setShowLandscapePopup(false));
      } else {
        dispatch(setShowLandscapePopup(true));
      }  
    }
  }

  window.onorientationchange = readDeviceOrientation;

  window.onload = function () {
    readDeviceOrientation();
  };

  useEffect(() => {
    if (window.innerWidth < window.innerHeight) {
      dispatch(setShowLandscapePopup(true));
    }
  }, [dispatch]);

  function fullscreen() {
    dispatch(setShowLandscapePopup(false));
    props.onClick();
  }

  return (
    <div>
      {system.showLandscapePopup ? (
        <motion.div
          key={uuidv4()}
          className="landscape-screen"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="landscape-details">
            <div className="landscape-msg">
              {/* The game is best viewed in landscape mode. For a superior
              experience, please switch to landscape and full screen mode on your device and
              refresh the screen.{" "} */}
               This is best viewed in landscape mode. For a superior
               experience, please switch to landscape mode on your device and
               refresh the screen.{" "}
            </div>
            {showButton ? (
              <motion.div
                className="fullscreen-action-button noSelect"
                onClick={(evt) => fullscreen()}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.1, duration: 1 }}
              >
                <div className="fullscreen-button-title">Enter Full Screen</div>
              </motion.div>
            ) : (
                ""
              )}
          </div>
        </motion.div>
      ) : (
          ""
        )}
    </div>
  );
}
