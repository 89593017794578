import React, { ReactElement, useRef } from "react";
import "components/start-game/start-game.css";
import TetrateLogo from "components/tetrate-logo";
import Settings from "components/settings";
import AboutUs from "components/aboutus";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { motion, useCycle, AnimatePresence } from "framer-motion";
import { RootState } from "redux-setup/root-reducer";
import { toggleSoundSetting, toggleMusicSetting } from "slices/system";
import { playButtonClickMusic } from "services/npc-service";
import ReactGA from "react-ga";
import { GAME_START } from "configs/app-config";

export default function StartGame(): ReactElement {
  let system = useSelector((state: RootState) => {
    let obj = {
      playAudio: state.system.musicDetails.playAudio,
      volumeOn: state.system.musicDetails.volumeOn
    };
    return obj;
  }, shallowEqual);

  const [showSettings, toggleShowSettings] = useCycle(false, true);
  const [showAboutUs, toggleShowAboutUs] = useCycle(false, true);
  const containerRef = useRef(null);

  const history = useHistory();
  const dispatch = useDispatch();

  function handleStartGameAction() {
    playButtonClickMusic();
    ReactGA.event({
      category: "User",
      action: GAME_START
    });
    history.replace("/main-graph");
  }

  function handleSettingsAction() {
    toggleShowSettings();
  }

  function handleAboutUsAction() {
    toggleShowAboutUs();
  }
  function handleSoundsAction() {
    dispatch(toggleSoundSetting());
  }

  function handleMusicAction() {
    dispatch(toggleMusicSetting());
  }

  return (
    <div className="start-background">
      <AnimatePresence>
        {showSettings && <Settings onClose={() => toggleShowSettings()} />}
        {showAboutUs ? (
          <motion.nav
            initial={"closed"}
            exit={"closed"}
            animate={showAboutUs ? "open" : "closed"}
            onAnimationComplete={() => console.log("end")}
            ref={containerRef}
            variants={{}}
          >
            <AboutUs onClose={() => toggleShowAboutUs()} />
          </motion.nav>
        ) : (
          <div></div>
        )}
      </AnimatePresence>
      {!showAboutUs && !showSettings ? (
        <TetrateLogo animateTheGame={false} />
      ) : (
        <div />
      )}
      {!showAboutUs && !showSettings ? (
        <motion.div
          className="start-options-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="icon-container">
            <div
              className="setting-button-bg"
              onClick={evt => handleAboutUsAction()}
            >
              <div className="info-button info-btn-size" />
            </div>
            <div className="info-btn-size"> </div>
          </div>
          <div
            className="start-action-button noSelect"
            onClick={evt => handleStartGameAction()}
          >
            <div className="start-button-title">START</div>
          </div>
          <div className="icon-container">
            <div
              className={
                system.volumeOn
                  ? "volume-on info-btn-size"
                  : "volume-off info-btn-size"
              }
              onClick={evt => handleSoundsAction()}
            />
            <div
              className={
                system.playAudio
                  ? "music-on info-btn-size"
                  : "music-off info-btn-size"
              }
              onClick={evt => handleMusicAction()}
            />
          </div>
        </motion.div>
      ) : (
        ""
      )}
    </div>
  );
}
