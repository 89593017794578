import React, { ReactElement } from "react";
import "components/aboutus/aboutus.css";
import { motion } from "framer-motion";
import {
  PRIVACY_POLICY_URL,
  TERMS_URL,
  CONTACT_US_URL,
  EMAIL_BODY,
  SHARE_SUBJECT,
  SHARE_URL
} from "configs/app-config";
import { playButtonClickMusic } from "services/npc-service";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga";

import {
  LinkedinShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";

interface Props {
  onClose: Function;
}

export default function AboutUs({ onClose }: Props): ReactElement {
  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

  const optionVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

  function handleConnectAction() {
    playButtonClickMusic();
    window.open(CONTACT_US_URL);
  }

  function handleTermsAction() {
    playButtonClickMusic();
    window.open(TERMS_URL);
  }

  function handlePolicyAction() {
    playButtonClickMusic();
    window.open(PRIVACY_POLICY_URL);
  }

  function sendShareInformation(name: string) {
    ReactGA.event({
      category: "User",
      action: "User shared link on " + name
    });
  }

  return (
    <motion.div className="aboutus-background">
      <div className="aboutus" />
      <motion.div
        className="aboutus-button-bg noSelect"
        variants={optionVariants}
        onClick={evt => onClose()}
      />

      <motion.ul variants={variants} className="aboutus-container connect">
        <motion.li variants={optionVariants} whileTap={{ scale: 0.95 }}>
          <div
            className="aboutus-action-button aboutus-button noSelect"
            onClick={evt => handleConnectAction()}
          >
            <div className="aboutus-action-button-title"> CONNECT </div>
          </div>
        </motion.li>
        <motion.li
          variants={optionVariants}
          whileTap={{ scale: 0.95 }}
          className=""
        >
          <div
            className="aboutus-action-button aboutus-button noSelect"
            onClick={evt => handleTermsAction()}
          >
            <div className="aboutus-action-button-title"> TERMS OF USE </div>
          </div>
        </motion.li>
        <motion.li variants={optionVariants} whileTap={{ scale: 0.95 }}>
          <div
            className="aboutus-action-button aboutus-button noSelect"
            onClick={evt => handlePolicyAction()}
          >
            <div className="aboutus-action-button-title"> PRIVACY POLICY </div>
          </div>
        </motion.li>
      </motion.ul>
      <motion.div className="share-options" variants={optionVariants}>
        <EmailShareButton
          url={""}
          subject={SHARE_SUBJECT}
          body={EMAIL_BODY}
          className="share"
        >
          <EmailIcon
            size={42}
            round={true}
            onClick={() => sendShareInformation("Email")}
          />
        </EmailShareButton>
        <TwitterShareButton
          url={SHARE_URL}
          title={SHARE_SUBJECT}
          className="share"
          onClick={() => sendShareInformation("Twitter")}
        >
          <TwitterIcon size={42} round={true} />
        </TwitterShareButton>
        <LinkedinShareButton
          url={SHARE_URL}
          title={SHARE_SUBJECT}
          className="share noSelect"
          onClick={() => sendShareInformation("Linkedin")}
        >
          <LinkedinIcon size={42} round={true} />
        </LinkedinShareButton>
      </motion.div>
      <motion.div className="music-credit" variants={optionVariants}>
        Music: www.bensound.com
      </motion.div>
    </motion.div>
  );
}
