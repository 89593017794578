import React, { ReactElement, useEffect, useState } from "react";
import "components/badge-earn/badge-earn.css";
import { UpdateBadge } from "models/badge";
import {
  updateEarnedBadges,
  setShowPermissions,
  setCanUpdateSystemHealth,
  setDropTsbHealth
} from "slices/system";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { RootState } from "redux-setup/root-reducer";
import { v4 as uuidv4 } from "uuid";

interface system {}
export default function BadgeEarn(): ReactElement {
  let dispatch = useDispatch();

  let system = useSelector((state: RootState) => {
    let obj = {
      showEarnedBadge: state.system.system.showEarnedBadge,
      earnedBadges: state.system.system.earnedBadges
    };
    return obj;
  }, shallowEqual);

  var badge = system.earnedBadges[system.earnedBadges.length - 1];

  let xPos = window.innerWidth > 1000 ? badge?.xPosDesktop : badge?.xPosmobile;
  let yPos = window.innerHeight > 360 ? badge?.yPosDesktop : badge?.yPosmobile;
  let scale = window.innerWidth > 1000 ? 0.4 : 0.3;

  const variants = {
    open: {
      x: "-50%",
      y: "-50%",
      top: "50%",
      left: "50%",
      scaleX: 1,
      scaleY: 1,
      opacity: 1
    },
    closed: { opacity: 0, scale: 0 }
  };
  const badgeFly = {
    visible: { x: "20%", y: "80%", scale: 0.9, opacity: 1 },
    hidden: {
      x: xPos ? xPos : 0,
      y: yPos ? yPos : 0,
      scale: scale,
      opacity: 0
    }
  };

  function handleOKAction() {
    let badgeUpdate: UpdateBadge = {
      badge: null,
      show: false
    };
    if (badge?.badgeName === "TSB") {
      setTimeout(() => {
        dispatch(setShowPermissions(true));
      }, 4000);
    }
    if (badge?.badgeName === "envoy") {
      dispatch(setCanUpdateSystemHealth(false));
      setTimeout(() => {
        dispatch(setCanUpdateSystemHealth(true));
      }, 3000);
      dispatch(setDropTsbHealth(false));
    }
    dispatch(updateEarnedBadges(badgeUpdate));
  }

  return (
    <AnimatePresence>
      {system.showEarnedBadge && (
        <motion.div
          key={uuidv4()}
          className="badge-screen"
          initial={{
            x: "-50%",
            y: "-50%",
            top: "-50%",
            left: "50%",
            opacity: 1,
            scaleX: 0,
            scaleY: 0
          }}
          animate={"open"}
          exit={{ opacity: 0, scale: 0 }}
          variants={variants}
          transition={{
            duration: 0.4,
            when: "afterChildren"
          }}
        >
          <div className="badge-screen-container">
            <div className="badge-earned-name">
              <div>
                <motion.img
                  // key={uuidv4()}
                  src={badge?.imageSrc}
                  className="badge-icon"
                  transition={{
                    duration: 0.4
                  }}
                  exit={{
                    x: "-1100%",
                    y: "-800%",
                    scale: 0,
                    opacity: 0
                  }}
                />
                <div className="badge-title">{badge?.badgeName}</div>
                <div className="badge-text">{badge?.badgeSubtitle}</div>
                <div className="lines-container">
                  <div className="lines"></div>

                  <div className="lines"></div>
                  <div className="lines"></div>
                </div>
              </div>
            </div>
            <div className="badge-earned-details">
              <div>
                <div className="awesome-text">{badge?.message}</div>
                <div className="sub-title">{badge?.title}</div>
                <div className="sub-text">{badge?.subtitle}</div>
                <ul className="badge-earned-steps">
                  {badge?.badgeDetails.map((item: any, index: number) => (
                    <li key={index}>{item.detail}</li>
                  ))}
                </ul>{" "}
              </div>
            </div>
          </div>
          <motion.div
            initial={{
              x: "-50%",
              y: "-50%",
              top: "1%",
              left: "99%",
              scaleX: 1,
              scaleY: 1,
              opacity: 1
            }}
            className="badge-close-button"
            onClick={evt => handleOKAction()}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
