import { userDetails } from "./user-details-slice";

export const {
  getuserDetailsStart,
  getuserDetailsSuccess,
  getuserDetailssFailure,
  resetuserState
} = userDetails.actions;

export default userDetails.reducer;

export { submitUserDetails } from "./user-details-thunks";
