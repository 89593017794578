import { motion } from "framer-motion";
import React from "react";
import "components/game-tour/game-tour.css";


const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)"
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255, 255, 0)"
  }
};
const transition = { delay: 1.11, duration: 0.1 }
const arrowTranstion = { default: { delay: 0.5, duration: 0.6 } }

export const stepOneArrowImage = (
  <motion.svg width="36px" height="49px" viewBox="0 0 36 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="arrow-bg tour-1">
    <motion.g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <motion.g id="Tour-3" transform="translate(-169.000000, -23.000000)" stroke="#FF590D">
        <motion.g id="Group-11" transform="translate(186.909190, 47.000000) scale(-1, -1) rotate(70.000000) translate(-186.909190, -47.000000) translate(165.909190, 37.000000)">
          <motion.path d="M2.48689958e-14,17.7587891 C19.5097727,23.4339193 32.8733759,17.6171747 40.0908097,0.308555366" id="Path-13"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={arrowTranstion}
          ></motion.path>
          <motion.polyline id="Path-14" points="33.0908097 0 41.0908097 0 41.0908097 7"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
          ></motion.polyline>
        </motion.g>
      </motion.g>
    </motion.g>
  </motion.svg>
)

export const setpTwoArrowImage = (
  <motion.svg width="36px" height="49px" viewBox="0 0 36 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="arrow-up">
    <motion.g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <motion.g id="Tour-2" transform="translate(-345.000000, -23.000000)" stroke="#FF590D">
        <motion.g id="Group-11" transform="translate(362.909190, 47.000000) scale(1, -1) rotate(70.000000) translate(-362.909190, -47.000000) translate(341.909190, 37.000000)">
          <motion.path d="M2.48689958e-14,17.7587891 C19.5097727,23.4339193 32.8733759,17.6171747 40.0908097,0.308555366" id="Path-13"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={arrowTranstion}
          ></motion.path>
          <motion.polyline id="Path-14" points="33.0908097 0 41.0908097 0 41.0908097 7"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
          ></motion.polyline>
        </motion.g>
      </motion.g>
    </motion.g>
  </motion.svg>
)

export const stepThreeArrowImage = (
  <motion.svg width="36px" height="49px" viewBox="0 0 36 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="arrow-up-left">
    <motion.g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <motion.g id="Tour-3" transform="translate(-169.000000, -23.000000)" stroke="#FF590D">
        <motion.g id="Group-11" transform="translate(186.909190, 47.000000) scale(-1, -1) rotate(70.000000) translate(-186.909190, -47.000000) translate(165.909190, 37.000000)">
          <motion.path d="M2.48689958e-14,17.7587891 C19.5097727,23.4339193 32.8733759,17.6171747 40.0908097,0.308555366" id="Path-13"
            variants={icon}
            initial="hidden"
            animate="visible"
            transition={arrowTranstion}
          ></motion.path>
          <motion.polyline id="Path-14" points="33.0908097 0 41.0908097 0 41.0908097 7"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={transition}
          ></motion.polyline>
        </motion.g>
      </motion.g>
    </motion.g>
  </motion.svg>
)

