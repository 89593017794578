import { combineReducers } from "@reduxjs/toolkit";
import systemReducer from "slices/system";
import stageNodeReducer from "slices/stage-nodes";
import userDetailsReducer from "slices/user-details";

const rootReducer = combineReducers({
  system: systemReducer,
  stageNodes: stageNodeReducer,
  userDetails: userDetailsReducer
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
