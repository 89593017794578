import React, { ReactElement, useState, useEffect } from "react";
import "components/user-details/user-details.css";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { setShowUserDetailsScreen } from "slices/system";
import { submitUserDetails, resetuserState } from "slices/user-details";
import { UserDetailsRequest } from "models/user";
import { playButtonClickMusic } from "services/npc-service";
import {
  SHARE_URL,
  TITLE,
  SHARE_SUBJECT,
  EMAIL_BODY,
  FORM_SUBMITTED,
  FORM_CLOSED
} from "configs/app-config";
import { HUBSPOT_FORM } from "configs/hubspot-config";
import { motion } from "framer-motion";
import {
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";
import ReactGA from "react-ga";
export default function UserDetails(): ReactElement {
  let dispatch = useDispatch();
  const [showThankYouMessage, setshowThankYouMessage] = useState(false);
  let system = useSelector((state: RootState) => {
    let result = {
      showUserDetailsScreen: state.system.popupDetails.showUserDetailsScreen,
      inlineMessage: state.userDetails.inlineMessage,
      isLoading: state.userDetails.isLoading
    };
    return result;
  }, shallowEqual);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [email, setEmail] = useState("");
  const [serviceBridge, setServiceBridge] = useState(false);
  const [istioSubsciption, setIstioSubsciption] = useState(false);
  const optionVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };
  useEffect(() => {
    function keyHandling(e: any) {
      window.scrollTo(0, 0);
    }
    window.addEventListener("hidekeyboard", keyHandling, false);
    return () => window.addEventListener("hidekeyboard", keyHandling, false);
  }, []);
  useEffect(() => {
    if (system.inlineMessage.length > 0) {
      dispatch(resetuserState(""));
      clearInputFields();
    }
  }, [dispatch, system.inlineMessage]);
  function clearInputFields() {
    setName("");
    setCompany("");
    setDesignation("");
    setEmail("");
    setServiceBridge(false);
    setIstioSubsciption(false);
  }
  function handleSubmitAction() {
    if (system.isLoading === false) {
      if (
        name.length === 0 ||
        company.length === 0 ||
        designation.length === 0 ||
        email.length === 0 ||
        (istioSubsciption === false && serviceBridge === false)
      ) {
        alert("Please fill below details.");
      } else if (validateEmail(email) === true) {
        playButtonClickMusic();
        saveuserDetails();
        setshowThankYouMessage(true);
        ReactGA.event({
          category: "User",
          action: FORM_SUBMITTED
        });
      }
    }
  }
  function validateEmail(inputText: string) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) {
      return true;
    } else {
      alert("You have entered an invalid email address.");
      return false;
    }
  }
  function onClose() {
    clearInputFields();
    setshowThankYouMessage(false);
    dispatch(setShowUserDetailsScreen(false));
    ReactGA.event({
      category: "User",
      action: FORM_CLOSED
    });
  }
  function sendShareInformation(name: string) {
    ReactGA.event({
      category: "User",
      action: "User shared link on " + name
    });
  }
  function saveuserDetails() {
    var subscriptions = "";
    if (serviceBridge) {
      subscriptions = "Tetrate Service Bridge";
    }
    if (istioSubsciption) {
      subscriptions += serviceBridge
        ? ";Tetrate Istio Subscription"
        : "Tetrate Istio Subscription";
    }
    var fields: any = [];
    fields.push({
      name: HUBSPOT_FORM.FIRSTNAME,
      value: name.split(" ")[0]
    });
    fields.push({
      name: HUBSPOT_FORM.LASTNAME,
      value: name
        .split(" ")
        .slice(1)
        .join(" ")
    });
    fields.push({
      name: HUBSPOT_FORM.COMPANY,
      value: company
    });
    fields.push({
      name: HUBSPOT_FORM.JOBTITLE,
      value: designation
    });
    fields.push({
      name: HUBSPOT_FORM.EMAIL,
      value: email
    });
    fields.push({
      name: HUBSPOT_FORM.OFFERINGS,
      value: subscriptions
    });
    let userDetailsRequest: UserDetailsRequest = {
      fields: fields,
      context: {
        pageUri: SHARE_URL,
        pageName: TITLE
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text:
            "I agree to allow Example Company to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text:
                "I agree to receive marketing communications from Example Company."
            }
          ]
        }
      }
    };
    clearInputFields();
    dispatch(submitUserDetails(userDetailsRequest));
  }
  return system.showUserDetailsScreen ? (
    <div className="user-background" key={"user-details"}>
      {!showThankYouMessage ? (
        <div className="user-screen">
          <div className="user-container">
            <div className="close-details-button" onClick={evt => onClose()} />
            <div className="user-challange">
              Get in touch with us at any stage of your app modernization
              journey.
            </div>
            <div className="user-details-msg">
              Fill out this form and you'll hear from us soon.
            </div>
            <div className="input-container">
              <div className="rectangle-input">
                <input
                  className="input-filed"
                  key="fullname"
                  id="fullname"
                  type="text"
                  value={name}
                  placeholder="Full Name"
                  autoComplete="off"
                  onChange={event => {
                    setName(event.currentTarget.value);
                  }}
                />
              </div>
              <div className="rectangle-input">
                <input
                  className="input-filed"
                  key="company"
                  id="company"
                  type="text"
                  value={company}
                  placeholder="Company"
                  autoComplete="off"
                  onChange={event => {
                    setCompany(event.currentTarget.value);
                  }}
                />
              </div>
            </div>
            <div className="input-container">
              <div className="rectangle-input">
                <input
                  className="input-filed"
                  key="designation"
                  id="designation"
                  type="text"
                  value={designation}
                  placeholder="Job Title"
                  autoComplete="off"
                  onChange={event => {
                    setDesignation(event.currentTarget.value);
                  }}
                />
              </div>
              <div className="rectangle-input">
                <input
                  className="input-filed"
                  key="email"
                  id="email"
                  type="text"
                  value={email}
                  placeholder="Work Email ID"
                  autoComplete="off"
                  onChange={event => {
                    setEmail(event.currentTarget.value);
                  }}
                />
              </div>
            </div>
            <div className="what-offering">
              What offering are you interested in?
            </div>
            <div className="input-container">
              <div className="checkbox-container">
                <div
                  className={serviceBridge ? "checked" : "unchecked"}
                  onClick={() => setServiceBridge(!serviceBridge)}
                >
                  {serviceBridge ? (
                    <img className="" src="/assets/images/granted.svg"></img>
                  ) : (
                    ""
                  )}
                </div>
                <div className="tetrate-service-brid">
                  Tetrate Service Bridge
                </div>
              </div>
              <div className="checkbox-container istio-check">
                <div
                  className={istioSubsciption ? "checked" : "unchecked"}
                  onClick={() => setIstioSubsciption(!istioSubsciption)}
                >
                  {istioSubsciption ? (
                    <img className="" src="/assets/images/granted.svg"></img>
                  ) : (
                    ""
                  )}
                </div>
                <div className="tetrate-service-brid">
                  Tetrate Istio Subscription
                </div>
              </div>
            </div>
            <div
              className="submit-button noSelect"
              onClick={evt => handleSubmitAction()}
            >
              <div className="submit-title">SUBMIT</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="thank-you-div">
          <div>
            <div className="close-details-button" onClick={evt => onClose()} />
            <div className="tet-logo" />
            <div className="mission-accomplished">
              Thank you for filling out the form.
            </div>
            <div className="mission-accomplished">
              We will get back to you shortly.
            </div>
            <motion.div
              className="share-options"
              style={{ marginTop: "2%" }}
              variants={optionVariants}
            >
              <EmailShareButton
                url={""}
                subject={SHARE_SUBJECT}
                body={EMAIL_BODY}
                className="share"
              >
                <EmailIcon
                  size={42}
                  round={true}
                  onClick={() => sendShareInformation("Email")}
                />
              </EmailShareButton>
              <TwitterShareButton
                url={SHARE_URL}
                title={SHARE_SUBJECT}
                className="share"
                onClick={() => sendShareInformation("Twitter")}
              >
                <TwitterIcon size={42} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={SHARE_URL}
                title={SHARE_SUBJECT}
                className="share noSelect"
                onClick={() => sendShareInformation("Linkedin")}
              >
                <LinkedinIcon size={42} round={true} />
              </LinkedinShareButton>
            </motion.div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div style={{ display: "none" }}></div>
  );
}
