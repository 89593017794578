import React, { ReactElement } from "react";
import "components/stage/stage.css";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { getCurrentStepConfig } from "resources/steps";
import {
  LoadBalancer,
  Microservice,
  Monolith,
  ISTIO,
  MicroserviceEnvoy,
  TSB,
  DynamicEdge
} from "components/node-types";
import NodeType from "models/node-type";
import { v4 as uuidv4 } from "uuid";
import { clearNodes } from "slices/stage-nodes";

interface Props {}

function Stage(): ReactElement {
  const dispatch = useDispatch();
  let system = useSelector((state: RootState) => {
    let obj = {
      currentStep: state.system.system.state,
      isTourOpen: state.system.popupDetails.isTourOpen,
      showIntroduction: state.system.popupDetails.showIntroduction,
      missionAccomplished: state.system.popupDetails.missionAccomplished
    };
    return obj;
  }, shallowEqual);

  function renderNodes() {
    dispatch(clearNodes());
    const step = getCurrentStepConfig(system.currentStep);

    let svgArray: Array<ReactElement> = [];
    step.edges.map((edge: any) => {
      svgArray.push(
        <DynamicEdge
          key={uuidv4()}
          source={edge.sourceId}
          destination={edge.destinationId}
          animationDelay={edge.animationDelay}
          className={edge.className}
          shouldAnimate={edge.shouldAnimate}
          curve={edge.curve}
          cluster={edge.cluster}
          animationTimer={edge.animationFrequency}
        ></DynamicEdge>
      );
    });

    step.nodes.map((node: any) => {
      if (node.type === NodeType.TSB) {
        svgArray.push(
          <TSB
            xPosition={node.xPosition}
            yPosition={node.yPosition}
            animate={node.animate}
            strokeColor={node.strokeColor}
            key={node.id}
            node={node}
            id={node.id}
          />
        );
      } else if (node.type === NodeType.ISTIO) {
        svgArray.push(
          <ISTIO
            xPosition={node.xPosition}
            yPosition={node.yPosition}
            animate={node.animate}
            strokeColor={node.strokeColor}
            key={node.id}
            node={node}
            id={node.id}
          ></ISTIO>
        );
      } else if (node.type === NodeType.MONOLITH) {
        svgArray.push(
          <Monolith
            xPosition={node.xPosition}
            yPosition={node.yPosition}
            animate={node.animate}
            strokeColor={node.strokeColor}
            key={uuidv4()}
            node={node}
            id={node.id}
          ></Monolith>
        );
      } else if (node.type === NodeType.LOADBALANCER) {
        svgArray.push(
          <LoadBalancer
            xPosition={node.xPosition}
            yPosition={node.yPosition}
            animate={node.animate}
            strokeColor={node.strokeColor}
            key={uuidv4()}
            node={node}
            id={node.id}
          ></LoadBalancer>
        );
      } else if (node.type === NodeType.MICROSERVICE) {
        svgArray.push(
          <Microservice
            xPosition={node.xPosition}
            yPosition={node.yPosition}
            fillColour={node.fillColour}
            animate={node.animate}
            strokeColor={node.strokeColor}
            key={uuidv4()}
            node={node}
            id={node.id}
          ></Microservice>
        );
      } else if (node.type === NodeType.MICROSERVICE_ENVOY) {
        svgArray.push(
          <MicroserviceEnvoy
            id={node.id}
            xPosition={node.xPosition}
            yPosition={node.yPosition}
            fillColour={node.fillColour}
            animate={node.animate}
            strokeColor={node.strokeColor}
            key={uuidv4()}
            node={node}
          />
        );
      }
    });

    return svgArray;
  }

  return system.isTourOpen || system.showIntroduction || system.missionAccomplished ? (
    <div></div>
  ) : (
    <div className="stage-size">
      <svg height="100%" width="100%" className="graph-container">
        {renderNodes()}
      </svg>
    </div>
  );
}

export default Stage;
