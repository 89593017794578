import NodeType from "models/node-type";
import { SystemStates } from "constants/system-states";

export function getNodeIdWithType(type: NodeType) {
  switch (type) {
    case NodeType.MONOLITH:
      return 1;
    case NodeType.MONOLITH_ZOOMED:
      return 2;
    case NodeType.MICROSERVICE:
      return 3;
    case NodeType.ISTIO:
      return 4;
    case NodeType.LOADBALANCER:
      return 5;
    case NodeType.TETRATESERVICEBUS:
      return 6;
    case NodeType.ENVOY:
      return 7;
    default:
      return 0;
  }
}

export function getNodeCapacityForState(state: SystemStates) {
  switch (state) {
    case SystemStates.DECOMPOSED_MONOLITHS:
      return 76;
      break;
    case SystemStates.ADDED_ENVOY:
      return 100;
      break;
    case SystemStates.ENVOY_ZOOM_OUT:
      return 80;
      break;
    case SystemStates.ADDED_ISTIO:
      return 100;
      break;
    case SystemStates.FAILOVER:
      return 11;
      break;
    case SystemStates.ADD_TSB:
      return 100;
      break;
    case SystemStates.UPDATE:
      return 100;
      break;

    case SystemStates.TSB_FAILOVER:
      return 100;
      break;
    default:
      return 0;
  }
}

export function getMinLimitForState(state: SystemStates) {
  var limit = 10;
  if (state === SystemStates.ADDED_ISTIO) {
    limit = 0;
  } else if (state === SystemStates.ISTIO_ZOOM_OUT) {
    limit = -50;
  } else if (state === SystemStates.FAILOVER) {
    limit = 0;
  } else if (state === SystemStates.TSB_FAILOVER) {
    limit = 96;
  }

  return limit;
}
