import React, { ReactElement } from "react";
import "components/intro-popup/intro-popup.css";
import { motion } from "framer-motion";
import { setShowIntroduction, setTourOpen } from "slices/system";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { playButtonClickMusic } from "services/npc-service";

export default function IntroPopUp(): ReactElement {
  let dispatch = useDispatch();

  let showIntroduction = useSelector((state: RootState) => {
    return state.system.popupDetails.showIntroduction;
  }, shallowEqual);

  function handleBeginGameAction() {
    playButtonClickMusic()
    dispatch(setShowIntroduction(false));
    dispatch(setTourOpen(true));
  }

  return showIntroduction ? (
    <div>
      <motion.div
        className="intro-popover-details"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
      >
        <div className="intro-logo">TP Inc.</div>
        <div className="subject-container">
          <div className="details-container">
            <div className="title"> From: </div>
            <div> T </div>
          </div>
          <div className="details-container">
            <div className="title"> Subject: </div>
            <div> Welcome to TP Inc! </div>
          </div>
        </div>
        <div className="wc-meg-conatiner">
          <div className="wc-message"> Hi! </div>
          <div className="wc-message">
            Welcome to TP, Inc! We’re a small toilet paper manufacturer, but 
            we’ve seen such a surge in demand that’s resulted 
            in an increased load on our services.
          </div>
          <div className="wc-message">
          I’m ‘T’ from the Platform Ops team. I’ll help guide you through.
          </div>
        </div>
        <motion.div
          className="begin-action-button noSelect"
          onClick={evt => handleBeginGameAction()}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.1, duration: 1 }}
        >
          <div className="begin-button-title">LET’S BEGIN</div>
        </motion.div>
      </motion.div>
    </div>
  ) : (
    <div></div>
  );
}
