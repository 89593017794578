import React, { ReactElement, useEffect } from "react";
import "components/hintbar/hintbar.css";
import { handleUserAction } from "services/npc-service";
import { motion } from "framer-motion";
import Typist from "react-typist";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { NodeParams } from "models/system";
import { updateShowParams } from "slices/system";
import ReactGA from "react-ga";
import { USER_CLICKED } from "configs/app-config";

export default function Hintbar(): ReactElement {
  let system = useSelector((state: RootState) => {
    let obj = {
      hint: state.system.system.hint,
      showIntroduction: state.system.popupDetails.showIntroduction,
      isTourOpen: state.system.popupDetails.isTourOpen,
      tourStepNumber: state.system.popupDetails.tourStepNumber,
      missionAccomplished: state.system.popupDetails.missionAccomplished,
      showUserDetailsScreen: state.system.popupDetails.showUserDetailsScreen,
      state: state.system.system.state,
      params: state.system.params
    };
    return obj;
  }, shallowEqual);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   let state = system.state;
  //   if ((state === SystemStates.ADD_TSB || state === SystemStates.TSB_ZOOMED) && (system.hint.hintType === "userAction")) {
  //     let actionName = state === SystemStates.ADD_TSB ? "UPDATE" : "FAILOVER";
  //     setTimeout(() => {
  //       handleUserAction(actionName)
  //     }, 4000);
  //   }
  // }, [system.state, system.hint.hintType]);

  useEffect(() => {
    if (system.params.nodeId !== "") {
      let node: NodeParams = {
        nodeId: "",
        showParams: false
      };
      dispatch(updateShowParams(node));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    system.state,
    system.hint.hintType,
    system.missionAccomplished,
    system.showUserDetailsScreen
  ]);

  function performUserAction(actionName: string, gaName: string) {
    ReactGA.event({
      category: "User",
      action: USER_CLICKED + gaName + " button"
    });

    handleUserAction(actionName);
    // if (system.state !== SystemStates.ADD_TSB && system.state !== SystemStates.TSB_ZOOMED) {
    // handleUserAction(actionName);
    // }
  }

  const variants = {
    open: { x: "0%", y: "0%", scale: 1 },
    closed: { x: "-50%", y: "50%", scale: 0 }
  };
  const logo = {
    open: { opacity: 1 },
    closed: { opacity: 0 }
  };
  const hint = {
    open: { opacity: 1 },
    closed: { opacity: 0 }
  };
  return (
    <div>
      {!system.showIntroduction &&
      !system.missionAccomplished &&
      !system.showUserDetailsScreen ? (
        <motion.div
          className={
            system.isTourOpen
              ? system.tourStepNumber === 0
                ? "enabled-tour-t-logo"
                : "disabled-t-logo"
              : "enabled-t-logo"
          }
          style={
            system.isTourOpen && system.tourStepNumber === 0
              ? { zIndex: 111111 }
              : {}
          }
          initial={{ opacity: 0 }}
          variants={logo}
          animate={
            system.hint.showHint || system.isTourOpen ? "open" : "closed"
          }
          transition={{
            duration: 1,
            ease: "easeInOut"
          }}
        >
          <div
            className={
              system.isTourOpen
                ? system.tourStepNumber === 0
                  ? "t-character"
                  : "t-disabled-character"
                : "t-character"
            }
          >
            <div>T</div>
          </div>
        </motion.div>
      ) : (
        ""
      )}
      {!system.isTourOpen &&
      !system.showIntroduction &&
      !system.missionAccomplished &&
      !system.showUserDetailsScreen ? (
        <motion.div
          className="disabled-t-logo"
          initial={{ opacity: 1 }}
          variants={logo}
          animate={
            system.hint.showHint || system.isTourOpen ? "closed" : "open"
          }
          transition={{
            duration: 1,
            ease: "easeInOut"
          }}
        >
          <div className="t-disabled-character">
            <div>T</div>
          </div>
        </motion.div>
      ) : (
        ""
      )}

      <motion.div
        initial={{ x: "-40%", y: "50%", scale: 0 }}
        animate={
          system.hint.showHint && system.hint.hintType === "userAction"
            ? "open"
            : "closed"
        }
        variants={variants}
        transition={{
          duration: 0.3
        }}
        className="user-action-box"
      >
        <motion.div
          key={system.hint.hintText}
          className="drop-text"
          variants={hint}
          initial="closed"
          animate="open"
          transition={{
            duration: 0.5,
            delay: 0.5
          }}
        >
          {system.hint.hintType === "userAction" ? system.hint.hintText : ""}
        </motion.div>
        <div className="user-action-div">
          {system.hint.actions
            ? system.hint.actions.map((item: any, index: number) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, ease: "easeInOut", delay: 1 }}
                  className="user-action-button"
                  key={index}
                >
                  <motion.div
                    className="user-action-img"
                    onClick={evt =>
                      performUserAction(item.actionName, item.gaName)
                    }
                  >
                    <img
                      src={item.imgSrc}
                      className={
                        item.type === "incorrect"
                          ? "user-action-img"
                          : "correct-icon"
                      }
                    />
                  </motion.div>
                  <div
                    className={
                      item.type === "incorrect"
                        ? "incorrect-name icon-name"
                        : "icon-name"
                    }
                  >
                    {item.actionName}
                  </div>
                </motion.div>
              ))
            : ""}
        </div>
      </motion.div>
      <motion.div
        className="speech-bubble"
        initial={{ x: "-40%", y: "50%", scale: 0 }}
        animate={
          system.hint.showHint && system.hint.hintType === "text"
            ? "open"
            : "closed"
        }
        variants={variants}
        transition={{
          duration: 0.5
        }}
      >
        <motion.div
          key={system.hint.hintText}
          className="hint-text"
          variants={hint}
          initial="closed"
          animate="open"
          transition={{
            duration: 1,
            delay: 0.5
          }}
        >
          {system.hint.hintText}
        </motion.div>
      </motion.div>
      <div />
    </div>
  );
}
