import store from "redux-setup/store";
import { SystemStates } from "constants/system-states";
import {
  updateHint,
  updateEarnedBadges,
  updateCapacity,
  updateStateOfSystem,
  setShowStarFly,
  setShowPermissions,
  resetLoad,
  updateShowMissionAccomplished,
  setShowUserDetailsScreen,
  setCanUpdateSystemHealth,
  togglePlayRightActionMusic,
  togglePlayHealthDropMusic,
  toggleBadgeEarnedMusic,
  toggleButtonClickMusic,
  setDropTsbHealth
} from "slices/system";
import { UpdateBadge } from "models/badge";
import { getBadgeFor } from "services/badge-service";
import { getNodeCapacityForState } from "services/node-service";
import { updateDropMoreHealth } from "slices/system";
import ReactGA from "react-ga";
import { FORM_OPENED } from "configs/app-config";

const lowerLimit = {
  minHealth: -100,
  maxHealth: 0
};

const systemStateValues = [
  {
    stateName: SystemStates.BEGIN,
    hintProviderType: "JSON",

    hintData: [
      // {
      //   health: {
      //     minHealth: 95,
      //     maxHealth: 100
      //   },
      //   updateHealth: false,
      //   hintText: "Let’s get going!",
      //   hintType: "text",
      //   actions: []
      // },
      {
        health: {
          minHealth: 100,
          maxHealth: 110
        },
        updateHealth: false,
        soundType: "",
        hintText:
          "We have an active and a passive monolith, and we used to be able to manage our traffic well with them.",
        hintType: "text",
        actions: []
      }
    ]
  },
  {
    stateName: SystemStates.BEGIN_ZOOMED,
    hintProviderType: "JSON",
    hintData: [
      {
        health: {
          minHealth: 0,
          maxHealth: 30
        },
        hintType: "userAction",
        updateHealth: true,
        soundType: "",
        actions: [
          {
            actionName: "ADD MONOLITH",
            imgSrc: "/assets/images/add_monolith.svg",
            nextState: SystemStates.BEGIN,
            type: "incorrect",
            gaName: "ADD MONOLITH"
          },
          {
            actionName: "CARVE MONOLITH",
            imgSrc: "/assets/images/carve_monolith.svg",
            nextState: SystemStates.DECOMPOSED_MONOLITHS,
            type: "correct",
            gaName: "CARVE MONOLITH"
          }
        ],
        hintText: "Traffic is overloading our monolith. What are our options?"
      }
    ]
  },
  {
    stateName: SystemStates.DECOMPOSED_MONOLITHS,
    hintProviderType: "JSON",
    hintData: [
      {
        health: {
          minHealth: 71,
          maxHealth: 75
        },
        hintType: "text",
        updateHealth: false,
        soundType: "",
        actions: [],
        hintText:
          "Our order and payment services are running as microservices now. Way more efficient, and cost effective!"
      },
      {
        health: {
          minHealth: 47,
          maxHealth: 65
        },
        hintType: "text",
        updateHealth: true,
        soundType: "",
        actions: [],
        hintText:
          "With so many new services, networking is struggling to keep up with the load balancer change requests."
      },
      {
        health: {
          minHealth: 0,
          maxHealth: 40
        },
        updateHealth: false,
        soundType: "",
        hintType: "userAction",
        hintText: "What can we do to help?",
        actions: [
          {
            actionName: "ENVOY",
            imgSrc: "/assets/images/envoy_action.svg",
            nextState: SystemStates.ADDED_ENVOY,
            type: "correct",
            gaName: "ENVOY"
          },
          {
            actionName: "TRADITIONAL LOAD BALANCER",
            imgSrc: "/assets/images/loadbalancer_action.svg",
            nextState: SystemStates.DECOMPOSED_MONOLITHS,
            type: "incorrect",
            gaName: "TRADITIONAL LOAD BALANCER"
          }
        ]
      }
    ]
  },
  {
    stateName: SystemStates.ADDED_ENVOY,
    hintProviderType: "JSON",
    hintData: [
      {
        health: {
          minHealth: 96,
          maxHealth: 99
        },
        updateHealth: false,
        soundType: "",
        hintText:
          "Yes! Envoy has made a big difference to our traffic management!",
        hintType: "text",
        actions: []
      }
    ]
  },
  {
    stateName: SystemStates.ENVOY_ZOOM_OUT,
    hintProviderType: "JSON",
    hintData: [
      {
        health: {
          minHealth: 53,
          maxHealth: 65
        },
        updateHealth: true,
        soundType: "",
        hintText: "Wow! Inventory is now running as microservices too.",
        hintType: "text",
        actions: []
      },
      {
        health: {
          minHealth: 25,
          maxHealth: 45
        },
        updateHealth: true,
        soundType: "",
        hintText:
          "As we grow, manually updating each Envoy sidecar will be too much. We need to have standardized control.",
        hintType: "text",
        actions: []
      },
      {
        health: {
          minHealth: 0,
          maxHealth: 15
        },
        hintText:
          "Let’s try adding Istio so that we can automate configuration updates, and get better service observability and security!",
        hintType: "userAction",
        updateHealth: false,
        soundType: "",
        actions: [
          {
            actionName: "ISTIO",
            imgSrc: "/assets/images/istio_action.svg",
            nextState: SystemStates.ADDED_ISTIO,
            type: "correct",
            gaName: "ISTIO"
          },
          {
            actionName: "NO, CONTINUE",
            imgSrc: "/assets/images/close.svg",
            nextState: SystemStates.ENVOY_ZOOM_OUT,
            type: "incorrect",
            gaName: "NO, CONTINUE (with envoy)"
          }
        ]
      }
    ]
  },
  {
    stateName: SystemStates.ADDED_ISTIO,
    hintProviderType: "JSON",
    hintData: [
      {
        health: {
          minHealth: 98,
          maxHealth: 99
        },
        hintText:
          "Great! Istio’s helping us better manage fluctuating traffic, downtime and is also making us more secure!",
        hintType: "text",
        updateHealth: false,
        soundType: "",
        actions: []
      },
      {
        health: {
          minHealth: 70,
          maxHealth: 80
        },
        updateHealth: true,
        soundType: "",
        hintText:
          "Oh no. The health has started dropping, we need to see what’s happened.",
        hintType: "text",
        actions: []
      },
      {
        health: {
          minHealth: 48,
          maxHealth: 60
        },
        updateHealth: true,
        soundType: "",
        hintText:
          "Someone made a change in our active cluster, and it broke traffic to our active cluster. We should failover to the passive cluster.",
        hintType: "text",
        actions: []
      }
    ]
  },
  {
    stateName: SystemStates.ISTIO_ZOOM_OUT,
    hintProviderType: "JSON",
    hintData: [
      // {
      //   health: {
      //     minHealth: 15,
      //     maxHealth: 32
      //   },
      //   updateHealth: true,
      //   hintText:
      //     "Looks like the Rate of Error has increased. We need to find out why. ",
      //   hintType: "text",
      //   actions: []
      // },
      {
        health: {
          minHealth: -50,
          maxHealth: 20
        },
        updateHealth: false,
        soundType: "",
        hintText: "If we don’t failover, the services will remain down.",
        hintType: "userAction",
        actions: [
          {
            actionName: "FAILOVER",
            imgSrc: "/assets/images/Failover_icon.svg",
            nextState: SystemStates.FAILOVER,
            type: "correct",
            gaName: "FAILOVER(fail)"
          },
          {
            actionName: "NO, CONTINUE",
            imgSrc: "/assets/images/close.svg",
            nextState: SystemStates.ISTIO_ZOOM_OUT,
            type: "incorrect",
            gaName: "NO, CONTINUE (with istio)"
          }
        ]
      }
    ]
  },
  {
    stateName: SystemStates.FAILOVER,
    hintProviderType: "JSON",
    hintData: [
      {
        health: {
          minHealth: 9,
          maxHealth: 10
        },
        updateHealth: true,
        soundType: "error",
        hintType: "text",
        actions: [],
        hintText:
          "The failover hasn’t helped. It looks like the other cluster’s configuration is out of date."
      },
      {
        health: {
          minHealth: 6,
          maxHealth: 7
        },
        updateHealth: true,
        soundType: "",
        hintType: "text",
        actions: [],
        hintText: "8 hours later..."
      }
    ]
  },
  {
    stateName: SystemStates.UPDATE,
    hintProviderType: "JSON",
    hintData: [
      {
        health: {
          minHealth: 94,
          maxHealth: 99
        },
        updateHealth: true,
        soundType: "",
        hintText: "We can’t let this happen again.",
        hintType: "text",
        actions: []
      },
      {
        health: {
          minHealth: 90,
          maxHealth: 92
        },
        updateHealth: false,
        soundType: "",
        hintText:
          "We should enable the Tetrate Service Bridge management plane. We need to be able to see everything that’s happening in our services and monoliths, in one place.",
        hintType: "userAction",
        actions: [
          {
            actionName: "TSB",
            imgSrc: "/assets/images/TSB.svg",
            nextState: SystemStates.ADD_TSB,
            type: "correct",
            gaName: "TSB"
          },
          {
            actionName: "NO, CONTINUE",
            imgSrc: "/assets/images/close.svg",
            nextState: SystemStates.FAILOVER,
            type: "incorrect",
            gaName: "NO, CONTINUE (with failover-fail)"
          }
        ]
      }
    ]
  },
  {
    stateName: SystemStates.ADD_TSB,
    hintProviderType: "JSON",
    hintData: [
      {
        health: {
          minHealth: 98,
          maxHealth: 99
        },
        updateHealth: false,
        soundType: "",
        hintText:
          "Tap on the TSB logo to see the top three metrics we’re gathering.",
        hintType: "text",
        actions: []
      },
      {
        health: {
          minHealth: 55,
          maxHealth: 96
        },
        updateHealth: true,
        soundType: "",
        hintText: "The rate of error is high again.",
        hintType: "text",
        actions: []
      },
      {
        health: {
          minHealth: 0,
          maxHealth: 20
        },
        updateHealth: false,
        soundType: "",
        hintText:
          "All configs are updated in the backup cluster. Do you want to failover to that one?",
        hintType: "userAction",
        actions: [
          {
            actionName: "FAILOVER",
            imgSrc: "/assets/images/Failover_icon.svg",
            nextState: SystemStates.TSB_FAILOVER,
            type: "correct",
            gaName: "FAILOVER(pass)"
          },
          {
            actionName: "NO, CONTINUE",
            imgSrc: "/assets/images/close.svg",
            nextState: SystemStates.ADD_TSB,
            type: "incorrect",
            gaName: "NO, CONTINUE (with TSB)"
          }
        ]
      }
    ]
  },
  {
    stateName: SystemStates.TSB_FAILOVER,
    hintProviderType: "JSON",
    hintData: [
      {
        health: {
          minHealth: 97,
          maxHealth: 99
        },
        updateHealth: true,
        soundType: "success",
        hintText: "Look how much smoother it’s running! ",
        hintType: "text",
        actions: []
      }
    ]
  }
];

// let timer : any = null;

// export function startIncreasingRequests() {
//     if (timer !== null) {
//     }
//     else {
//         timer = setInterval(() => handleTickAction(), 1000);
//     }
// }

// export function pauseIncreasingRequests() {
//     clearInterval(timer);
// }

// export function handleTickAction() {
//     const state = store.getState();

//     if(state.system.system.loadProfile.requests >= 120) {
//         pauseIncreasingRequests();
//     }

//     store.dispatch(updateLoadProfile(5));
//     store.dispatch(updateHealthOfSystem());
//     updateGameResponse();
// }

export function updateStateHint() {
  const state = store.getState();

  if (!(state.system.system.hint.hintType === "userAction")) {
    let data = systemStateValues.filter(
      item => item.stateName === state.system.system.state
    )[0];
    if (data && data.hintProviderType === "JSON") {
      let healthOfSystem = state.system.system.health;
      let filteredItem = data.hintData.filter(
        item =>
          healthOfSystem >= item.health.minHealth &&
          healthOfSystem <= item.health.maxHealth
      );

      if (filteredItem.length > 0) {
        let hint = {
          showHint: filteredItem[0].hintText === "" ? false : true,
          hintText: filteredItem[0].hintText,
          hintType: filteredItem[0].hintType,
          actions: filteredItem[0].actions
        };
        let prevHintText = state.system.system.hint.hintText;
        if (hint.hintText !== prevHintText) {
          store.dispatch(updateHint(hint));
          store.dispatch(
            setCanUpdateSystemHealth(filteredItem[0].updateHealth)
          );
          let soundType = filteredItem[0].soundType;
          if (soundType === "error") {
            playErrorMusic();
          } else if (soundType === "success") {
            playCorrectChoiceMusic();
          }
          if (
            (state.system.system.state === SystemStates.ADD_TSB ||
              state.system.system.state === SystemStates.ADDED_ENVOY) &&
            state.system.system.health <= 96
          ) {
            store.dispatch(setDropTsbHealth(true));
          }
          if (hint.hintType !== "userAction" && !filteredItem[0].updateHealth) {
            setTimeout(() => {
              let hint = {
                showHint: false,
                hintText: "",
                hintType: "text",
                actions: []
              };

              store.dispatch(updateHint(hint));
              store.dispatch(setCanUpdateSystemHealth(true));
            }, 7000);
          }
        }
      } else {
        let hint = {
          showHint: false,
          hintText: "",
          hintType: "text",
          actions: []
        };
        let prevHintText = state.system.system.hint.hintText;
        if (hint.hintText !== prevHintText) {
          store.dispatch(updateHint(hint));
        }
      }
    } else {
      //handling the function calls
    }
  }
}
// }

export function handleUserAction(actionName: string) {
  const sysState = store.getState().system.system;
  if (
    actionName === "ENVOY" ||
    actionName === "ISTIO" ||
    actionName === "TSB"
  ) {
    let badge: UpdateBadge;
    if (actionName === "TSB") {
      badge = { badge: getBadgeFor(3), show: true };
      store.dispatch(updateEarnedBadges(badge));
    } else if (actionName === "ENVOY") {
      badge = { badge: getBadgeFor(1), show: true };
      store.dispatch(updateEarnedBadges(badge));
    } else if (actionName === "ISTIO") {
      badge = { badge: getBadgeFor(2), show: true };
      store.dispatch(updateEarnedBadges(badge));
    }
    playCorrectChoiceMusic();
  }
  if (
    actionName === "CARVE MONOLITH" ||
    actionName === "ENVOY" ||
    actionName === "ISTIO" ||
    actionName === "FAILOVER" ||
    actionName === "TSB" ||
    actionName === "UPDATE"
  ) {
    let index = sysState.hint.actions.findIndex(
      item => item.actionName === actionName
    );
    let nextState = sysState.hint.actions[index].nextState;
    let capacity = getNodeCapacityForState(nextState);
    let hint = {
      showHint: false,
      hintText: sysState.hint.hintText,
      hintType: "text",
      actions: sysState.hint.actions
    };
    store.dispatch(updateHint(hint));
    store.dispatch(updateStateOfSystem(sysState.hint.actions[index].nextState));
    store.dispatch(updateCapacity(capacity));
    store.dispatch(resetLoad());
    store.dispatch(updateDropMoreHealth(false));

    setTimeout(() => {
      store.dispatch(setShowStarFly(false));
    }, 7000);

    if (actionName === "TSB") {
      store.dispatch(setCanUpdateSystemHealth(false));
      store.dispatch(setDropTsbHealth(false));
    }
    if (actionName === "CARVE MONOLITH" || actionName === "UPDATE") {
      playCorrectChoiceMusic();
    }

    if (actionName === "FAILOVER" && sysState.state === SystemStates.ADD_TSB) {
      setTimeout(() => {
        playCorrectChoiceMusic();
        store.dispatch(updateShowMissionAccomplished(true));
      }, 15000);
      setTimeout(() => {
        ReactGA.event({
          category: "User",
          action: FORM_OPENED,
          nonInteraction: true
        });
        store.dispatch(setShowUserDetailsScreen(true));
      }, 18000);
    }
  } else if (actionName === "ADD MONOLITH") {
    let action: any = [];
    sysState.hint.actions.map(item =>
      item.actionName === "CARVE MONOLITH" ? action.push(item) : ""
    );
    let hint = {
      showHint: true,
      hintText:
        "Hmm... adding just another monolith isn’t targeting the services that need the most help… Let’s split some of it into microservices.",
      hintType: sysState.hint.hintType,
      actions: action
    };
    store.dispatch(updateHint(hint));
    playErrorMusic();
  } else if (
    actionName === "MICROSERVICE" ||
    actionName === "TRADITIONAL LOAD BALANCER"
  ) {
    let action: any = [];
    sysState.hint.actions.map(item =>
      item.actionName === "ENVOY" ? action.push(item) : ""
    );
    let hint = {
      showHint: true,
      hintText:
        "That may not be the best decision in the long term because it won’t distribute traffic most effectively as we continue to grow. Why don’t we try Envoy?",
      hintType: sysState.hint.hintType,
      actions: action
    };
    store.dispatch(updateHint(hint));
    playErrorMusic();
  } else if (actionName === "NO, CONTINUE") {
    let action: any = [];
    var actionName = "";
    var hintText = "";
    if (sysState.state === SystemStates.ENVOY_ZOOM_OUT) {
      actionName = "ISTIO";
      hintText =
        "If you don’t use Istio, your infrastructure health will continue to drop. You won’t be able to manage services with the granularity you need to keep them healthy.";
      store.dispatch(updateDropMoreHealth(true));
    } else if (sysState.state === SystemStates.ISTIO_ZOOM_OUT) {
      actionName = "FAILOVER";
      hintText = "If we don’t failover, the services will remain down.";
    } else if (sysState.state === SystemStates.UPDATE) {
      actionName = "TSB";
      hintText =
        "Not doing anything might result into infrastructure breaking. We recommend you to use TSB.";
    } else if (sysState.state === SystemStates.ADD_TSB) {
      actionName = "FAILOVER";
      hintText = sysState.hint.hintText;
    }

    sysState.hint.actions.map(item =>
      item.actionName === actionName ? action.push(item) : ""
    );
    let hint = {
      showHint: true,
      hintText: hintText,
      hintType: sysState.hint.hintType,
      actions: action
    };
    store.dispatch(updateHint(hint));
    playErrorMusic();
  }
}

export function playButtonClickMusic() {
  store.dispatch(toggleButtonClickMusic());
  setTimeout(() => {
    store.dispatch(toggleButtonClickMusic());
  }, 1000);
}

export function playErrorMusic() {
  store.dispatch(togglePlayHealthDropMusic());
  setTimeout(() => {
    store.dispatch(togglePlayHealthDropMusic());
  }, 1000);
}

export function playCorrectChoiceMusic() {
  store.dispatch(togglePlayRightActionMusic());
  setTimeout(() => {
    store.dispatch(togglePlayRightActionMusic());
  }, 2000);
}
