import React, { ReactElement, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { motion } from "framer-motion";
import { shallowEqual } from "@babel/types";
import { NodeParams } from "models/system";
import { updateShowParams } from "slices/system";
import "components/parameter-box/parameter-box.css";
import { SystemStates } from "constants/system-states";

interface Props {}

export default function ParameterBox({}: Props): ReactElement {
  const dispatch = useDispatch();
  let system = useSelector((state: RootState) => {
    let sourceElement = state.stageNodes.filter(
      node => node.nodeId === state.system.params.nodeId
    );
    return {
      elements: sourceElement,
      params: state.system.params,
      state: state.system.system.state,
      health: state.system.system.health
    };
  }, shallowEqual);

  function getErrorRate() {
    if (system.state === SystemStates.ADD_TSB && system.health <= 96) {
      return "56%";
    } else {
      return "0.01%";
    }
  }

  const variants = {
    open: {
      scale: 1,
      opacity: 1
    },
    closed: { opacity: 0, scale: 0 }
  };

  function handleParamClose() {
    let node: NodeParams = {
      nodeId: "",
      showParams: false
    };
    dispatch(updateShowParams(node));
  }

  return (
    <motion.div
      style={{
        backgroundColor: "white",
        position: "absolute",
        left: system.elements[0]?.xPosition + system.elements[0]?.width - 20,
        top:
          system.elements[0]?.yPosition + system.elements[0]?.height / 2 + 50,
        display: system.params.showParams ? "block" : "none",
        borderRadius: "5px",
        padding: "0.3px"
      }}
      animate={system.params.showParams ? "open" : "closed"}
      variants={variants}
      transition={{
        duration: 0.5
      }}
    >
      <motion.img
        onClick={handleParamClose}
        className="param-close"
        src="/assets/images/param-close.svg"
        initial={{ x: "0%", y: "0%", scale: 1 }}
        animate={"open"}
        variants={variants}
      ></motion.img>

      <table>
        <tbody>
          <tr className="tables">
            <td>Request Rate</td>
            <td>500r/s</td>
          </tr>
          <tr
            className={
              system.state === SystemStates.ADD_TSB && system.health <= 96
                ? "low-ROE tables"
                : "tables"
            }
          >
            <td>Error Rate</td>
            <td>{getErrorRate()}</td>
          </tr>
          <tr className="tables">
            <td>Latency</td>
            <td>500 ms</td>
          </tr>
        </tbody>
      </table>
    </motion.div>
  );
}
