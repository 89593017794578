import React, {
  ReactElement,
  useState,
  useEffect,
  useRef,
  MutableRefObject,
} from "react";
import { motion } from "framer-motion";
// import "./microservice.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { SystemStates } from "constants/system-states";
import { addNode, updateNode } from "slices/stage-nodes";
import { Node } from "models/system";

interface Props {
  strokeColor?: string;
  xPosition: string;
  yPosition: string;
  animate: any;
  key: string;
  node: any;
  fillColour: string;
  id: string;
}

export default function MicroserviceEnvoy(props: Props): ReactElement {
  const dispatch = useDispatch();

  const envoyRef = useRef() as MutableRefObject<SVGRectElement>;

  let step = useSelector((state: RootState) => {
    return state.system.system.state;
  });

  let health = useSelector((state: RootState) => {
    return state.system.system.health;
  });

  const [isUpdate, setUpdated] = useState(false);
  const [timer, setTimer] = useState(0);

  const pathVariants = {
    hidden: { opacity: 0, stroke: "#3A955E" },
    unhealthy: { opacity: 1, stroke: "red" },
    healthy: { opacity: 1, stroke: "#3A955E" },
  };

  useEffect(() => {
    if (
      step === SystemStates.ISTIO_ZOOM_OUT &&
      props.node.checkForHealth !== undefined &&
      props.node.checkForHealth
    ) {
      let interval: any = null;
      if (interval === null && !isUpdate) {
        interval = setInterval(() => {
          setUpdated(true);
          clearInterval(interval);
        }, props.node.nodeUpdateDelay ?? 1000);
      } else {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [isUpdate, props.node.checkForHealth, props.node.nodeUpdateDelay, step]);

  function getHealthLimit() {
    if (step === SystemStates.ISTIO_ZOOM_OUT) {
      if (
        props.node.checkForHealth !== undefined &&
        props.node.checkForHealth
      ) {
        return 0;
      } else {
        return -50;
      }
    } else if (step === SystemStates.FAILOVER) {
      if (
        props.node.checkForHealth !== undefined &&
        props.node.checkForHealth
      ) {
        return 20;
      } else {
        return 0;
      }
    } else if (
      step === SystemStates.ADDED_ISTIO ||
      step === SystemStates.ADD_TSB ||
      step === SystemStates.TSB_FAILOVER
    ) {
      return 0;
    } else if (step === SystemStates.UPDATE) {
      return 10;
    } else {
      return 50;
    }
  }

  let nodeItem: Node = {
    nodeId: props.id,
    xPosition: 0,
    yPosition: 0,
    height: 0,
    width: 0,
    isAnimating: true,
  };

  dispatch(addNode(nodeItem));

  function onAnimationStart() {
    let timer = setInterval(() => {
      if (envoyRef !== null && envoyRef.current !== null) {
        let obj = envoyRef.current.getBBox();

        let nodeItem: Node = {
          nodeId: props.id,
          xPosition: obj.x,
          yPosition: obj.y,
          height: obj.height,
          width: obj.width,
          isAnimating: true,
        };

        dispatch(updateNode(nodeItem));
      }
    }, 10);
    setTimer(timer);
  }

  function onAnimationComplete() {
    clearTimeout(timer);
    if (envoyRef !== null && envoyRef.current !== null) {
      let obj = envoyRef.current.getBBox();

      let nodeItem: Node = {
        nodeId: props.id,
        xPosition: obj.x,
        yPosition: obj.y,
        height: obj.height,
        width: obj.width,
        isAnimating: false,
      };

      dispatch(updateNode(nodeItem));
    }
  }

  return (
    <motion.g ref={envoyRef}>
      <motion.svg
        overflow="visible"
        viewBox="0 0 80 56"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        height={props.node.height}
        width={props.node.width}
        x={props.xPosition}
        y={props.yPosition}
      >
        {/* <motion.defs>
          <radialGradient
            cx="49.2342841%"
            cy="21.4438537%"
            fx="49.2342841%"
            fy="21.4438537%"
            r="83.6654036%"
            gradientTransform="translate(0.492343,0.214439),scale(1.000000,0.960000),rotate(110.396422),translate(-0.492343,-0.214439)"
            id="radialGradient-1"
          >
            <motion.stop stop-color="#434748" offset="0%"></motion.stop>
            <motion.stop stop-color="#202426" offset="100%"></motion.stop>
          </radialGradient>
        </motion.defs>*/}
        <motion.g
          id="Symbols"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          variants={props.animate.microservice}
          initial="hidden"
          animate={isUpdate ? "afterUpdate" : "visible"}
          transition={{
            default: { duration: 0.5, ease: "easeInOut" },
          }}
          onAnimationStart={onAnimationStart}
          onAnimationComplete={onAnimationComplete}
        >
          <motion.g
            id="Microservice-With-Envoy"
            transform="translate(0.000000, 1.000000)"
          >
            <motion.g
              id="Microservice-With-Envoy"
              transform="translate(1.000000, 0.000000)"
            >
              <motion.g id="Group">
                <motion.g
                  id="Envoy_color"
                  transform="translate(0.000000, 13.000000)"
                  variants={props.animate.envoy}
                  initial="hidden"
                  animate="visible"
                  transition={{
                    default: {
                      duration: 0.5,
                      delay: props.node.animationDelay ?? 0,
                      ease: "easeInOut",
                    },
                  }}
                  onAnimationComplete={onAnimationComplete}
                >
                  <motion.rect
                    id="Rectangle"
                    stroke="#FFFFFF"
                    stroke-width="1.5"
                    fill="url(#radialGradient-1)"
                    fill-rule="nonzero"
                    opacity="0.921442534"
                    stroke-linejoin="round"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                    rx="4"
                  ></motion.rect>
                  <motion.path
                    d="M7.25028846,18.5288711 L9.74965781,17.1356365 C9.92070994,17.0402861 10.0267337,16.8598011 10.0267337,16.6639682 L10.0267337,14.4700439 C10.0267337,14.2826404 9.92957074,14.1086363 9.77002706,14.0103213 L7.10165388,12.3659983 C6.94629435,12.2702616 6.75309151,12.2594683 6.58803525,12.3373048 L4.08744453,13.5165231 C3.89839337,13.6056751 3.77776979,13.7959215 3.77776979,14.0049392 L3.77776979,16.3950565 C3.77776979,16.581572 3.87402105,16.754883 4.03235491,16.853465 L6.70194946,18.5156114 C6.86871107,18.6194407 7.07870317,18.6245186 7.25028846,18.5288711 Z"
                    id="Path-10-Copy-5"
                    stroke="#FFFFFF"
                    stroke-width="0.9"
                  ></motion.path>
                  <motion.path
                    d="M11.0543955,17.5568906 L14.1215312,15.8471635 C14.3591035,15.7147325 14.5063588,15.4640589 14.5063588,15.1920688 L14.5063588,12.5171579 C14.5063588,12.2568754 14.3714102,12.015203 14.1498218,11.8786543 L10.8637847,9.85371032 C10.6480076,9.72074269 10.3796703,9.70575193 10.1504255,9.81385825 L7.08428293,11.2597772 C6.82171186,11.3835994 6.65417912,11.6478304 6.65417912,11.9381329 L6.65417912,14.8564576 C6.65417912,15.1155069 6.78786142,15.3562165 7.00776956,15.493136 L10.2928136,17.5384744 C10.5244269,17.6826818 10.8160826,17.6897345 11.0543955,17.5568906 Z"
                    id="Path-10-Copy"
                    stroke="url(#linearGradient-2)"
                    stroke-width="2.265"
                  ></motion.path>
                  <motion.path
                    d="M11.3120303,17.5568906 L14.5157559,15.7710235 C14.7533283,15.6385925 14.9005835,15.3879189 14.9005835,15.1159288 L14.9005835,12.3152428 C14.9005835,12.0549602 14.765635,11.8132879 14.5440465,11.6767392 L11.1160715,9.56432924 C10.9002944,9.4313616 10.6319571,9.41637084 10.4027123,9.52447717 L7.19907093,11.0352372 C6.93649986,11.1590594 6.76896712,11.4232905 6.76896712,11.7135929 L6.76896712,14.7675182 C6.76896712,15.0265675 6.90264942,15.2672771 7.12255756,15.4041966 L10.5504483,17.5384744 C10.7820617,17.6826818 11.0737174,17.6897345 11.3120303,17.5568906 Z"
                    id="Path-10-Copy-4"
                    stroke="#FFFFFF"
                  ></motion.path>
                  <motion.path
                    d="M15.0203129,5.82645218 L10.6002054,7.9108683 C10.2501107,8.07596455 10.0267337,8.42827264 10.0267337,8.81534254 L10.0267337,13.0337772 C10.0267337,13.3791762 10.2049768,13.7001225 10.4981876,13.8826818 L15.2230733,16.8244962"
                    id="Path"
                    stroke="url(#linearGradient-3)"
                    stroke-width="2.265"
                    stroke-linecap="round"
                  ></motion.path>
                  <motion.path
                    d="M15.8552421,16.5374399 L19.9447563,14.2578038 C20.2615195,14.081229 20.4578598,13.7469976 20.4578598,13.3843441 L20.4578598,9.81779628 C20.4578598,9.47075289 20.2779284,9.14852311 19.9824771,8.96645813 L15.6010943,6.26653284 C15.3133915,6.08924266 14.9556085,6.06925498 14.6499487,6.21339674 L10.5617587,8.14128865 C10.2116639,8.3063849 9.9882869,8.65869299 9.9882869,9.04576289 L9.9882869,12.9368625 C9.9882869,13.2822616 10.16653,13.6032078 10.4597408,13.7857671 L14.8397995,16.5128849 C15.1486173,16.7051614 15.5374916,16.714565 15.8552421,16.5374399 Z"
                    id="Path-10-Copy-3"
                    stroke="#FFFFFF"
                    stroke-width="1.1"
                  ></motion.path>
                </motion.g>
              </motion.g>
              <motion.g id="Group-8" transform="translate(22.500000, 0.000000)">
                <motion.polygon
                  id="Path-2"
                  stroke-width="1.5"
                  fill="#3B3F40"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  points="1 12.2363479 27.6152312 0 54.7492106 12.2363479 54.7492106 38.3650043 27.8746053 53.9903915 1 38.3650043"
                  animate={health < 42 ? "unhealthy" : "healthy"}
                  variants={pathVariants}
                ></motion.polygon>
                <motion.g
                  id="Color_brown"
                  transform="translate(0.906406, 12.205880)"
                  fill={props.fillColour}
                  animate={health < 42 ? "unhealthy" : "healthy"}
                  variants={pathVariants}
                >
                  <motion.polygon
                    id="Path-5-Copy"
                    points="53.5 25.717444 26.7583019 41 0.5 25.717444 0.5 0.705463989 26.7583019 11.8464308 53.5 0.5"
                  ></motion.polygon>
                </motion.g>
                <motion.polyline
                  id="Path-3"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  points="1.9064056 12.7066369 27.5108862 23.6351485 53.6959906 12.7066369"
                  animate={health < 42 ? "unhealthy" : "healthy"}
                  variants={pathVariants}
                ></motion.polyline>
                <motion.line
                  x1="27.8746053"
                  y1="23.6351485"
                  x2="27.8746053"
                  y2="53.1320228"
                  id="Path-4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  animate={health < 42 ? "unhealthy" : "healthy"}
                  variants={pathVariants}
                ></motion.line>
              </motion.g>
            </motion.g>
          </motion.g>
        </motion.g>
      </motion.svg>
    </motion.g>
  );
}
