import React, { ReactElement, useEffect } from "react";
import "components/badge-details/badge-details.css";
import { motion } from "framer-motion";
import { setShowBadgeDetails } from "slices/system";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { v4 as uuidv4 } from "uuid";

const badgeDetails = [
  {
    title: "ENVOY",
    detail: "A service proxy that handles all traffic communications.",
    icon: "/assets/images/Group5.svg"
  },
  {
    title: "ISTIO",
    detail: "A service mesh for easier management of microservices.",
    icon: "/assets/images/Group3.svg"
  },
  {
    title: "TSB",
    detail:
      "An Enterprise service mesh to give you control over any environment.",
    icon: "/assets/images/tsb_badge.svg"
  }
];

export default function BadgeDetails(): ReactElement {
  let dispatch = useDispatch();

  let system = useSelector((state: RootState) => {
    let obj = {
      showBadgeDetails: state.system.popupDetails.showBadgeDetails
    };

    return obj;
  }, shallowEqual);

  let badgeVariant = {
    tsb: { x: "34%", y: "50%", scale: 1.7 },
    other: { x: "0%", y: "0%", scale: 1 }
  };

  function closeDetails() {
    dispatch(setShowBadgeDetails(false));
  }

  return system.showBadgeDetails ? (
    <div>
      <motion.div
        key={uuidv4()}
        className="badge-details-screen"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="outerDiv">
          <div className="close-button noSelect" onClick={closeDetails} />
          {badgeDetails.map((badge: any, index: number) => {
            return (
              <div className="innerDiv" key={index}>
                <div className="badge-div-list">
                  <div className="badge-icon-div">
                    <motion.img
                      key={uuidv4()}
                      src={badge.icon}
                      initial={badge.title === "TSB" ? "tsb" : "other"}
                      variants={badgeVariant}
                      className="badge-i"
                    />
                  </div>
                  <div className="badge-details-list">
                    <div className="badge-name">{badge.title}</div>
                    <div className="sub-text-desc">{badge.detail}</div>
                    <div
                      className={
                        index !== badgeDetails.length - 1 ? "divider-line" : ""
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </motion.div>
    </div>
) : (
  <div></div>
);
}
