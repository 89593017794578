import React, { ReactElement, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
// import ProgressBar from 'react-bootstrap/ProgressBar';
import "components/health-bar/health-bar.css";
import { SystemStates } from "constants/system-states";
import { motion, AnimatePresence } from "framer-motion";
import { togglePlayHealthDropMusic } from "slices/system";
import { v4 as uuidv4 } from "uuid";

export const healthItemColors = [
  "#EC544D",
  "#EC514A",
  "#E76349",
  "#E4764A",
  "#E39441",
  "#F2A751",
  "#F1A44F",
  "#F1A251",
  "#EFAB4B",
  "#F5BC56",
  "#FADE5D",
  "#F5DA58", //
  "#E0FD64",
  "#BAFC62",
  "#A0FA44",
  "#91BD3D",
  "#79BA59",
  "#549363",
  "#498557",
  "#325F3D"
];

interface Props {
  showHealthBarActive: boolean;
  handleHealthClick: Function;
}

export default function HealthBar(props: Props): ReactElement {
  let system = useSelector((state: RootState) => {
    return state.system.system;
  });

  let dispatch = useDispatch();

  function getNumberOfHealthItems() {
    return system.health / 5;
  }

  useEffect(() => {
    var healthDropMaxLimit = 30;
    var healthDropMinLimit = 27;
    switch (system.state) {
      case SystemStates.BEGIN:
        healthDropMinLimit = 24;
        break;
      case SystemStates.BEGIN_ZOOMED:
        healthDropMinLimit = 39;
        healthDropMaxLimit = 43;
        break;
      case SystemStates.DECOMPOSED_MONOLITHS:
        healthDropMinLimit = 39;
        healthDropMaxLimit = 43;
        break;
      case SystemStates.ENVOY_ZOOM_OUT:
        healthDropMinLimit = 37;
        healthDropMaxLimit = 43;
        break;
      case SystemStates.ADDED_ISTIO:
        healthDropMinLimit = 77;
        healthDropMaxLimit = 80;
        break;
      case SystemStates.ISTIO_ZOOM_OUT:
        healthDropMinLimit = 35;
        healthDropMaxLimit = 39;
        break;
      case SystemStates.FAILOVER:
        healthDropMinLimit = 10;
        healthDropMaxLimit = 13;
        break;
      case SystemStates.ADD_TSB:
        healthDropMinLimit = 95;
        healthDropMaxLimit = 97;
        break;
      default:
        break;
    }

    if (
      system.health < healthDropMaxLimit &&
      system.health > healthDropMinLimit
    ) {
      dispatch(togglePlayHealthDropMusic());
      setTimeout(() => {
        dispatch(togglePlayHealthDropMusic());
      }, 2000);
    }
  }, [system.health]);

  function renderHealthItems() {
    let healthItems = getNumberOfHealthItems();
    let arr = [];
    for (let i = 0; i < healthItems; i++) {
      arr.push(i);
    }
    return arr.map((item: any, index: number) => (
      <motion.div
        key={index}
        className="health-item"
        style={{ background: healthItemColors[item] }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      />
    ));
  }

  const starFly = {
    visible: {
      x: "-2800%",
      y: "1800%",
      scale: 0,
      opacity: 0
    },
    hidden: {
      x: "0%",
      y: "0%",
      scale: 1,
      opacity: 1
    }
  };

  return (
    <div
      className="container"
      style={props.showHealthBarActive ? { zIndex: 111111 } : {}}
    >
      {props.showHealthBarActive ? <div className="app-tour-health"></div> : ""}

      <img className="star-img" src="/assets/images/filledstar.svg"></img>

      {system.showStarFly && (
        <motion.img
          src={
            system.state === SystemStates.ADD_TSB ||
            system.state === SystemStates.UPDATE ||
            system.state === SystemStates.TSB_FAILOVER
              ? "/assets/images/filledstar.svg"
              : "/assets/images/star.svg"
          }
          key={uuidv4()}
          className="star-img"
          initial={{ x: "-2800%", y: "1800%", scale: 0, opacity: 0 }}
          animate="hidden"
          variants={starFly}
          transition={{
            stiffness: 30,
            duration: 1.5,
            delay: 2
          }}
        ></motion.img>
      )}
      <div
        className="progress-container"
        onClick={() => props.handleHealthClick()}
      >
        {renderHealthItems()}
      </div>
      <div
        className="service-health-text"
        onClick={() => props.handleHealthClick()}
      >
        SERVICE HEALTH
      </div>
    </div>
  );
}
