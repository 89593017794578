
export enum SystemStates {
  BEGIN = "BEGIN",
  DECOMPOSED_MONOLITHS = "DECOMPOSED_MONOLITHS",
  ADDED_ENVOY = "ADDED_ENVOY",
  ADDED_ISTIO = "ADDED_ISTIO", //2 stages message9, message10,
  ADD_TSB = "ADD_TSB", //message15
  BEGIN_ZOOMED = "BEGIN_ZOOMED",
  ENVOY_ZOOM_OUT = "ENVOY_ZOOM_OUT",
  ISTIO_ZOOM_OUT = "ISTIO_ZOOM_OUT",
  FAILOVER = "FAILOVER",
  UPDATE = "UPDATE",
  TSB_FAILOVER = "TSB_FAILOVER"
}
