import { SystemStates } from "constants/system-states";

import React, { ReactElement } from "react";
import { RootState } from "redux-setup/root-reducer";
import { useSelector, useDispatch } from "react-redux";
import {
  updateStateOfSystem,
  updateCapacity,
  resetLoad,
  showPostMortemEmail,
  setCanUpdateSystemHealth
} from "slices/system";
import { getNodeCapacityForState } from "services/node-service";

export default function UpdateGameResponse(): ReactElement {
  let dispatch = useDispatch();

  let systemState = useSelector((state: RootState) => {
    let obj = {
      health: state.system.system.health,
      state: state.system.system.state,
      capacity: state.system.system.capacity
    };
    return obj;
  });

  switch (systemState.state) {
    case SystemStates.BEGIN:
      if (systemState.state === "BEGIN" && systemState.health <= 50) {
        dispatch(updateStateOfSystem(SystemStates.BEGIN_ZOOMED));
      }
      break;

    case SystemStates.DECOMPOSED_MONOLITHS:
      break;
    case SystemStates.ADDED_ENVOY:
      //function call
      if (
        systemState.state === SystemStates.ADDED_ENVOY &&
        systemState.health <= 76 &&
        systemState.health >= 50
      ) {
        dispatch(updateStateOfSystem(SystemStates.ENVOY_ZOOM_OUT));
        dispatch(
          updateCapacity(getNodeCapacityForState(SystemStates.ENVOY_ZOOM_OUT))
        );
        dispatch(resetLoad());
      }

      break;
    case SystemStates.ADDED_ISTIO:
      //function call
      if (
        systemState.state === SystemStates.ADDED_ISTIO &&
        systemState.health <= 45
      ) {
        dispatch(updateStateOfSystem(SystemStates.ISTIO_ZOOM_OUT));
      }
      break;
    case SystemStates.ISTIO_ZOOM_OUT:
      //function call
      break;
    case SystemStates.FAILOVER:
      //function call
      if (
        systemState.state === SystemStates.FAILOVER &&
        systemState.health <= 6
      ) {
        dispatch(updateCapacity(getNodeCapacityForState(SystemStates.UPDATE)));
        dispatch(updateStateOfSystem(SystemStates.UPDATE));
        dispatch(setCanUpdateSystemHealth(false));
        setTimeout(() => {
          dispatch(showPostMortemEmail(true));
        }, 2000);
      }
      break;
    case SystemStates.ADD_TSB:
      break;
    case SystemStates.UPDATE:
      break;
  }

  return <div></div>;
}
