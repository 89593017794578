export default class UrlConfig {
  public static readonly BASE_URL =
    "https://api.hsforms.com/submissions/v3/integration/submit/";
}
export const HUBSPOT_PORTAL_ID = "7637559";
export const HUBSPOT_FORM_ID = "891e8e41-d8f6-4806-b093-12e303e4e5c3";
export const HUBSPOT_FORM = {
  FIRSTNAME: "firstname",
  LASTNAME: "lastname",
  COMPANY: "company",
  JOBTITLE: "jobtitle",
  EMAIL: "email",
  OFFERINGS: "which_offering_are_you_interested_in_"
};
