import React, { ReactElement } from "react";
import "components/music-options/music-options.css";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { RootState } from "redux-setup/root-reducer";
import { 
  toggleSoundSetting,
  toggleMusicSetting
 } from "slices/system";

export default function MusicOptions(): ReactElement {
  let system = useSelector((state: RootState) => {
    let obj = {
      showIntroduction: state.system.popupDetails.showIntroduction,
      isTourOpen: state.system.popupDetails.isTourOpen,
      missionAccomplished: state.system.popupDetails.missionAccomplished,
      showUserDetailsScreen: state.system.popupDetails.showUserDetailsScreen,
      playAudio: state.system.musicDetails.playAudio,
      volumeOn: state.system.musicDetails.volumeOn
    };
    return obj;
  }, shallowEqual);

  const dispatch = useDispatch();

  return !system.isTourOpen &&
  !system.showIntroduction &&
  !system.missionAccomplished &&
  !system.showUserDetailsScreen ? (
          <div className="play-icon-container">
            <div
              className={system.volumeOn ? "volume-on info-btn-size" : "volume-off info-btn-size"}
              onClick={evt => dispatch(toggleSoundSetting())}
            />
            <div
              className={system.playAudio ? "music-on info-btn-size" : "music-off info-btn-size"}
              onClick={evt => dispatch(toggleMusicSetting())}
            />
          </div>) : (
          <div />  );
}
